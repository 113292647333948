import React, { useEffect, useState } from 'react';
import SingleSelect from "./SingleSelect";
import { getSubOrdinates } from '../../actions/userActions/tasks/taskAction';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from "lucide-react";
import { MapPinned } from "lucide-react";
import TextField from './TextField';
import MultiSelect from './MultiSelect';


const Temporary = () => {
    const dispatch = useDispatch();
    const initialValues = {
        employee: null,
        employeeIds: [296, 251, 1, 112],
        firstName: ""
    }
    const [formValues, setFormValues] = useState(initialValues)
    const subordinates = useSelector((state) => state.tasks_reducer.subOrdinates);
    const getOptions = () => {
        // return (
        //     subordinates &&
        //     subordinates !== "isLoading" &&
        //     subordinates.length > 0 &&
        //     subordinates.map((subordinate) => ({
        //         value: subordinate.id,
        //         label: `${subordinate.firstName} ${subordinate.lastName}`,
        //         secondaryLabel: subordinate.designationShortName
        //     }))
        // ) || [];
        return (
            subordinates &&
            subordinates !== "isLoading" &&
            subordinates.length > 0 &&
            subordinates
                .sort((a, b) => {
                    const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
                    const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                })
                .map((subordinate) => ({
                    value: subordinate.id,
                    label: `${subordinate.firstName} ${subordinate.lastName}`,
                    secondaryLabel: subordinate.designationShortName,
                }))
        ) || [];
    };
    console.log(subordinates, "subordinates in temporary");

    useEffect(() => {
        const id = 296;
        dispatch(getSubOrdinates(id));
    }, []);
    const handleEmployeeChange = (event) => {
        setFormValues({
            ...formValues,
            employee: event.value
        })
    }
    const handleEmployeesChange = (updatedSelection) => {
        setFormValues({
            ...formValues,
            employeeIds: updatedSelection
        })
    }
    const handleNameChange = (event) => {
        setFormValues({
            ...formValues,
            firstName: event.target.value // Corrected to use event.target.value
        });
    };
    console.log(formValues, "form valyes in temporary");

    return (
        <div>
            <div style={{ backgroundColor: "#FFFFFF", height: "500px", padding: "20px" }}>
                <SingleSelect
                    label="Employee"
                    // labelIcon={<MapPinned height={16} width={16} />}
                    isSearchable
                    // type="radio"
                    secondaryLabel
                    // iconStart={<Search width={16} height={16} />}
                    iconEnd
                    error={"This is error"}
                    onChange={handleEmployeeChange}
                    value={formValues.employee}
                    placeholder='Select Employee'
                    options={(subordinates &&
                        subordinates !== "isLoading" &&
                        subordinates.length > 0)
                        && getOptions()}
                />

                <TextField
                    label="First Name"
                    // disabled={true}
                    iconEnd={<Search width={16} height={16} />}
                    iconStart={<Search width={16} height={16} />}
                    type='text'
                    value={formValues.firstName}
                    onChange={handleNameChange}
                    placeholder='Enter first name'
                />

                <MultiSelect
                    label="Employee"
                    isSearchable
                    secondaryLabel
                    // iconStart={<Search width={16} height={16} />}
                    iconEnd
                    error={"This is error"}
                    onChange={handleEmployeesChange}
                    value={formValues.employeeIds}
                    placeholder='Select Employees'
                    options={(subordinates &&
                        subordinates !== "isLoading" &&
                        subordinates.length > 0)
                        && getOptions()}
                />
            </div>

        </div>
    )
}
export default Temporary;