import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addPermissions,
  updatePermissions,
  getAllPermissions,
  getAllPermissionsGroup,
} from "../../actions/userActions/permissionsAction";
import PreLoader from "../preLoader/PreLoader";
import Loader from "../subComponents/Loader";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
import { CircularProgress, IconButton, InputLabel } from "@mui/material";
import SaveButton from "../subComponents/buttons/SaveButton";
import CancelButton from "../subComponents/buttons/CancelButton";
import BodyExtraSmallMedium from "../designSystem/BodyExtraSmallMedium";

import permissionIcon from "../../assets/images/Icon=Permissions.svg";
import BodyLargeMedium from "../designSystem/BodyLargeMedium";
import MultiSelect from "../designSystem/MultiSelect";
import TextField from "../designSystem/TextField";
import Textarea from "../designSystem/TextArea";
import closeModal from "../../assets/images/closeModal.svg";
function AddPermissions({ onClose, editData, onSave }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const initialValues = { name: "", active: false, description: "", permission: [] };
  const [loading, setLoading] = useState(true); // Loading state
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [selected, setSelected] = useState([])

  const body = {
    filter: {
      textSearch: "",
      permissionGroup: [0],
    },
    pageNumber: -1,
    pageSize: 0,
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllPermissions(body));

        if (editData) {
          setFormValues({
            ...formValues,
            name: editData.name,
            active: editData.active,
            description: editData.description,
            id: editData.id,
            permission: editData.permission,
          });
          setSelected(editData.permission)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };
    fetchData();
  }, []);

  const handleSave = async (e) => {
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (editData) {
        await dispatch(updatePermissions(formValues));
      } else {
        await dispatch(addPermissions(formValues));
      }
      dispatch(getAllPermissionsGroup());
      onSave(formValues);
    }
  };
  const handletextChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };


  const getOptions = () => {
    
    return allPermissions.permissions.map((permission) => ({
      value: permission.id,
      label: permission.name,
    }));
  };
  const handlePermissionChange = (updatedSelection) => {
    console.log("Before: ", formValues)
    console.log("updatedSelection: ", updatedSelection)
    setFormValues((prevState) => {
      const permission = prevState.permission || [];
      const isAlreadySelected = permission.find((item) => item === updatedSelection);
      return {
        ...prevState,
        permission: isAlreadySelected
          ? permission.filter((item) => item !== updatedSelection)
          : [...permission, updatedSelection],
      };
    });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (name === "active" && formValues.active === true) {
      setFormValues({ ...formValues, [name]: false });
    } else if (name === "active" && formValues.active === false) {
      setFormValues({ ...formValues, [name]: true });
    }
  };

  const handleDescriptionChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };


  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.description) {
      errors.description = "Description is required!";
    }
    if (values.permission.length === 0) {
      errors.permission = "Permission is required!";
    }
    return errors;
  };

  const allPermissions = useSelector(
    (state) => state.permissions_reducer.allPermissions
  );
console.log(allPermissions,"");

  const addPermission = useSelector(
    (state) => state.permissions_reducer.addPermission
  );
  const updatePermission = useSelector(
    (state) => state.permissions_reducer.updatePermission
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  const totalPermissions = allPermissions.permissions;

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
        <CircularProgress size={60} style={{ color: '#002D72' }} />
      </div>
    );
  }
  return (
    <>
      <div className="dynamicmodal-overlay">
        <div className="dynamicmodal-container">
          <div className="dynamicmodal-header mb-3">
            <div className="dynamicmodal-header-left">
              <div className="icon">
                <img src={permissionIcon} alt=" permissionIcon" />
              </div>
              <div className="text">
                <BodyLargeMedium
                  text={editData ? "Edit Permission Group" : "Add Permission Group"}
                  color="#384153"
                />
                <p>Define the access levels and permissions.</p>
              </div>
            </div>
            <div className="dynamicmodal-header-right">
              <IconButton onClick={onClose}>
                <img src={closeModal} alt="" />
              </IconButton>
            </div>
          </div>
          <form onSubmit={handleSave}>
            <div className="dynamicmodal-body">
              <div className="dynamicmodal-form-group">
                <TextField
                  name='name'
                  label="Name"
                  type='text'
                  value={formValues.name}
                  onChange={handletextChange}
                  placeholder='Enter name'
                  error={formErrors.name}
                />
              </div>

              <div className="dynamicmodal-form-group">
                <MultiSelect
                  isSearchable
                  onChange={handlePermissionChange}
                  value={formValues?.permission?.map(permission => permission.id) || []}
                  label="Permission Group"
                  options={
                    getOptions()
                  }
                />

              </div>
              <Textarea
                name='description'
                label="Description"
                placeholder="Enter your description here"
                value={formValues.description}
                onChange={handletextChange}
                error={formErrors.description}
              />
              <div className=" mt-2 ml-0">
                <div
                  className="form-group"
                  style={{ display: "flex", alignItems: "flex-start" }}
                >
                  <input
                    name="active"
                    style={{ cursor: "pointer", height: "14px", width: "14px" }}
                    type="checkbox"
                    checked={formValues.active}
                    className="ml-1 mt-1"
                    onChange={handleChange}
                  />
                  <label
                    style={{ paddingLeft: "10px" }}
                    className="form-control-label"
                  >
                    <BodyExtraSmallMedium text="Active ?" color="#1E293B" />
                  </label>
                </div>
              </div>
            </div>

            <div className="dynamicmodal-footer">
              <button
                className="cancel-button"
                type="button"
                onClick={onClose}
              >
                <BodyExtraSmallMedium color="#1E293B" text="Cancel" />
              </button>
              <button
                type="submit"
                className="primary-button"
                disabled={false}
              >
                <BodyExtraSmallMedium color="#FFFFFF" text="Save" />
              </button>
            </div>
          </form>
        </div>
      </div>

    </>
  );
}

export default AddPermissions;