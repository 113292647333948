import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getTaskTypes } from "../../actions/userActions/taskTypeAction";
import { SIZE_OF_PAGE } from "../../actions/types";
import DynamicTable from "../reuse_components/DynamicTable";
import SearchInput from "../subComponents/search/SearchInput";
import Heading6Regular from "../designSystem/Heading6Regular";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import CustomPagination from "../subComponents/pagination/CustomPagination";
import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
function TaskTypes() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState("");
  const [previousQuery, setPreviousQuery] = useState("");
  const [taskTypesData, settaskTypesData] = useState([]);
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const pageNumber = currentPage;

    if (query !== previousQuery) {
      setCurrentPage(1);
    }
    dispatch(getTaskTypes(pageNumber, SIZE_OF_PAGE, query));
    setPreviousQuery(query);

  }, [currentPage, query]);
  const taskTypes = useSelector((state) => state.task_types_reducer.taskTypes);
  const itemsPerPage = 10;
  const totalPages = taskTypes?.totalPages;
  const totalItems = taskTypes?.totalCount;
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  const taskTypeData = taskTypes && taskTypes.result;
  useEffect(() => {
    const tasktypeData = taskTypeData?.map((taskTypes) => ({
      ...taskTypes,
      status: (
        <span
          style={{
            background: "#4361FF1A",
            width: "56px",
            height: "26px",
            padding: "3px 8px",
            display: "inline-block",
            textAlign: "center",
            lineHeight: "20px",
            borderRadius: "4px 0px 0px 0px",
            opacity: 1,
          }}
        >
          {taskTypes.active ? "Active" : "Inactive"}
        </span>
      ),
    }));
    settaskTypesData(tasktypeData);
  }, [taskTypes]);


  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Description", accessor: "description" },
    { label: "Status", accessor: "status" },
  ];

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="main-container">
        <div className="content-header">
          <div className="header-navigation">
            <BodySmallMedium text="Manage" color="#A1A2A4" />
            <img
              src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
            <BodySmallMedium text="Task Types" color="#1E293B" />

          </div>
        </div>
        <Heading6Regular text="Task Types" color="#1E293B" />
        <div className="content-wrapper">
          <div className="pagination">
            <SearchInput
              placeholder="Search"
              onChange={handleSearchChange}
              value={query}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              {taskTypes !== 'isLoading' && taskTypes.length !== 0 && (
                <CustomPagination
                  currentPage={currentPage}
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  totalPages={totalPages}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              )
              }

            </div>
          </div>
          <DynamicTable
            data={taskTypesData}
            isLoading={taskTypes === "isLoading"}
            columns={columns}
            permissions={permissions}
          />
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {taskTypes !== 'isLoading' && taskTypes.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
        </div>
      </div>
    </>
  );
}

export default TaskTypes;
