import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getTaskStatuses } from "../../actions/userActions/taskStatusAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import Pagination from '../paginatioin/pagination';
import { SIZE_OF_PAGE } from "../../actions/types";
import DynamicTable from "../reuse_components/DynamicTable";
import CustomButton from "../subComponents/buttons/CustomButton";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import Heading6Regular from "../designSystem/Heading6Regular";
import SearchInput from "../subComponents/search/SearchInput";
import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";

function TaskStatuses() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1)
  const [previousQuery, setPreviousQuery] = useState("");
  const [query, setQuery] = useState("");

 
  const taskStatuses = useSelector(
    (state) => state.task_statuses_reducer.taskStatuses
  );

  const taskStatusData = taskStatuses && taskStatuses.result;
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
 
  useEffect(() => {
    const pageNumber = currentPage;
  
      if (query !== previousQuery) {
        setCurrentPage(1);
      }
      dispatch(getTaskStatuses(pageNumber, SIZE_OF_PAGE, query));
      setPreviousQuery(query);
  
    }, [currentPage, query]);
 
  const TaskStausData = taskStatusData?.map((taskStatuses) => ({
    ...taskStatuses,
    status: (
      <span
        style={{
          background: "#4361FF1A",
          width: "56px",
          height: "26px",
          padding: "3px 8px",
          display: "inline-block",
          textAlign: "center",
          lineHeight: "20px",
          borderRadius: "4px 0px 0px 0px",
          opacity: 1 ,
        }}
      >
        {taskStatuses.active ? "Active" : "Inactive"}
      </span>
    ),
  }));
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Description", accessor: "description" },
     { label: "Status", accessor: "status" },  
  ];
  
const handleSearchChange = (e) => {
  setQuery(e.target.value);
  setCurrentPage(1);
};
  return (
    <>
     <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="subTasks" color="#1E293B" />

                </div>
            </div>
            <Heading6Regular text="subTasks" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearchChange}
                        value ={query}
             />
                  
                </div>
                <DynamicTable
                    data={ TaskStausData}
                    isLoading={taskStatuses === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    thIcon={thIcon}
                />
                
            </div>

        </div>
     
    </>
  );
}

export default TaskStatuses;
