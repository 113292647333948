import React from 'react';
import "./TextArea.css";
import BodySmallRegular from './BodySmallRegular';
import { CircleAlert } from 'lucide-react';

const Textarea = ({  
  error, 
  value, 
  onChange, 
  disabled = false, 
  placeholder = '', 
  label ,name
}) => {
  return (
    <div className="textarea-container">
      {label && label.length !== 0 && (
        <BodySmallRegular color="#1E293B" text={label} />
      )}
      <div className={`textarea-main ${disabled ? 'textarea-disabled' : ''}`}>
        <textarea
          className="textarea-field"
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          rows={4}
          name={name}
        />
      </div>
      {error && error.trim().length > 0 && (
        <div className="error-text-container">
          <CircleAlert height={16} />
          <span className="error-text">{error}</span>
        </div>
      )}
    </div>
  );
};

export default Textarea;
