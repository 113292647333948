import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDoctorSpecializations } from "../../actions/userActions/doctorSpecializationAction";
import { Link, useHistory } from "react-router-dom";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import { IconButton,Tooltip } from "@mui/material";
import Pagination from '../paginatioin/pagination';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SIZE_OF_PAGE } from "../../actions/types";
import DynamicTable from "../reuse_components/DynamicTable";
import CustomButton from "../subComponents/buttons/CustomButton";
import SearchInput from "../subComponents/search/SearchInput";
import Heading6Regular from "../designSystem/Heading6Regular";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import CustomPagination from "../subComponents/pagination/CustomPagination";
import AddDoctorSpecializations from "./AddDoctorSpecializations";

function DoctorSpecializations() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [expanded, setExpanded] = useState({});
  const [currentPage, setCurrentPage] = useState(1)
  const [previousQuery, setPreviousQuery] = useState("");
  const [query, setQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [doctorSpecializationData, setdoctorSpecializationData] = useState([]);

  const toggleExpansion = (id) => {
    setExpanded((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    dispatch(getDoctorSpecializations());
  }, [dispatch]);

  const specializations = useSelector(
    (state) => state.doctor_specialization_reducer.specializations
  );

  const totalPages = specializations?.totalPages || 0;
  const totalItems = specializations?.totalCount || 0;
  const itemsPerPage =10;
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  useEffect(() => {
    const pageNumber = currentPage;
  
      if (query !== previousQuery) {
        setCurrentPage(1);
      }
      dispatch(getDoctorSpecializations(pageNumber, SIZE_OF_PAGE, query));
      setPreviousQuery(query);
  
    }, [currentPage, query]);
 
  useEffect(() => {
    const SpecializationsData = specializations && specializations.result?.map((specializations) => ({
      ...specializations,
      status: (
        <span
          style={{
            background: "#4361FF1A",
            width: "56px",
            height: "26px",
            padding: "3px 8px",
            display: "inline-block",
            textAlign: "center",
            lineHeight: "20px",
            borderRadius: "4px 0px 0px 0px",
            opacity: 1 ,
          }}
        >
          {specializations.active ? "Active" : "Inactive"}
        </span>
      ),
    }));
    setdoctorSpecializationData(SpecializationsData);
  }, [specializations]);

  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Description", accessor: "description" },
    ];
  const handleEditDoctorSpecialization = (row) => {
    setEditData(row);
    setShowModal(true);
  };
  const actions = [  
    {
        label: 'Edit Doctor Specialization',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdateDoctorSpecialization',
        handler:handleEditDoctorSpecialization,
    },
];

const handleSearchChange = (e) => {
  setQuery(e.target.value);
  setCurrentPage(1);
};

const handleAddDoctorSpecializationClick = () => {
  setEditData(null);
  setShowModal(true);
};


const handleCloseModal = () => {
  setShowModal(false);
};

const handleSaveData = () => {
  dispatch(getDoctorSpecializations(currentPage, itemsPerPage, query));
  setShowModal(false);
};

  return (
    <>
     <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Doctor Specialization" color="#1E293B" />
                </div>
            </div>
            <Heading6Regular text="Doctor Specialization" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearchChange}
              value={query}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {specializations !== 'isLoading' && specializations.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                   
                        {permissions && permissions.includes("CanAddDoctorSpecialization") && (
                            <CustomButton text="Add Specialization" width="180px" onClick={handleAddDoctorSpecializationClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ doctorSpecializationData}
                    isLoading={specializations === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {specializations !== 'isLoading' && specializations.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
            </div>
            {showModal && (
        <AddDoctorSpecializations
          onClose={handleCloseModal}
          editData={editData}
          onSave={handleSaveData}
        />
      )}
        </div>
      
     
    </>
  );
}

export default DoctorSpecializations;
