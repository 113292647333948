import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addDepartments, updateDepartments } from '../../actions/userActions/departmentAction'
import Loader from '../subComponents/Loader'
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import designationLevelIcon from "../../assets/images/designationLevelIcon.svg";
import closeModal from "../../assets/images/closeModal.svg";
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium';
import departmentIcon from "../../assets/images/Icon=Departments.svg";
import Textarea from '../designSystem/TextArea';
import { IconButton } from '@mui/material';
import BodyLargeMedium from '../designSystem/BodyLargeMedium';
import TextField from '../designSystem/TextField';
function AddDepartments({ onClose, editData, onSave }) {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = { name: "", description: "", active: false };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    useEffect(() => {
        if (editData) {
          setFormValues(editData);
        }
      }, [editData]);
    
   
    const handleSave = (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);
    
        if (Object.keys(errors).length === 0) {
          if (editData) {
            dispatch(updateDepartments(formValues));
          } else {
            dispatch(addDepartments(formValues));
          }
          onSave(formValues);
        }
      };
  

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        if (name === "active" && formValues.active === true) {
            setFormValues({ ...formValues, [name]: false });
        }
        else if (name === "active" && formValues.active === false) {
            setFormValues({ ...formValues, [name]: true });
        }
    }

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Name is required!"
        }
        if (!values.description) {
            errors.description = "Description is required!"
        }
        return errors;
    }

    return (
        <>
              <div className="dynamicmodal-overlay">
      <div className="dynamicmodal-container">
        <div className="dynamicmodal-header mb-3">
          <div className="dynamicmodal-header-left">
            <div className="icon">
              <img src={departmentIcon} alt="departmentIcon" />
            </div>
            <div className="text">
              <BodyLargeMedium
                text={editData ? "Edit Department" : "Add Department"}
                color="#384153"
              />
              <p>Create a new department and manage team assignments.</p>
            </div>
          </div>
          <div className="dynamicmodal-header-right">
          <IconButton onClick={onClose}>
            <img src={closeModal} alt="" />
            </IconButton>
              </div>
         
        </div>
       

        <form onSubmit={handleSave}>
          <div className="dynamicmodal-body">
       
        <TextField
         name="name"
                    label="Name"
                      type='text'
                    value={formValues.name}
                    onChange={handleChange}
                    placeholder='Enter name'
                    error={formErrors.name}
                />
         
              <Textarea
               name="description"
        label="Description"
        placeholder="Enter your description here"
        value={formValues.description}
        onChange={handleChange}
        error={formErrors.description}
      />

            <div className=" mt-5 ml-0">
              <div
                className="form-group"
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <input
                  name="active"
                  style={{ cursor: "pointer", height: "14px", width: "14px" }}
                  type="checkbox"
                  checked={formValues.active}
                  className="ml-1 mt-1"
                  onChange={handleChange}
                />
                <label
                  style={{ paddingLeft: "10px" }}
                  className="form-control-label"
                >
                  <BodyExtraSmallMedium text="Active ?" color="#1E293B" />
                </label>
              </div>
            </div>
          </div>

          <div className="dynamicmodal-footer">
            <button
              className="cancel-button"
              type="button"
              onClick={onClose}
            >
              <BodyExtraSmallMedium color="#1E293B" text="Cancel" />
            </button>
            <button
              type="submit"
              className="primary-button"
              disabled={false}
            >
              <BodyExtraSmallMedium  color="#FFFFFF" text="Save" />
            </button>
          </div>
        </form>
      </div>
    </div>
           
        </>
    )
}

export default AddDepartments
