import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  addOfficeLocation,
  updateOfficeLocation,
} from '../../actions/userActions/officelocationAction'
import { getCities } from '../../actions/userActions/cityAction'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CancelButton from '../subComponents/buttons/CancelButton'
import SaveButton from '../subComponents/buttons/SaveButton'
import { CircularProgress, IconButton } from '@mui/material'
import HrComponent from '../reuse_components/HrComponent'
import BodyLargeMedium from '../designSystem/BodyLargeMedium'
import TextField from '../designSystem/TextField'
import SingleSelect from '../designSystem/SingleSelect'
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium'

import officeLocationIcon from "../../assets/images/Icon=Office Locations.svg";

import closeModal from "../../assets/images/closeModal.svg";
function AddOfficeLocations({ onClose, editData,onSave }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    address: '',
    contact1: '',
    contact2: '',
    email: '',
    fax: '',
    extensions: '',
    cityId: '',
    cityName: '',
    isHeadOffice: false,
  }
  const [page, setPage] = useState(1)
  const [isSubmit, setIsSubmit] = useState(false);

  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })
  const cityBody = {
    filter: cityFilters,
    pageNumber: page,
    pageSize: 100,
  }
  const [loading, setLoading] = useState(true); 
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getCities(cityBody));
        
        if (editData.officeLocation) {
          setFormValues({
            ...formValues,
            address:editData.address,
            contact1: editData.contact1,
            contact2:editData.contact2,
            email: editData.email,
            fax: editData.fax,
            extensions: editData.extensions,
            isHeadOffice: editData.isHeadOffice,
            cityId: editData.cityId,
            id: editData.id,
            cityName: editData.cityName,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();    
  }, [editData]);
  useEffect(() => {
    if (editData) {
      setFormValues(editData);
    }
  }, [editData]);
 

  
  const getOptions = () => {
        
    return (
      allCities !== "isLoading" &&
      allCities.length !== 0 &&
      allCities.result?.[0]?.cities?.length !== 0 &&
      allCities.result[0].cities
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((city) => ({
        value: city.id,
            label: city.name
          }))
      ) || [];
};
  
const handlecheckChange = (e) => {
  const { name, checked, type, value } = e.target;
  setFormValues((prevValues) => ({
    ...prevValues,
    [name]: type === 'checkbox' ? checked : value,
  }));
};
  const handleChange = (e) => {
    const val = e.target;
    const { name, value } = val;

    if (name === "contact1") {
      const isNumeric = /^[0-9]*$/.test(value); 
  
      if (isNumeric) {
        setFormValues({ ...formValues, [name]: value.slice(0, 13) });
      }
    } else if (name === "cityId") {
      const selectedCity = allCities && allCities !== "isLoading" && allCities.length !== 0 &&  allCities.result?.[0]?.cities.find(city => city.id === value);
      if (selectedCity) {
        setFormValues({ ...formValues, [name]: selectedCity.id, cityName: selectedCity.name });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    if (name === 'isHeadOffice' && formValues.isHeadOffice === true) {
      setFormValues({ ...formValues, [name]: false })
    } else if (name === 'isHeadOffice' && formValues.isHeadOffice === false) {
      setFormValues({ ...formValues, [name]: true })
    }
  }
  const validate = (values) => {
    const errors = {}
    if (!values.address) {
      errors.address = 'Address is required!'
    } else if (values.address.length > 300) {
      errors.address = 'Address must be less than 300 characters!'
    }
    if (!values.contact1) {
      errors.contact1 = 'Contact number is required!'
    } else if (
      !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
        values.contact1,
      )
    ) {
      errors.contact1 = 'Please enter valid contact number!'
    }
    if (
      values.contact2 &&
      !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
        values.contact2,
      )
    ) {
      errors.contact2 = 'Please enter valid contact number!'
    }
    if (values.cityId === '') {
      errors.cityId = 'City is required!'
    }
    return errors
  }
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      location.officeLocation
        ? dispatch(updateOfficeLocation(formValues))
        : dispatch(addOfficeLocation(formValues));
    }
  }, [formErrors]);

  const allCities = useSelector((state) => state.cities_reducer.cities)
  const addOfficeLocations = useSelector(
    (state) => state.officeLocations_reducer.addOfficeLocation,
  )
  const updateOfficeLocations = useSelector(
    (state) => state.officeLocations_reducer.updateOfficeLocation,
  )
  const permissions = JSON.parse(localStorage.getItem('permissionsList'));
  const handleGoBack = () => {
    history.goBack();
  };
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
        <CircularProgress size={60} style={{ color: '#002D72' }} />
      </div>
    );
  }
  
  const handleSave = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (editData) {
        dispatch(updateOfficeLocation(formValues));
      } else {
        dispatch(addOfficeLocation(formValues));
      }
      onSave(formValues);
    }
  };

  const handleCityChange = (event) => {
    setFormValues({
        ...formValues,
        city: event.value
    })
}
 return (
    <>
    
   <div className="dynamicmodal-overlay">
      <div className="dynamicmodal-container">
        <div className="dynamicmodal-header mb-3">
          <div className="dynamicmodal-header-left">
            <div className="icon">
              <img src={officeLocationIcon} alt="officeLocationIcon " />
            </div>
            <div className="text">
              <BodyLargeMedium
                text={editData ? "Edit Office Location" : "Add Office Location"}
                color="#384153"
              />
              <p>Specify the details of company office location.</p>
            </div>
          </div>
          <div className="dynamicmodal-header-right">
          <IconButton onClick={onClose}>
            <img src={closeModal} alt="" />
            </IconButton>
          </div>
        </div>
       

        <form onSubmit={handleSave}>
          <div className="dynamicmodal-body">
        
            <div className="dynamicmodal-form-group">
            <TextField
                    label="Address"
                      type='text'
                      name='address'
                    value={formValues.address}
                    onChange={handleChange}
                    placeholder='Enter address'
                    error={formErrors.address}
                />
            </div>
          
                <SingleSelect
                    label="City"
                    isSearchable
                    secondaryLabel
                    // iconStart={<Search width={16} height={16} />}
                    iconEnd
                   onChange={handleCityChange}
                    value={formValues.cityId}
                    placeholder='Select city'
                    options={(allCities &&
                        allCities !== "isLoading" &&
                        allCities.length !== 0 && allCities.result?.[0]?.cities &&
                        allCities.result?.[0]?.cities.length !== 0)
                        && getOptions("allCities")}
                />
            <div className="dynamicmodal-form-group">
             
            <TextField
            name='contact1'
                    label="contact1"
                      type='text'
                    value={formValues.contact1}
                    onChange={handleChange}
                    placeholder='Enter address'
                    error={formErrors.contact1}
                />
            </div>
            <div className=" mt-2 ml-0">
              <div
                className="form-group"
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <input
                  name="isHeadOffice"
                  style={{ cursor: "pointer", height: "14px", width: "14px" }}
                  type="checkbox"
                  checked={formValues.isHeadOffice}
                  className="ml-1 mt-1"
                  onChange={handlecheckChange}
                />
                <label
                  style={{ paddingLeft: "10px" }}
                  className="form-control-label"
                >
                  <BodyExtraSmallMedium text="Is Head Office ?" color="#1E293B" />
                </label>
              </div>
            </div>
        
          </div>

          <div className="dynamicmodal-footer">
            <button
              className="cancel-button"
              type="button"
              onClick={onClose}
            >
              <BodyExtraSmallMedium color="#1E293B" text="Cancel" />
            </button>
            <button
              type="submit"
              className="primary-button"
              disabled={false}
            >
              <BodyExtraSmallMedium color="#FFFFFF" text="Save" />
            </button>
          </div>
        </form>
      </div>
    </div>
   
    </>
  )
}

export default AddOfficeLocations
