import {
  COUNTRY_SUCCESS,
  COUNTRY_FAIL,
  SET_MESSAGE,
  COUNTRY_PENDING,
} from "../types";
import UserService from "../../services/user.service";

export const getCountries = () => async (dispatch) => {
  dispatch({
    type: COUNTRY_PENDING,
    payload: { countries: "isLoading" },
  });
  return await UserService.getCountries()
    .then(
      (data) => {
        console.log("====================================");
        console.log(data.data.result);
        console.log("====================================");
        data.data.status
          ? dispatch({
              type: COUNTRY_SUCCESS,
              payload: { countries: data.data },
            })
          : dispatch({
              type: COUNTRY_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: COUNTRY_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};
