import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addRoles, getRoles, updateRoles } from "../../actions/userActions/roleAction";
import {
  getAllPermissionsGroup
} from "../../actions/userActions/permissionsAction";
import roleicon from "../../assets/images/roleicon.svg";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import "./Roles.css";
import { CircularProgress, IconButton } from "@mui/material";
import HrComponent from "../reuse_components/HrComponent";
import BodyExtraSmallMedium from "../designSystem/BodyExtraSmallMedium";
import BodyLargeMedium from "../designSystem/BodyLargeMedium";
import closeModal from "../../assets/images/closeModal.svg";
import MultiSelect from "../designSystem/MultiSelect";
import "../DesignationLevel/DynamicModal.css"
import Textarea from "../designSystem/TextArea";
import TextField from "../designSystem/TextField";
function AddRoles({ onClose, editData, onSave }) {
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    description: "",
    roleGroup: [],
    active: false,
  };
  const [loading, setLoading] = useState(true); // Loading state
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const pageNumber = -1;
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllPermissionsGroup(pageNumber));
       if (editData) {
          const roleGroupIds = editData.permissionGroup.map(
            (item) => item.id
          );
    
          setFormValues({
            ...formValues,
            name: editData.name,
            description: editData.description,
            id: editData.id,
            active: editData.active,
            roleGroup: roleGroupIds,
          });
          setSelected(
    
            editData.roleGroupIds
          );
        }
        
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
    
   
  }, []);

  const extractRoleGroupIds = (permissionGroups) => {
    return permissionGroups.map((group) => group.id);
  };
   useEffect(() => {
    if (editData) {
      const roleGroupIds = editData.permissionGroup
        ? extractRoleGroupIds(editData.permissionGroup)
        : [];
         setFormValues({
        ...formValues,
        name: editData.name,
        description: editData.description,
        id: editData.id,
        active: editData.active,
        roleGroup: roleGroupIds
      });
    }
  }, []);
  const handletextChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  
const handleChange = (e) => {
  const { name, checked, type, value } = e.target;
  setFormValues((prevValues) => ({
    ...prevValues,
    [name]: type === 'checkbox' ? checked : value,
  }));
};
   const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.description) {
      errors.description = "Description is required!";
    }
    if (values.roleGroup.length <= 0) {
      errors.roleGroup = "Permission Group is required!";
    }
    return errors;
  };

  const handleSave = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
        if (editData) {
            dispatch(updateRoles(formValues));
        } else {
            dispatch(addRoles(formValues));
        }
        onSave(formValues);
    }
};

  const allPermissionsGroup = useSelector(
    (state) => state.permissions_reducer.allPermissionsGroup
  );
  const getOptions = () => {
    return (
      (allPermissionsGroup &&
        allPermissionsGroup !== "isLoading" &&
        allPermissionsGroup.length !== 0 &&
        allPermissionsGroup.result &&
        allPermissionsGroup.result.length !== 0 &&
        allPermissionsGroup.result
          .filter((permissionGroup) => permissionGroup.active)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((permissionGroup) => ({
            value: permissionGroup.id,
            label: permissionGroup.name,
          }))) ||
      []
    );
  };
  const handlePermissionGroupChange = (updatedSelection) => {
    setFormValues((prevState) => {
      const roleGroup = prevState.roleGroup || [];
      const isAlreadySelected = roleGroup.includes(updatedSelection);
  
      return {
        ...prevState,
        roleGroup: isAlreadySelected
          ? roleGroup.filter((item) => item !== updatedSelection) 
          : [...roleGroup, updatedSelection], 
      };
    });
  };
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
        <CircularProgress size={60} style={{ color: '#002D72' }} />
      </div>
    );
  }

  return (
    <>
     <div className="dynamicmodal-overlay">
      <div className="dynamicmodal-container">
        <div className="dynamicmodal-header mb-3">
          <div className="dynamicmodal-header-left">
            <div className="icon">
              <img src={roleicon} alt=" roleicon" />
            </div>
            <div className="text">
              <BodyLargeMedium
                text={editData ? "Edit Role" : "Add Role"}
                color="#384153"
              />
              <p>Define a role and specify relevant permission groups.</p>
            </div>
          </div>
          <div className="dynamicmodal-header-right">
            <IconButton onClick={onClose}>
            <img src={closeModal} alt="" />
            </IconButton>
              </div>
        </div>
         <form onSubmit={handleSave}>
          <div className="dynamicmodal-body">
            <div className="dynamicmodal-form-group">
            <TextField
            name='name'
                    label="Name"
                      type='text'
                    value={formValues.name}
                    onChange={handletextChange}
                    placeholder='Enter name'
                    error={formErrors.name}
                />
                </div>
          
            <div className="dynamicmodal-form-group">
            <MultiSelect
              isSearchable
              onChange={handlePermissionGroupChange}
              value={formValues?.roleGroup}
              label="Permission Group"
              options={
                allPermissionsGroup &&
                allPermissionsGroup !== "isLoading" &&
                allPermissionsGroup.result &&
                allPermissionsGroup.result.length !== 0 &&
                getOptions()
              }
            />

              </div>
              <Textarea
              name='description'
        label="Description"
        placeholder="Enter your description here"
        value={formValues.description}
        onChange={handletextChange}
        error={formErrors.description}
      />
           <div className=" mt-2 ml-0">
              <div
                className="form-group"
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <input
                  name="active"
                  style={{ cursor: "pointer", height: "14px", width: "14px" }}
                  type="checkbox"
                  checked={formValues.active}
                  className="ml-1 mt-1"
                  onChange={handleChange}
                />
                <label
                  style={{ paddingLeft: "10px" }}
                  className="form-control-label"
                >
                  <BodyExtraSmallMedium text="Active ?" color="#1E293B" />
                </label>
              </div>
            </div>
          </div>

          <div className="dynamicmodal-footer">
            <button
              className="cancel-button"
              type="button"
              onClick={onClose}
            >
              <BodyExtraSmallMedium color="#1E293B" text="Cancel" />
            </button>
            <button
              type="submit"
              className="primary-button"
              disabled={false}
            >
              <BodyExtraSmallMedium color="#FFFFFF" text="Save" />
            </button>
          </div>
        </form>
      </div>
    </div>
    
    </>
  );
}

export default AddRoles;
