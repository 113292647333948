import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addLocationType, updateLocationType } from '../../actions/userActions/locationTypeAction';
import Loader from '../subComponents/Loader'
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium';
import Textarea from '../designSystem/TextArea';
import TextField from '../designSystem/TextField';
import { IconButton } from '@mui/material';
import locationtypeicon from "../../assets/images/locationtypeicon.svg";
import closeModal from "../../assets/images/closeModal.svg";
import BodyLargeMedium from '../designSystem/BodyLargeMedium';
function AddLocationTypes({ onClose, editData, onSave }) {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const initialValues = { name: "", description: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const handletextChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  useEffect(() => {
    if (editData) {
      setFormValues(editData);
    }
  }, [editData]);

  const handleSave = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (editData) {
        dispatch(updateLocationType(formValues));
      } else {
        dispatch(addLocationType(formValues));
      }
      onSave(formValues);
    }
  };


  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required!"
    }
    if (!values.description) {
      errors.description = "Description is required!"
    }
    return errors;
  }
  return (
    <>
      <div className="dynamicmodal-overlay">
        <div className="dynamicmodal-container">
          <div className="dynamicmodal-header mb-3">
            <div className="dynamicmodal-header-left">
              <div className="icon">
                <img src={locationtypeicon} alt="locationtypeicon" />
              </div>
              <div className="text">
                <BodyLargeMedium
                  text={editData ? "Edit PracticeLocation Type" : "Add  PracticeLocation Type"}
                  color="#384153"
                />
                <p>Set a new type of practice location.</p>
              </div>
            </div>
            <div className="dynamicmodal-header-right">
              <IconButton onClick={onClose}>
                <img src={closeModal} alt="" />
              </IconButton>
            </div>
          </div>


          <form onSubmit={handleSave}>
            <div className="dynamicmodal-body">

              <TextField
                name="name"
                label="Name"
                type='text'
                value={formValues.name}
                onChange={handletextChange}
                placeholder='Enter name'
                error={formErrors.name}
              />

              <Textarea
                name="description"
                label="Description"
                placeholder="Enter description here"
                value={formValues.description}
                onChange={handletextChange}
                error={formErrors.description}
              />
            </div>

            <div className="dynamicmodal-footer mt-5">
              <button
                className="cancel-button"
                type="button"
                onClick={onClose}
              >
                <BodyExtraSmallMedium color="#1E293B" text="Cancel" />
              </button>
              <button
                type="submit"
                className="primary-button"
                disabled={false}
              >
                <BodyExtraSmallMedium color="#FFFFFF" text="Save" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddLocationTypes
