import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  addDesignations,
  updateDesignations,
} from '../../actions/userActions/designationAction'
import { Redirect, useLocation, useHistory } from 'react-router-dom'

import { getDesignationsLevel } from '../../actions/userActions/designationLevelAction'
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium';
import Textarea from '../designSystem/TextArea'
import TextField from '../designSystem/TextField'
import { IconButton } from '@mui/material'
import BodyLargeMedium from '../designSystem/BodyLargeMedium';
import designationIcon from "../../assets/images/Icon=Designations.svg";
import closeModal from "../../assets/images/closeModal.svg";
import SingleSelect from '../designSystem/SingleSelect'
function AddDesignations({ onClose, editData, onSave }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    name: '',
    shortName: '',
    description: '',
    active: false,
    levelId: 0,
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})

  const currentPage = -1
  const itemsPerPage = 10
  const searchQuery = ''

  useEffect(() => {
    dispatch(getDesignationsLevel(currentPage, itemsPerPage, searchQuery))
  }, [dispatch, currentPage, itemsPerPage, searchQuery])
  useEffect(() => {
    if (editData) {
      setFormValues(editData);
    }
  }, [editData]);
  const handleSave = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (editData) {
        dispatch(updateDesignations(formValues));
      } else {
        dispatch(addDesignations(formValues));
      }
      onSave(formValues);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    if (
      (name === 'active' && formValues.active === true) ||
      formValues.active === 'on'
    ) {
      setFormValues({ ...formValues, [name]: false })
    } else if (name === 'active' && formValues.active === false) {
      setFormValues({ ...formValues, [name]: true })
    }
  }

  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Name is required!'
    }
    if (!values.shortName) {
      errors.shortName = 'Short name is required!'
    }
    if (!values.description) {
      errors.description = 'Description is required!'
    }
    if (!values.levelId) {
      errors.levelId = 'Designation Level is required!'
    }
    return errors
  }
  
  const designationLevels = useSelector(
    (state) => state.designationLevels_reducer.designationLevels,
  )
  const permissions = JSON.parse(localStorage.getItem('permissionsList'));
  const handleGoBack = () => {
    history.goBack();
  };
  const handleSelectChange = (event) => {
    setFormValues({
      ...formValues,
      levelId: event.value
    })
  }
  const getOptions = () => {

    return (
      designationLevels &&
      designationLevels !== "isLoading" &&
      designationLevels.length !== 0  &&  designationLevels?.result?.[0]?.designationLevels
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((designationLevel) => ({
          value: designationLevel.id,
          label: designationLevel.name,
         // secondaryLabel: designationLevels.id
        }))
    ) || [];
  };
  return (
    <>
      <div className="dynamicmodal-overlay">
        <div className="dynamicmodal-container">
          <div className="dynamicmodal-header mb-3">
            <div className="dynamicmodal-header-left">
              <div className="icon">
                <img src={designationIcon} alt="designationIcon" />
              </div>
              <div className="text">
                <BodyLargeMedium
                  text={editData ? "Edit Designation" : "Add Designation"}
                  color="#384153"
                />
                <p>Set and manage job titles within the organization.</p>
              </div>
            </div>
            <div className="dynamicmodal-header-right">
              <IconButton onClick={onClose}>
                <img src={closeModal} alt="" />
              </IconButton>
            </div>

          </div>
          <form onSubmit={handleSave}>
            <div className="dynamicmodal-body">
              <div className='row mt-5'>
                <div className='col-6'>  
                   <TextField
                  name="name"
                  label="Name"
                  type='text'
                  value={formValues.name}
                  onChange={handleChange}
                  placeholder='Enter name'
                  error={formErrors.name}
                />
                </div>
                <div className='col-6'>   
                   <TextField
                  name="shortName"
                  label="Short Name"
                  type='text'
                  value={formValues.shortName}
                  onChange={handleChange}
                  placeholder='Enter Short Name'
                  error={formErrors.shortName}
                /></div>
              </div>

              <SingleSelect
                label="Designation Level"
                isSearchable
                secondaryLabel
                error={formErrors.levelId}
                // iconStart={<Search width={16} height={16} />}
                iconEnd
                onChange={handleSelectChange}
                value={formValues.levelId}
                placeholder='Select designation level'
                options={(designationLevels &&
                  designationLevels !== "isLoading" &&
                  designationLevels.length !== 0 && designationLevels?.result?.[0]?.designationLevels &&
                  designationLevels?.result?.[0]?.designationLevels.length !== 0)
                  && getOptions()}
              />
              <Textarea
                name="description"
                label="Description"
                placeholder="Enter your description here"
                value={formValues.description}
                onChange={handleChange}
                error={formErrors.description}
              />

              <div className=" mt-5 ml-0">
                <div
                  className="form-group"
                  style={{ display: "flex", alignItems: "flex-start" }}
                >
                  <input
                    name="active"
                    style={{ cursor: "pointer", height: "14px", width: "14px" }}
                    type="checkbox"
                    checked={formValues.active}
                    className="ml-1 mt-1"
                    onChange={handleChange}
                  />
                  <label
                    style={{ paddingLeft: "10px" }}
                    className="form-control-label"
                  >
                    <BodyExtraSmallMedium text="Active ?" color="#1E293B" />
                  </label>
                </div>
              </div>
            </div>

            <div className="dynamicmodal-footer">
              <button
                className="cancel-button"
                type="button"
                onClick={onClose}
              >
                <BodyExtraSmallMedium color="#1E293B" text="Cancel" />
              </button>
              <button
                type="submit"
                className="primary-button"
                disabled={false}
              >
                <BodyExtraSmallMedium color="#FFFFFF" text="Save" />
              </button>
            </div>
          </form>
        </div>
      </div>
    
    </>
  )
}

export default AddDesignations
