import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addEmployees,
  updateEmployees,
  getEmployee,
} from "../../actions/userActions/employeeAction";

import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import { getCities } from "../../actions/userActions/cityAction";
import { getDesignations } from "../../actions/userActions/designationAction";
import { getOfficeLocations } from "../../actions/userActions/officelocationAction";
import { getDepartments } from "../../actions/userActions/departmentAction";
import { getTeams } from "../../actions/userActions/teamAction";
import { getEmploymentStatuses } from "../../actions/userActions/employmentStatusAction";
import { getRoles } from "../../actions/userActions/roleAction";
import {
  getEmployees,
  getGenders,
} from "../../actions/userActions/employeeAction";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import moment from 'moment';
import { CircularProgress } from "@mui/material";
import HrComponent from "../reuse_components/HrComponent";
import { SIZE_OF_PAGE } from "../../actions/types";
import { getvisitProfile } from "../../actions/userActions/VisitProfileAction";
import TextField from "../designSystem/TextField";
import SingleSelect from "../designSystem/SingleSelect";
import PrimaryButton from "../subComponents/buttons/PrimaryButton";
import Heading6Regular from "../designSystem/Heading6Regular";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import BodySmallRegular from "../designSystem/BodySmallRegular";
import CancelButton from "../subComponents/buttons/CustomCancelButton";

function AddEmployees() {
  const [retryKey, setRetryKey] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [tryagain, setTryagain] = useState(false);
  const [name, setName] = useState("");
  const [goBack, setGoBack] = useState(false);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');

  const currentDate = new Date();
  const eighteenYearsAgo = new Date(
    currentDate.getFullYear() - 18,
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const twentyFourYearsAgo = new Date(
    currentDate.getFullYear() - 24,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const [page, setPage] = useState(1);


  const cityBody = {
    filter: {
      textSearch: "",
      countryId: "1"
    },
    pageNumber: -1,
    pageSize: 0,
  };
  const designationBody = {
    filter: {
      textSearch: ""
    },
    pageNumber: -1,
    pageSize: 0,
  };
  const visitLocationbody = {
    filter: {
      textSearch: ""
    },
    pageNumber: -1,
    pageSize: 0
  }
  const locationBody = {
    filter: {
      textSearch: "",
      cityName: "",
    },
    pageNumber: -1,
    pageSize: 0,
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [birthDate, setBirthDate] = useState(twentyFourYearsAgo);
  const initialValues = {
    firstName: "",
    lastName: "",
    gender: "",
    dateOfJoining: "",
    dateOfBirth: twentyFourYearsAgo,
    nic: "",
    passport: "",
    address: "",
    city: "",
    personalContact1: "",
    personalContact2: "",
    personalEmail: "",
    designation: "",
    jobLocation: "",
    officialEmail: "",
    officialContact1: "",
    officialContact2: "",
    department: "",
    reportsTo: "",
    team: "",
    employmentStatus: "",
    password: "",
    employeeRoles: [],
    status: false,
    empId: "",
    visitProfileId: "",
    replacementOf: null
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [inactiveLabels, setInactiveLabels] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const pageNumber = -1;
  useEffect(() => {
    if (location.id) {
      dispatch(getEmployee(location.id));
    }

    const fetchData = async () => {
      try {
        dispatch(getCities(cityBody));
        await Promise.all([
          dispatch(getDesignations(designationBody)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getOfficeLocations(locationBody)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getDepartments(pageNumber)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getTeams(pageNumber, SIZE_OF_PAGE, query)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getEmploymentStatuses(pageNumber)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getRoles(pageNumber)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getEmployees(pageNumber)).catch(error => {
            return Promise.reject();
          }),
          dispatch(getGenders()).catch(error => {
            return Promise.reject();
          }),

          dispatch(getvisitProfile(pageNumber, SIZE_OF_PAGE, query)).catch(error => {
            return Promise.reject();
          }),

          setLoadingData(false)
        ]).then((response) => {

        }).catch((error) => {
          throw error;
        });
        setLoadingData(false);
      } catch (error) {
        setTryagain(true);
        setLoadingData(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, location.id, retryKey]);


  const employee = useSelector((state) => state.employees_reducer.employee);
  const visitProfile = useSelector((state) => state.visitProfile_reducer.visitProfile);
  const initialSelectedValues = {
    empId:
      employee && employee !== "isLoading" && location.id && employee.empId
        ? { label: "loading...", value: "" }
        : "",
    firstName:
      employee && employee !== "isLoading" && location.id && employee.firstName
        ? { label: "loading...", value: "" }
        : "",
    lastName:
      employee && employee !== "isLoading" && location.id && employee.lastName
        ? { label: "loading...", value: "" }
        : "",
    cnic:
      employee && employee !== "isLoading" && location.id && employee.cnic
        ? { label: "loading...", value: "" }
        : "",
    gender:
      employee && employee !== "isLoading" && location.id && employee.gender
        ? { label: "loading...", value: "" }
        : "",
    city:
      employee && employee !== "isLoading" && location.id && employee.city
        ? { label: "loading...", value: "" }
        : "",
    designation:
      employee && employee !== "isLoading" && location.id && employee.designation
        ? { label: "loading...", value: "" }
        : "",
    visitProfileId:
      employee && employee !== "isLoading" && location.id && employee.visitProfileId
        ? { label: "loading...", value: "" }
        : "",
    replacementOf:
      employee && employee !== "isLoading" && location.id && employee.replacementOf
        ? { label: "loading...", value: "" }
        : "",
    jobLocation:
      employee &&
        employee !== "isLoading" &&
        location.id &&
        employee.jobLocation
        ? { label: "loading...", value: "" }
        : "",
    department:
      employee && employee !== "isLoading" && location.id && employee.department
        ? { label: "loading...", value: "" }
        : "",
    reportsTo:
      employee && employee !== "isLoading" && location.id && employee.reportsTo
        ? { label: "loading...", value: "" }
        : "",
    team:
      employee && employee !== "isLoading" && location.id && employee.team
        ? { label: "loading...", value: "" }
        : "",
    employmentStatus:
      employee &&
        employee !== "isLoading" &&
        location.id &&
        employee.employmentStatus
        ? { label: "loading...", value: "" }
        : "",
    employeeRoles:
      employee &&
        employee !== "isLoading" &&
        location.id &&
        employee.employeeRoles
        ? { label: "loading...", value: "" }
        : "",
  };
  const [selected, setSelected] = useState(initialSelectedValues);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues, departments, designationData, teamsData, employmentStatusData, roles);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {

      const updatedFormValues = {
        ...formValues,
        personalEmail: formValues.personalEmail.trim().toLowerCase(),
        officialEmail: formValues.officialEmail.trim().toLowerCase(),
        firstName: formValues.firstName.trim(),
        lastName: formValues.lastName.trim(),
        empId: formValues?.empId?.trim(),
        address: formValues.address.trim(),
        personalContact1: formValues?.personalContact1.trim(),
        personalContact2: formValues?.personalContact2?.trim(),
        passport: formValues?.passport.trim(),
        visitProfileId: formValues.visitProfileId ? formValues.visitProfileId : null,
      };
      if (location.id) {
        dispatch(updateEmployees(updatedFormValues));
        setGoBack(true);
      } else {
        dispatch(addEmployees(updatedFormValues));
      }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : e;

    if (name === "personalEmail") {
      setFormValues({ ...formValues, personalEmail: value, officialEmail: value });
    } else if (name === "personalContact1") {
      setFormValues({ ...formValues, personalContact1: value, officialContact1: value });
    } else if (name === "status") {
      setFormValues({ ...formValues, [name]: value, status: !formValues.status });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };



  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setSelected(prevValues => ({
      ...prevValues,
      dateOfJoining: formattedDate,
    }));
    setFormValues(prevValues => ({
      ...prevValues,
      dateOfJoining: formattedDate,
    }));
  };


  const findInactiveEntry = (id, data) => {
    if (!Array.isArray(data)) {
      return null;
    }
    const entry = data.find(item => item.id === id);
    return entry && !entry.active ? entry : null;
  };

  const validate = (values, departments, designationData, teamsData, employmentStatusData, roles) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = <p className="text-red">First Name is required!</p>;
    }
    if (!values.lastName) {
      errors.lastName = <p className="text-red">Last Name is required! </p>;
    }

    if (!values.address) {
      errors.address = <p className="text-red">Address is required!</p>;
    }
    if (values.nic.trim().length > 20) {
      errors.nic = (
        <p className="text-red">Maximum number of characters exceeded. Only 20 allowed!</p>
      );
    }
    if (!values.personalContact1) {
      errors.personalContact1 = (
        <p className="text-red">Contact is required!</p>
      );
    } else if (values.personalContact1.length !== 11) {
      errors.personalContact1 = <p className="text-red">Contact is Invalid!</p>;
    }
    if (values.personalContact2 && values.personalContact2.length !== 11) {
      errors.personalContact2 = <p className="text-red">Contact is Invalid!</p>;
    }
    if (!values.personalEmail) {
      errors.personalEmail = <p className="text-red">Email is required!</p>;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.personalEmail)
    ) {
      errors.personalEmail = (
        <p className="text-red">Please enter valid email!</p>
      );
    }

    if (!values.officialEmail) {
      errors.officialEmail = <p className="text-red">Email is required!</p>;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.officialEmail)
    ) {
      errors.officialEmail = (
        <p className="text-red">Please enter valid email!</p>
      );
    }
    if (values.gender === "") {
      errors.gender = <p className="text-red">Gender is required!</p>;
    }

    if (!values.department) {
      errors.department = <p className="text-red">Department is required!</p>;
    }

    if (values.team === "") {
      errors.team = <p className="text-red">Team is required!</p>;
    }
    if (values.employmentStatus === "") {
      errors.employmentStatus = (
        <p className="text-red">Employment Status is required!</p>
      );
    }
    if (values.city === "") {
      errors.city = <p className="text-red">City is required!</p>;
    }

    if (!values.employeeRoles || values.employeeRoles.length === 0) {
      errors.employeeRoles = <p className="text-red">Employee Role is required!</p>;
    }

    if (!values.designation) {
      errors.designation = <p className="text-red">Designation is required!</p>;
    }
    if (!values.reportsTo) {
      errors.reportsTo = <p className="text-red">Report to is required!</p>;
    }
    if (values.jobLocation.length === 0) {
      errors.jobLocationName = (
        <p className="text-red">Job location is required!</p>
      );
    }
    //  EmpId validation
    if (values.empId && values.empId.length > 15) {
      errors.empId = <p className="text-red">Employee id must not exceed 15 characters!</p>;
    }
    if (!values.dateOfJoining) {
      errors.dateOfJoining = (
        <p className="text-red">Date of Joining is required!</p>
      );
    }
    if (!values.jobLocation) {
      errors.jobLocation = (
        <p className="text-red">Job Location is required!</p>
      );
    }
    return errors;
  };

  const cities = useSelector((state) => state.cities_reducer.cities);
  const designations = useSelector(
    (state) => state.designations_reducer.designations
  );
  const designationData = designations && designations[0] && designations?.result?.[0]?.designations;
  const officeLocations = useSelector(
    (state) => state.officeLocations_reducer.officeLocations
  );
  const departments = useSelector(
    (state) => state.departments_reducer.departments
  );
  const departmentsData = departments.result;
  const teams = useSelector((state) => state.teams_reducer.teams);

  const teamsData = teams && teams.result;


  const employmentStatuses = useSelector(
    (state) => state.employmentStatuses_reducer.employmentStatuses
  );

  const employmentStatusData = employmentStatuses.result;
  const roles = useSelector((state) => state.roles_reducer.roles);
  const rolesResultData = roles && roles.result;
  const employees = useSelector((state) => state.employees_reducer.employees);
  const employeeData = useSelector((state) => state.employees_reducer.employee);
  console.log(employeeData,'empl');
  console.log(employees.result,"::::::::::::");
  console.log(
    employees?.result?.filter(emp => emp.status === false),
    "Filtered Employees"
  );
  
  const genders = useSelector((state) => state.employees_reducer.genders);
  const addEmployee = useSelector(
    (state) => state.employees_reducer.addEmployee
  );
  const updateEmployee = useSelector(
    (state) => state.employees_reducer.updateEmployee
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  const getOptions = (type) => {
    switch (type) {
      case "cities":
        return cities?.result?.[0]?.cities?.length
          ? cities.result[0].cities
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((city) => ({
              value: city.id,
              label: city.name,
            }))
          : [];
      case "gender":
        return genders?.length
          ? genders
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((gender) => ({
              value: gender.id,
              label: gender.name,
            }))
          : [];
          case "replacementOf":
            const filteredEmployees = employees?.result?.filter((emp) => emp.status !== true);
            console.log("Filtered Employees:", filteredEmployees);
            return filteredEmployees?.length
              ? filteredEmployees.map((emp) => ({
                  value: emp.id,
                  label: `${emp.firstName} ${emp.lastName}`,
                }))
              : [{ value: "", label: "No available replacements" }];
          
      case "department":
        return departments?.result?.length
          ? departments.result.map((dept) => ({
            value: dept.id,
            label: dept.name,
          }))
          : [];
      case "designation":
        return designations?.result?.[0]?.designations?.length
          ? designations.result[0].designations.map((desig) => ({
            value: desig.id,
            label: desig.name,
          }))
          : [];
      case "employeeRoles":
        return rolesResultData?.length
          ? rolesResultData.map((role) => ({
            value: role.id,
            label: role.name,
          }))
          : [];
      case "team":
        return teamsData?.length
          ? teamsData.map((team) => ({
            value: team.id,
            label: team.name,
          }))
          : [];
      case "reportsTo":
        return employees?.result?.length
          ? employees.result.map((emp) => ({
            value: emp.empId,
            label: `${emp.firstName} ${emp.lastName}`,
          }))
          : [];
      case "visitProfileId":
        return visitProfile?.result?.length
          ? visitProfile.result.map((profile) => ({
            value: profile.visitProfileId,
            label: profile.profileName,
          }))
          : [];
      case "jobLocation":
        return officeLocations.result?.[0]?.officeLocations?.length
          ? officeLocations.result?.[0]?.officeLocations
            .map((location) => ({
              value: location.id,
              label: location.address,
            }))
          : [];
      case "employmentStatus":
        return employmentStatuses?.result?.length
          ? employmentStatuses.result.map((status) => ({
            value: status.id,
            label: status.name,
          }))
          : [];
      default:
        return [];
    }
  };

  useEffect(() => {
    if (!loadingData && location.id) {

      employee &&
        employee !== "isLoading" &&
        setSelected({
          ...selected,
          ["gender"]: getOptions(genders, "gender")[
            getOptions(genders, "gender")
              .map((e) => e.value)
              .indexOf(employee.gender)
          ],
          ["city"]: getOptions(cities && cities !== "isLoading" && cities.result?.[0]?.cities.length !== 0 && cities.result?.[0]?.cities, "city")[
            getOptions(cities && cities !== "isLoading" && cities.result?.[0]?.cities.length !== 0 && cities.result?.[0]?.cities, "city")
              .map((e) => e.value)
              .indexOf(employee.city)
          ],
          ["designation"]: getOptions(designations && designations !== "isLoading" && designations.length !== 0 && designations?.result?.[0]?.designations, "designation")[
            getOptions(designations && designations !== "isLoading" && designations.length !== 0 && designations?.result?.[0]?.designations, "designation")
              .map((e) => e.value)
              .indexOf(employee.designation)
          ],

          ["jobLocation"]: getOptions(
            officeLocations && officeLocations !== "isLoading" && officeLocations.length !== 0 && officeLocations.result?.[0]?.officeLocations,
            "jobLocation"
          )[
            getOptions(officeLocations.officeLocations, "jobLocation")
              .map((e) => e.value)
              .indexOf(employee.jobLocation)
          ],
          ["department"]: getOptions(departments && departments !== "isLoading" && departments.length !== 0 && departments.result, "department")[
            getOptions(departments && departments !== "isLoading" && departments.length !== 0 && departments.result, "department")
              .map((e) => e.value)
              .indexOf(employee.department)
          ],
          ["team"]: getOptions(teamsData, "team")[
            getOptions(teamsData, "team")
              .map((e) => e.value)
              .indexOf(employee.team)
          ],

          ["reportsTo"]: getOptions(employees.result, "reportsTo")[
            getOptions(employees.result, "reportsTo")
              .map((e) => e.value)
              .indexOf(employee.reportsTo)
          ],
          ["employmentStatus"]: getOptions(employmentStatuses.result, "employmentStatus")[
            getOptions(employmentStatuses.result, "employmentStatus")
              .map((e) => e.value)
              .indexOf(employee.employmentStatus)
          ],
          ["employeeRoles"]: getOptions(roles && Array.isArray(rolesResultData) && rolesResultData, "employeeRoles")[
            getOptions(roles, "employeeRoles")
              .map((e) => e.value)
              .indexOf(
                employee.employeeRoles[0] && employee.employeeRoles[0].roleId
              )
          ],

        });
      {
        employee &&
          employee !== "isLoading" &&
          setFormValues({
            ...formValues,
            ["firstName"]: employee.firstName,
            ["lastName"]: employee.lastName,
            ["gender"]: employee.gender,
            ["dateOfJoining"]: employee.dateOfJoining,
            ["dateOfBirth"]: employee.dateOfBirth,
            ["nic"]: employee.nic,
            ["passport"]: employee.passport,
            ["address"]: employee.address,
            ["city"]: employee.city,
            ["personalContact1"]: employee.personalContact1,
            ["personalContact2"]: employee.personalContact2,
            ["personalEmail"]: employee.personalEmail,
            ["designation"]: employee.designation,
            ["visitProfileId"]: employee.visitProfileId,
            ["replacementOf"]: employee.replacementOf,
            ["jobLocation"]: employee.jobLocation,
            ["officialEmail"]: employee.officialEmail,
            ["officialContact1"]: employee.officialContact1,
            ["officialContact2"]: employee.officialContact2,
            ["department"]: employee.department,
            ["reportsTo"]: employee.reportsTo,
            ["team"]: employee.team,
            ["employmentStatus"]: employee.employmentStatus,
            ["password"]: employee.password,
            ["employeeRoles"]: employee.employeeRoles,
            ["id"]: location.id,
            ["empId"]: employee.empId,
            ["status"]: employee.status,
            ["dateOfJoining"]: employee.dateOfJoining ? moment(employee.dateOfJoining).format('YYYY-MM-DD') : ""
          });
      }
      {
        employee &&
          employee !== "isLoading" &&
          setSelected(prevValues => ({
            ...prevValues,
            dateOfJoining: moment(employee.dateOfJoining).format('MM-DD-YYYY')
          }));
      }
      {
        employee &&
          employee !== "isLoading" &&
          setBirthDate(new Date(employee.dateOfBirth));
      }
    }
    if (!loadingData && location.id) {
      setName(employee.firstName + " " + employee.lastName);
      const inactiveLabels = {};
      if (departmentsData && departmentsData.length !== 0 && departmentsData != 'isLoading') {
        const inactiveDepartment = findInactiveEntry(formValues.department, departmentsData);
        if (inactiveDepartment) {
          inactiveLabels.department = (
            <p className="text-red">
              {`${inactiveDepartment.name} is inactive!`}
            </p>
          );
        }
      }
      if (teams && Array.isArray(teamsData) && teamsData !== 'isLoading' && teamsData.length !== 0 && teamsData) {
        const inactiveTeam = findInactiveEntry(formValues.team, teamsData);
        if (inactiveTeam) {
          inactiveLabels.team = (
            <p className="text-red">
              {`${inactiveTeam.name} is inactive!`}
            </p>
          );
        }
      }
      if (employmentStatusData && employmentStatusData.length !== 0 && employmentStatusData != 'isLoading') {
        const inactiveEmploymentStatus = findInactiveEntry(formValues.employmentStatus, employmentStatusData);
        if (inactiveEmploymentStatus) {
          inactiveLabels.employmentStatus = (
            <p className="text-red">
              {`${inactiveEmploymentStatus.name} is inactive!`}
            </p>
          );
        }
      }
      if (designationData && designationData.length !== 0 && designationData != 'isLoading') {
        const inactiveDesignation = findInactiveEntry(formValues.designation, designationData);
        if (inactiveDesignation) {
          inactiveLabels.designation = (
            <p className="text-red">
              {`${inactiveDesignation.name} is inactive!`}
            </p>
          );
        }
      }
      if (roles && Array.isArray(rolesResultData) && rolesResultData && rolesResultData.length !== 0 && rolesResultData != 'isLoading') {
        const selectedRoles = formValues.employeeRoles.map(role => ({
          roleId: role.roleId,
          roleName: role.roleName,
          active: true,
        }));

        const inactiveRoles = selectedRoles.filter(selectedRole => {
          const role = roles && Array.isArray(rolesResultData) && rolesResultData.find(r => r.id === selectedRole.roleId);
          if (role) {
            selectedRole.active = role.active;
            return !role.active;
          }
          return false;
        });

        if (inactiveRoles.length > 0) {
          inactiveLabels.employeeRoles = (
            <div>
              {inactiveRoles.map(role => (
                <p key={role.roleId} className="text-red">
                  {`${role.roleName} is inactive!`}
                </p>
              ))}
            </div>
          );
        }
      }

      setInactiveLabels(inactiveLabels);
    }
  }, [
    loadingData,
    location.id,
    location.empId,
    employee,
    genders,
    cities,
    designations,
    officeLocations,
    departments,
    teamsData,
    employmentStatuses,
    roles
  ]);
  
  useEffect(() => {
    if (employeeData && formValues.replacementOf) {
      setFormValues({
        ...formValues,
        department: employeeData.department,
        employeeRoles: employeeData.employeeRoles,
        visitProfileId: employeeData.visitProfileId,
        designation: employeeData.designation,
        reportsTo: employeeData.reportsTo,
        team: employeeData.team
      });
    }
  }, [employeeData]);

  const handleGoBack = () => {
    history.goBack();
  };
  const onRetry = () => {
    setRetryKey(!retryKey);
    setTryagain(false);
    setLoading(true);
  }
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
        <CircularProgress size={60} style={{ color: '#002D72' }} />
      </div>
    );
  }
  if (tryagain) {
    return (
      <div className="error-container">
        <h2>Something went wrong</h2>
        <p>Looks like we're experiencing an issue, please check your internet connection and try again.</p>
        <button className="retry-button" onClick={onRetry}>
          &#x21bb; Try Again
        </button>
      </div>
    );
  }
  const handletextChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleselectChange = (event, fieldName) => {
    setFormValues({
      ...formValues,
      [fieldName]: event.value,
    });

    if (fieldName === "replacementOf" && event.value) {
      dispatch(getEmployee(event.value)); 
    }
  };

  return (
    <>
      {addEmployee && addEmployee === true && !location.id && <Redirect to="/admin/employees" />}
      {updateEmployee && updateEmployee === true && goBack && <Redirect to="/admin/employees" />}
      {!permissions.includes("CanAddEmployee") && <Redirect to="/admin" />}
      {!permissions.includes("CanUpdateEmployee") && <Redirect to="/admin" />}
      {!location.id && <Redirect to={"/admin/Addemployees"} />}
      <div className="add-visit-profile-container">
        <div className="content-header">
          <div className="header-navigation">
            <BodySmallMedium text="Manage" color="#A1A2A4" />
            <img
              src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
            <BodySmallMedium text="Employee" color="#1E293B" />

          </div>

        </div>
        <Heading6Regular text="Employee" color="#1E293B" />
        <div className="add-content-wrapper">
          <div className="row">
            <div className="col-12">
              <Heading6Regular text="Personal Details" color="#1E293B" />
            </div>
          </div>

          <HrComponent />

          <div className="row">
            <div className="col-6">
              <TextField

                name="Emp Id"
                label=" empId"
                type='text'
                value={formValues.empId}
                onChange={handletextChange}
                placeholder='Enter empid'
                error={formErrors.empId}
              />
            </div>

            <div className="col-6">
              <TextField

                name="nic"
                label=" Nic"
                type='text'
                value={formValues.nic}
                onChange={handletextChange}
                placeholder='Enter NIC'
                error={formErrors.nic}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TextField

                name="name"
                label=" First Name"
                type='text'
                value={formValues.firstName}
                onChange={handletextChange}
                placeholder='Enter First name'
                error={formErrors.firstName}
              />
            </div>

            <div className="col-6">
              <TextField

                name="name"
                label=" Last Name"
                type='text'
                value={formValues.lastName}
                onChange={handletextChange}
                placeholder='Enter First name'
                error={formErrors.lastName}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <SingleSelect
                label="Gender"
                isSearchable
                iconEnd
                onChange={(event) => handleselectChange(event, 'gender')}
                value={formValues.gender}
                placeholder='Select Gender'
                options={(genders &&
                  genders !== "isLoading" &&
                  genders.length !== 0) &&
                  getOptions("gender")}
              />
            </div>

            <div className="col-6">
              <TextField
                name="passport"
                label=" Passport No"
                type='text'
                value={formValues.passport}
                onChange={handletextChange}
                placeholder='Enter passport no'
                error={formErrors.passport}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TextField
                name="personalEmail"
                label="Personal Email"
                type='text'
                value={formValues.personalEmail}
                onChange={handletextChange}
                placeholder='Enter personal Email '
                error={formErrors.personalEmail}
              />

            </div>

            <div className="col-6">
              <SingleSelect
                label="City"
                isSearchable
                iconEnd
                onChange={(event) => handleselectChange(event, 'cities')}
                value={formValues.city}
                placeholder='Select city'
                options={(cities &&
                  cities !== "isLoading" &&
                  cities.length !== 0 && cities.result?.[0]?.cities &&
                  cities.result?.[0]?.cities.length !== 0)
                  && getOptions("cities")}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <TextField

                name="personalContact1"
                label="Personal Contact1"
                type='text'
                value={formValues.personalContact1}
                onChange={handletextChange}
                placeholder='Enter personalContact1'
                error={formErrors.personalContact1}
              />
            </div>

            <div className="col-6">
              <TextField

                name="personalContact2"
                label="Personal Contact2"
                type='text'
                value={formValues.personalContact2}
                onChange={handletextChange}
                placeholder='Enter personalContact2 '
                error={formErrors.personalContact2}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextField

                name="address"
                label="Address"
                type='text'
                value={formValues.address}
                onChange={handletextChange}
                placeholder='Enter address'
                error={formErrors.address}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Heading6Regular text="Official Details" color="#1E293B" />
            </div>
          </div>

          <HrComponent />

          <div className="row">
            <div className="col-6">
              <SingleSelect
                label="In Replacement Of"
                isSearchable
                iconEnd
                disabled={location.id}
                onChange={(event) => handleselectChange(event, 'replacementOf')}
                value={formValues.replacementOf}
                placeholder="Select an employee"
                options={getOptions("replacementOf")}
              />

            </div>

            <div className="col-6">

              <SingleSelect
                label="Visit Profile"
                isSearchable
                iconEnd
                onChange={(event) => handleselectChange(event, 'visitProfileId')}
                value={formValues.visitProfileId}
                placeholder="Select Visit Profile"
                options={getOptions("visitProfileId")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <SingleSelect
                label="Department *"
                isSearchable
                iconEnd
                onChange={(event) => handleselectChange(event, 'department')}
                value={formValues.department}
                placeholder="Select Department"
                options={getOptions("department")}
              />  </div>

            <div className="col-6">
              <SingleSelect
                label="Designation *"
                isSearchable
                iconEnd
                onChange={(event) => handleselectChange(event, 'designation')}
                value={formValues.designation}
                placeholder="Select Designation"
                options={getOptions("designation")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">

              <SingleSelect
                label="Role *"
                isSearchable
                iconEnd
                onChange={(event) => handleselectChange(event, 'employeeRoles')}
                value={formValues.employeeRoles}
                placeholder="Select Role"
                options={getOptions("employeeRoles")}
              />
            </div>

            <div className="col-6">
              <SingleSelect
                label="Team *"
                isSearchable
                iconEnd
                onChange={(event) => handleselectChange(event, 'team')}
                value={formValues.team}
                placeholder="Select Team"
                options={getOptions("team")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <SingleSelect
                label="Reports To *"
                isSearchable
                iconEnd
                onChange={(event) => handleselectChange(event, 'reportsTo')}
                value={formValues.reportsTo}
                placeholder="Select Reporting Manager"
                options={getOptions("reportsTo")}
              />
            </div>

            <div className="col-6">
            <div style={{ marginBottom: "6px"}}>
    <BodySmallRegular text="Date of Joining"/> 
  </div>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
  <DatePicker
  valueType="date"
  value={selected.dateOfJoining && selected.dateOfJoining.length !== 0 && dayjs(selected.dateOfJoining)}
  onChange={handleDateChange}
  sx={{
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: "32px",
      padding: "0",
      boxSizing: "border-box",
      backgroundColor: "#f9fafb", // Background color
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",  // Remove border
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",  // Remove border on hover
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",  
      },
    },
    "& .MuiInputBase-input": {
      height: "32px",
      padding: "0px 8px",
      fontSize: "14px",
    },
    "& .MuiIconButton-root": {
      padding: "20px",
    },
  }}
/>

  </LocalizationProvider>
  {formErrors.dateOfJoining && <div>{formErrors.dateOfJoining}</div>}
</div>

          </div>
          <div className="row">
            <div className="col-6">
              <SingleSelect
                label="Employment Status *"
                isSearchable
                iconEnd
                onChange={(event) => handleselectChange(event, 'employmentStatus')}
                value={formValues.employmentStatus}
                placeholder="Select Employment Status"
                options={getOptions("employmentStatus")}
              />
            </div>

            <div className="col-6">
              <SingleSelect
                label="Office Location *"
                isSearchable
                iconEnd
                onChange={(event) => handleselectChange(event, 'jobLocation')}
                value={formValues.jobLocation}
                placeholder="Select Office Location"
                options={getOptions("jobLocation")}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group" style={{ display: "flex", alignItems: "flex-start" }}>
              <input
                name="status"
                style={{ cursor: "pointer", height: "16px", width: "16px" }}
                type="checkbox"
                checked={formValues.status}
                className="mt-1 ml-1"
                onChange={handleChange}
              />

              <label
                style={{ paddingLeft: "10px", }}
                className="form-control-label"

              >
                Is this employee active?
              </label>
            </div>
          </div>
          <hr />

          <div className="add-visit-profile-form-actions">
            <CancelButton onClick={handleGoBack} text="Cancel" />
            <PrimaryButton
              onClick={handleSubmit}
              isLoading={addEmployee === "isLoading" || updateEmployee === "isLoading"}
              text={location.employee ? "Update" : "Save"}
              // icon={customIcon}
              width="100px"
              height="50px"
            />

          </div>
        </div>
      </div>
    </>
  );
}

export default AddEmployees;
