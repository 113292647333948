import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCountries } from "../../actions/userActions/countryAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import Pagination from '../paginatioin/pagination';
import SearchInput from "../subComponents/search/SearchInput";
import Heading6Regular from "../designSystem/Heading6Regular";
import CustomPagination from "../subComponents/pagination/CustomPagination";
import DynamicTable from "../reuse_components/DynamicTable";
import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import BodySmallMedium from "../designSystem/BodySmallMedium";
function Countries() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const countries = useSelector((state) => state.countries_reducer.countries);
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [query, setQuery] = useState("");

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  // Safely extract the result array from countries
  const countriesList = Array.isArray(countries?.result) ? countries.result : [];
  console.log("Countries List:", countriesList);

  // Filter the countries based on the search query
  const filter_countries = countriesList.filter((country) =>
    country.name?.toLowerCase().includes(query.toLowerCase())
  );
  console.log("Filtered Countries:", filter_countries);

  const indexOfLastCountry = currentPage * perPage;
  const indexOfFirstCountry = indexOfLastCountry - perPage;
  const currentCountries = filter_countries.slice(
    indexOfFirstCountry,
    indexOfLastCountry
  );
  const totalPages = Math.ceil(filter_countries.length / perPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Short Name", accessor: "shortName" },
  ];

  return (
    <div className="main-container">
      <div className="content-header">
        <div className="header-navigation">
          <BodySmallMedium text="Manage" color="#A1A2A4" />
          <img src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
          <BodySmallMedium text="Countries" color="#1E293B" />
        </div>
      </div>
      <Heading6Regular text="Countries" color="#1E293B" />
      <div className="content-wrapper">
        <div className="pagination">
          <SearchInput
            placeholder="Search"
            setText={(text) => setQuery(text)}
            text={query}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            {countriesList.length !== 0 && currentCountries.length !== 0 && (
              <CustomPagination
                currentPage={currentPage}
                totalItems={filter_countries.length}
                itemsPerPage={perPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
        <DynamicTable
          data={currentCountries}
          isLoading={countries === "isLoading"}
          columns={columns}
          permissions={permissions}
          thIcon={thIcon}
        />
      </div>
    </div>
  );
}


export default Countries;
