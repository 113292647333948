import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getCities } from '../../actions/userActions/cityAction'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DynamicTable from '../reuse_components/DynamicTable'
import CustomButton from '../subComponents/buttons/CustomButton'
import CustomPagination from '../subComponents/pagination/CustomPagination'
import SearchInput from '../subComponents/search/SearchInput'
import BodySmallMedium from '../designSystem/BodySmallMedium'
import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import Heading6Regular from '../designSystem/Heading6Regular'
import AddCities from './AddCities'
function Cities() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '0',
  })

  // City Body
  const cityBody = {
    filter: cityFilters,
    pageNumber: currentPage,
    pageSize: 10,
  }
  const [selected, setSelected] = useState({
    textSearch: '',
    countryId: '1',
  })
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [cityData, setCityData] = useState([]);
  useEffect(() => {
    dispatch(getCities(cityBody))
  }, [currentPage, cityFilters])
 const itemsPerPage = 10;
  const cities = useSelector((state) => state.cities_reducer.cities)
  useEffect(() => {
    const citiesData = cities && cities.result?.[0]?.cities;
    setCityData(citiesData);
  }, [cities]);
  const totalPages = cities?.totalPages;
  const totalItems = cities?.totalCount;
  const [query, setQuery] = useState('')
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))

  const getPaginationNo = (pageNum) => {
    const object = []
    if (cities !== 'isLoading' && cities.length !== 0) {
      for (let i = 0; i < cities[0].page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Short Name", accessor: "shortName" }, 
  ];
  const handleEditCity = (row) => {
    setEditData(row);
    setShowModal(true);
  };
 const actions = [  
    {
        label: 'Edit City',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdateCity',
        handler:handleEditCity,
    },
];

  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 1) {
      setTimeout(() => {
        setCityFilters({ ...cityFilters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setCityFilters({ ...cityFilters, ['textSearch']: e.target.value })
    }
  }
 
  const handleAddClick = () => {
    setEditData(null);
    setShowModal(true);
  };

 
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSaveData = async (newData) => {
  await dispatch(getCities(cityBody));

    setShowModal(false);
  };
  return (
    <>
    <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Cities" color="#1E293B" />
                </div>
            </div>
            <Heading6Regular text="Cities" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearch}
                        value ={selected.textSearch}
             />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {cities !== 'isLoading' && cities.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                   
                        {permissions && permissions.includes("CanAddCity") && (
                            <CustomButton text="Add City" width="180px" onClick={handleAddClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ cityData}
                    isLoading={cities === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
                       <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {cities !== 'isLoading' && cities.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
  </div>
            </div>

            {showModal && (
        <AddCities
          onClose={handleCloseModal}
          editData={editData}
         onSave={handleSaveData}
        />
      )}
        </div>
    </>
  )
}

export default Cities
