import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import './CustomerRank.css'
import { addCustomerRank, updateCustomerRank } from '../../../actions/userActions/customerRankAction';
import BodyExtraSmallMedium from '../../designSystem/BodyExtraSmallMedium';
import Textarea from '../../designSystem/TextArea';
import BodyLargeMedium from '../../designSystem/BodyLargeMedium';
import customerrankicon from "../../../assets/images/customerrankicon.svg";
import closeModal from "../../../assets/images/closeModal.svg";
import TextField from '../../designSystem/TextField'
import { IconButton } from '@mui/material'

const AddCustomerRank = ({ onClose, editData, onSave}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    rankName: '',
    rankDescription: '',
  }

  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})

  const handletextChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  useEffect(() => {
    if (editData) {
      setFormValues(editData);
    }
  }, [editData]);

  const handleSave = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (editData) {
        dispatch(updateCustomerRank(formValues));
      } else {
        dispatch(addCustomerRank(formValues));
      }
      onSave(formValues);
    }
  };

  const validate = (values) => {
    const errors = {}
    if (!values.rankName) {
      errors.rankName = 'Name is required!'
    }
    if (!values.rankDescription) {
      errors.rankDescription = 'Description is required!'
    }
    return errors
  }

  return (
    <>

        <div className="dynamicmodal-overlay">
      <div className="dynamicmodal-container">
        <div className="dynamicmodal-header mb-3">
          <div className="dynamicmodal-header-left">
            <div className="icon">
              <img src={customerrankicon} alt="customerrankicon" />
            </div>
            <div className="text">
              <BodyLargeMedium
                text={editData ? "Edit Customer Rank" : "Add Customer Rank"}
                color="#384153"
              />
              <p>Define a ranking level to categorize customer.</p>
            </div>
          </div>
          <div className="dynamicmodal-header-right">
          <IconButton onClick={onClose}>
            <img src={closeModal} alt="" />
            </IconButton>
          </div>
        </div>
       

        <form onSubmit={handleSave}>
          <div className="dynamicmodal-body">
        
        <TextField
         name="rankName"
                    label="Name"
                      type='text'
                    value={formValues.rankName}
                    onChange={handletextChange}
                    placeholder='Enter name'
                    error={formErrors.rankName}
                />
         
              <Textarea
               name="rankDescription"
        label="Description"
        placeholder="Enter your description here"
        value={formValues.rankDescription}
        onChange={handletextChange}
        error={formErrors.rankDescription}
      />
   </div>

          <div className="dynamicmodal-footer mt-5">
            <button
              className="cancel-button"
              type="button"
              onClick={onClose}
            >
              <BodyExtraSmallMedium color="#1E293B" text="Cancel" />
            </button>
            <button
              type="submit"
              className="primary-button"
              disabled={false}
            >
              <BodyExtraSmallMedium color="#FFFFFF" text="Save" />
            </button>
          </div>
        </form>
      </div>
    </div>

    </>
  )
}

export default AddCustomerRank