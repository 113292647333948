import React, { useEffect, useState } from 'react'
import './CustomerRank.css'
import { getCustomerRank } from '../../../actions/userActions/customerRankAction'
import { useSelector, useDispatch } from 'react-redux'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link, useHistory } from 'react-router-dom'
import { SIZE_OF_PAGE } from '../../../actions/types'
import Search from '../../subComponents/search/Search'
import DynamicTable from '../../reuse_components/DynamicTable'
import CustomButton from '../../subComponents/buttons/CustomButton'
import CustomPagination from '../../subComponents/pagination/CustomPagination'
import SearchInput from '../../subComponents/search/SearchInput'
import Heading6Regular from '../../designSystem/Heading6Regular'
import BodySmallMedium from '../../designSystem/BodySmallMedium'
import thIcon from "../../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../../assets/images/chevron_rightIcon.svg";
import AddCustomerRank from './AddCustomerRank'

const CustomerRank = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState({ textSearch: '' });
  const [filters, setFilters] = useState({ textSearch: '' });
  const [isExpanded, setIsExpanded] = useState(false);
  const [query, setQuery] = useState("");
  const [previousQuery, setPreviousQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [CustomerRankData, setCustomerRankData] = useState([]);
 const itemsPerPage = 10

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const customerRankData = useSelector(
    (state) => state.customer_rank_reducer.customerRank
  )
  useEffect(() => {
    const customerRank = customerRankData?.result || [];
    setCustomerRankData(customerRank);
  }, [customerRankData]);
  
  const totalPages = customerRankData?.totalPages || 0;
  const totalItems = customerRankData?.totalCount || 0;
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))

  useEffect(() => {
    const pageNumber = currentPage;

    if (query !== previousQuery) {
      setCurrentPage(1);
    }
    dispatch(getCustomerRank(pageNumber, SIZE_OF_PAGE, query));
    setPreviousQuery(query);

  }, [currentPage, query]);

  const handleEditCustomerRank = (row) => {
    setEditData(row);
    setShowModal(true);
  };
  const columns = [
    { label: "Name", accessor: "rankName" },
    { label: "Description", accessor: "rankDescription" }
  ];
   const actions = [  
    {
        label: 'Edit Customer Rank',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdateCustomerRank',
        handler:handleEditCustomerRank,
    },
];

const handleSearchChange = (e) => {
  setQuery(e.target.value);
  setCurrentPage(1);
};
 
const handleAddCustomerRankClick = () => {
  setEditData(null);
  setShowModal(true);
};


const handleCloseModal = () => {
  setShowModal(false);
};

const handleSaveData = () => {
  dispatch(getCustomerRank(currentPage, itemsPerPage, query));
  setShowModal(false);
};

  return (
    <>
    <div>
      <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Customer Rank" color="#1E293B" />

                </div>
            </div>
            <Heading6Regular text="Customer Rank" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearchChange}
                        value ={query}
             />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {customerRankData !== 'isLoading' && customerRankData.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                   
                        {permissions && permissions.includes("CanAddCustomerRank") && (
                            <CustomButton text="Add Customer Rank" width="180px" onClick={handleAddCustomerRankClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ CustomerRankData}
                    isLoading={customerRankData === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {customerRankData !== 'isLoading' && customerRankData.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
            </div>

        </div>
       
      {showModal && (
        <AddCustomerRank
          onClose={handleCloseModal}
          editData={editData}
          onSave={handleSaveData}
        />
      )}
      </div>
    </>
   
  );
};

export default CustomerRank;
