import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getPracticeLocationTypes,
  addPracticeLocation,
  updatePracticeLocations,
} from '../../actions/userActions/practiceLocationAction'
import { getCities } from '../../actions/userActions/cityAction'
import { Redirect } from 'react-router-dom'
import { useLocation, useHistory } from 'react-router-dom'
import { CircularProgress, IconButton } from '@mui/material'
import HrComponent from '../reuse_components/HrComponent'
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium'
import SingleSelect from '../designSystem/SingleSelect'
import BodyLargeMedium from '../designSystem/BodyLargeMedium'
import practicelocationicon from "../../assets/images/practicelocationicon.svg";
import closeModal from "../../assets/images/closeModal.svg";
import TextField from '../designSystem/TextField'
function AddPracticeLocations({ onClose, editData,onSave }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    name: '',
    type: '',
    address: '',
    city: '',
  }
  const [loading, setLoading] = useState(true); // Loading state
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })
  const cityBody = {
    filter: cityFilters,
    pageNumber: -1,
    pageSize: 0,
  }
  const allCities = useSelector((state) => state.cities_reducer.cities)
  
  const practiceLocationType = useSelector(
    (state) => state.practiceLocations_reducer.practiceLocationTypes,
  )
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getCities(cityBody))
        .then(() => {
          dispatch(getPracticeLocationTypes(-1));
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
        
        if (editData) {
          setFormValues(editData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); 
      }
    };
    fetchData();
  
  }, [editData]);
 
  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Name is required!'
    }
    if (!values.address) {
      errors.address = 'Address is required!'
    }
    if (values.city === '') {
      errors.city = 'City is required!'
    }
    if (values.type === '') {
      errors.type = 'Type is required!'
    }
    return errors
  }

  const handleSave = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (editData) {
        dispatch(updatePracticeLocations(formValues));
      } else {
        dispatch(addPracticeLocation(formValues));
      }
      onSave(formValues);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const handleGoBack = () => {
    history.goBack();
  };
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
        <CircularProgress size={60} style={{ color: '#002D72' }} />
      </div>
    );
  }
 
  console.log(allCities,"cities");
  console.log(practiceLocationType,"type");
  const getOptions = (type) => {
    if (type === "allCities") {
      if (
        allCities !== "isLoading" &&
        allCities.length !== 0 &&
        allCities.result?.[0]?.cities?.length !== 0
      ) {
        return allCities.result[0].cities
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((city) => ({
            value: city.id,
            label: city.name
          }));
      }
      return [];
    }

    if (type === "practiceLocationType") {
      if (
        practiceLocationType !== "isLoading" &&
        practiceLocationType.length !== 0 &&
        practiceLocationType.result &&
        practiceLocationType.result.length !== 0
      ) {
        return practiceLocationType.result
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((locationType) => ({
            value: locationType.id,
            label: locationType.name
          }));
      }
      return [];
    }

    return [];
  };
  const handletextChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleCityChange = (event) => {
    setFormValues({
        ...formValues,
        city: event.value
    })
}
const handleTypeChange = (event) => {
  setFormValues({
      ...formValues,
      type: event.value
  })
}
  return (
    <>
        <div className="dynamicmodal-overlay">
      <div className="dynamicmodal-container">
        <div className="dynamicmodal-header mb-3">
          <div className="dynamicmodal-header-left">
            <div className="icon">
              <img src={practicelocationicon} alt="practicelocationicon" />
            </div>
            <div className="text">
              <BodyLargeMedium
                text={editData ? "Edit Practice Location" : "Add Practice Location"}
                color="#384153"
              />
              <p>Specify a new practice location.</p>
            </div>
          </div>
          <div className="dynamicmodal-header-right">
          <IconButton onClick={onClose}>
            <img src={closeModal} alt="" />
            </IconButton>
          </div>
        </div>
       

        <form onSubmit={handleSave}>
          <div className="dynamicmodal-body">
        
            <div className="dynamicmodal-form-group">
            <TextField
                    label="Name"
                      type='text'
                      name='name'
                    value={formValues.name}
                    onChange={handletextChange}
                    placeholder='Enter name'
                    error={formErrors.name}
                />
            </div>
            <SingleSelect
                    label="Location Type"
                    isSearchable
                    // type="radio"
                    secondaryLabel
                    // iconStart={<Search width={16} height={16} />}
                    iconEnd
                   onChange={handleTypeChange}
                    value={formValues.type}
                    placeholder='Select type'
                    options={(practiceLocationType &&
                      practiceLocationType !== "isLoading" &&
                      practiceLocationType.length !== 0 && practiceLocationType.result &&
                      practiceLocationType.result.length !== 0)
                        && getOptions("practiceLocationType")}
                />
                <SingleSelect
                    label="City"
                    isSearchable
                    secondaryLabel
                    // iconStart={<Search width={16} height={16} />}
                    iconEnd
                   onChange={handleCityChange}
                    value={formValues.city}
                    placeholder='Select country'
                    options={(allCities &&
                        allCities !== "isLoading" &&
                        allCities.length !== 0 && allCities.result?.[0]?.cities &&
                        allCities.result?.[0]?.cities.length !== 0)
                        && getOptions("allCities")}
                />
            <div className="dynamicmodal-form-group">
             
            <TextField
            name='address'
                    label="Address"
                      type='text'
                    value={formValues.address}
                    onChange={handletextChange}
                    placeholder='Enter address'
                    error={formErrors.address}
                />
            </div>
           
        
          </div>

          <div className="dynamicmodal-footer">
            <button
              className="cancel-button"
              type="button"
              onClick={onClose}
            >
              <BodyExtraSmallMedium color="#1E293B" text="Cancel" />
            </button>
            <button
              type="submit"
              className="primary-button"
              disabled={false}
            >
              <BodyExtraSmallMedium color="#FFFFFF" text="Save" />
            </button>
          </div>
        </form>
      </div>
    </div>
      {/* {addPracticeLocations &&
        addPracticeLocations != 'isLoading' &&
         <Redirect to="/admin/practiceLocations" />}
         {updatePracticeLocation && updatePracticeLocation != "isLoading" && <Redirect to="/admin/practiceLocations" />}
     
      {!location.practiceLocation && (
        <Redirect to={'/admin/AddPracticeLocations'} />
      )}
      <div>
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
              />{' '}
              {location.practiceLocation
                ? 'Update Practice Location'
                : 'Add Practice Location'}
            </h2>
          </div>
          <HrComponent />
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Name *"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
            <div style={{ color: 'red' }}>{formErrors.name}</div>
          </div>
          <div className="col-md-6">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Location Type *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formValues.type}
                onChange={handleChange}
                label="Location Type *"
                name='type'
                MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
              >
                 {practiceLocationType === "isLoading" && (
                    <MenuItem disabled>
                      Loading...
                    </MenuItem>
                  )}
                  {practiceLocationType !== "isLoading" && practiceLocationType.length === 0 && (
                    <MenuItem disabled>
                      No data
                    </MenuItem>
                  )}
                  {practiceLocationType !== "isLoading" && practiceLocationType.length !== 0 && practiceLocationType?.result
                     .sort((a, b) => a.name.localeCompare(b.name))
                    .map(locationType => (
                      <MenuItem key={locationType.id} value={locationType.id}>
                        {locationType.name}
                      </MenuItem>
                    ))
                  }
              </Select>
              <div style={{ color: 'red' }}>{formErrors.type}</div>
            </FormControl>
          </div>
          <div className="col-md-3 mt-5">
          <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">City *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.city}
                  onChange={handleChange}
                  label="City *"
                  name='city'
                  MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
                >
                  {allCities === "isLoading" && (
                    <MenuItem disabled>
                      Loading...
                    </MenuItem>
                  )}
                  {allCities !== "isLoading" && allCities.length === 0 && (
                    <MenuItem disabled>
                      No data
                    </MenuItem>
                  )}
                  {allCities !== "isLoading" && allCities.length !== 0 && allCities.result?.[0]?.cities
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(city => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))
                  }
                </Select>
                <div style={{ color: 'red' }}>{formErrors.city}</div>
              </FormControl>
          </div>
          <div className="col-md-9 mt-5">
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Address *"
              name="address"
              value={formValues.address}
              onChange={handleChange}
            />
            <div style={{ color: 'red' }}>{formErrors.address}</div>
          </div>
          <div className="col-lg-12 mt-10" style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
            <CancelButton
              onClick={handleGoBack}
            />
            <SaveButton
              onClick={addSubmitHandle}
              isLoading={addPracticeLocations === "isLoading" || updatePracticeLocations === "isLoading"}
              text={location.practiceLocationType ? "Update" : "Save"}
            />
          </div>
        </div>
      </div> */}
    </>
  )
}

export default AddPracticeLocations
