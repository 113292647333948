import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getCities } from '../../actions/userActions/cityAction'
import { getPracticeLocations } from '../../actions/userActions/practiceLocationAction';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DynamicTable from '../reuse_components/DynamicTable'
import CustomPagination from '../subComponents/pagination/CustomPagination'
import SearchInput from '../subComponents/search/SearchInput'
import BodySmallMedium from '../designSystem/BodySmallMedium'
import Heading6Regular from '../designSystem/Heading6Regular'
import CustomButton from '../subComponents/buttons/CustomButton'

import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import AddPracticeLocations from './AddPracticeLocations'
function PracticeLocations() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedCity, setSelectedCity] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [PracticeLocationData, setPracticeLocationData] = useState([]);
  const itemsPerPage = 10;
  const allCities = useSelector((state) => state.cities_reducer.cities)
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const [page, setPage] = useState(1)
  const [selected, setSelected] = useState({
    textSearch: '',
    city: '',
  })
  const [filters, setFilters] = useState({
    textSearch: '',
    city: selectedCity,
  })
  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })

  const body = {
    filter: filters,
    pageNumber: currentPage,
    itemsPerPage: 10,
  }
  const cityBody = {
    filter: cityFilters,
    pageNumber: -1,
    pageSize: 0,
  }
  useEffect(() => {
    dispatch(getCities(cityBody))
      .then(() => {
        dispatch(getPracticeLocations(body))
      })
  }, [page, filters])

  const practiceLocations = useSelector(
    (state) => state.practiceLocations_reducer.practiceLocations
  )

  const citydata =allCities?.result?.[0].cities
  
  useEffect(() => {
    const practiceLocationsData = practiceLocations?.result?.[0]?.practiceLocations|| [];
   
    setPracticeLocationData(practiceLocationsData);
  }, [practiceLocations]);
  const totalPages = practiceLocations?.totalPages || 0;
  const totalItems = practiceLocations?.totalCount || 0;
 
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const getPaginationNo = (pageNum) => {
    const object = []
    if (practiceLocations !== 'isLoading' && practiceLocations.length !== 0) {
      for (let i = 0; i < practiceLocations[0].page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }

  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 1) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }
 
  useEffect(() => {
    dispatch(getPracticeLocations(body));
  }, [filters,currentPage]);
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setFilters({
      ...filters,
      city: event.target.value
    })
  };
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Location Type", accessor: "typeName" },
    { label: "Address", accessor: "address" },
    ];
  const handleEditPracticeLocation = (row) => {
    setEditData(row);
    setShowModal(true);
  };
  const actions = [  
    {
        label: 'Edit Practice location',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdatePracticeLocation',
        handler:handleEditPracticeLocation,
    },
];
const handleAddPracticeLocationClick = () => {
  setEditData(null);
  setShowModal(true);
};


const handleCloseModal = () => {
  setShowModal(false);
};
const handleSaveData = async () => {
  dispatch(getPracticeLocations(body));
  setShowModal(false);
};
  return (
    <>
    <div>
      <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Practice Location" color="#1E293B" />

                </div>
            </div>
            <Heading6Regular text="Practice Location" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearch}
                        value ={selected.textSearch}
             />
             
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {practiceLocations !== 'isLoading' && practiceLocations.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                   <div style={{ display: "flex",marginRight:"10px" }}> <FormControl>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCity}
                label="City"
                onChange={handleCityChange}
                MenuProps={{ PaperProps: { style: { maxHeight: 160 } } }}
                style={{ maxHeight: 40, borderRadius: "2px", width: "220px" }}
              >
                {allCities === "isLoading" && (
                  <MenuItem disabled>
                    Loading...
                  </MenuItem>
                )}
                {allCities !== "isLoading" && allCities.length === 0 && (
                  <MenuItem disabled>
                    No data
                  </MenuItem>
                )}

                {allCities !== "isLoading" && allCities.length !== 0 && allCities?.result?.[0].cities
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(city => (
                    <MenuItem key={city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl></div>
                        {permissions && permissions.includes("CanAddPracticeLocation") && (
                            <CustomButton text="Add Practice Location" width="180px" onClick={handleAddPracticeLocationClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ PracticeLocationData}
                    isLoading={practiceLocations === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {practiceLocations !== 'isLoading' && practiceLocations.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
            </div>

        </div>
        
      {showModal && (
        <AddPracticeLocations
          onClose={handleCloseModal}
          editData={editData}
          onSave={handleSaveData}
        />
      )}
      </div>
     
    </>
  )
}

export default PracticeLocations
