import React, { useState, useEffect, useRef } from 'react';
import "./SingleSelect.css";
import BodySmallRegular from './BodySmallRegular';
import { Search, ChevronDown } from "lucide-react";
import { CircleAlert } from "lucide-react";

const SingleSelect = ({ options, value, onChange, disabled = false, placeholder = 'Select', label, isSearchable, secondaryLabel, type, icon, iconStart, iconEnd, error, labelIcon }) => {
    const [selectedOption, setSelectedOption] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const dropdownRef = useRef(null);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const handleToggleDropdown = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleOptionClick = (option) => {
        setSelectedOption(option.label);
        onChange(option, label);
        setIsOpen(false);
    };
    useEffect(() => {
        if (searchQuery.trim()) {
            const lowerCaseQuery = searchQuery.toLowerCase();
            setFilteredOptions(
                options?.filter((option) =>
                    option.label.toLowerCase().includes(lowerCaseQuery)
                )
            );
        } else {
            setFilteredOptions(options);
        }
    }, [searchQuery, options]);
    useEffect(() => {
        if (value && options && options.length > 0) {
            const matchedOption = options.find((option) => option.value == value);
            if (matchedOption) {
                setSelectedOption(matchedOption.label);
            } else {
                setSelectedOption('');
            }
        } else {
            setSelectedOption('');
        }
    }, [value, options]);
    return (
        <div className={`custom-single-select-container ${disabled ? 'custom-disabled' : ''}`}
            ref={dropdownRef}>
            <div className='single-select-main'>
                <div className='custom-single-select-label-container'>
                    {
                        labelIcon && (
                            labelIcon
                        )
                    }
                    {label && label.length !== 0 && (
                        <BodySmallRegular color='#1E293B' text={label} />
                    )}
                </div>

                <div className='custom-single-select-div'>
                    <div className="custom-single-select" onClick={handleToggleDropdown}>
                        <span className='main-label-container'>{selectedOption || placeholder}</span>
                        <span className="custom-single-select-icon">
                            {icon ? (
                                icon
                            ) : (
                                <ChevronDown />
                            )}
                        </span>
                    </div>
                    {error && error.trim().length > 0 && (
                        <div className="error-single-select-container">
                            <CircleAlert height={16} />
                            <span className="single-select-error-text">
                                {error}
                            </span>
                        </div>
                    )}
                </div>
            </div>

            {isOpen && (
                <div className="custom-single-select-dropmenu-container">

                    {options && options.length > 0 ? (
                        <>
                            {isSearchable && (
                                <div className='custom-single-select-search-container'>
                                    <input
                                        className='search-input'
                                        placeholder='Search'
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <Search
                                        width={18}
                                    />
                                </div>
                            )}
                            <div className="custom-single-select-options">
                                {filteredOptions && filteredOptions.length > 0 ? filteredOptions.map((option) => (
                                    <div
                                        key={option.value}
                                        className="custom-single-select-option"
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        <div className='primary-label-div'>
                                            {type && type === "radio" && (
                                                <input
                                                    type="radio"
                                                    className="custom-radio-input"
                                                    checked={value === option.value}
                                                />
                                            )}
                                            {iconStart && (
                                                iconStart
                                            )}
                                            <span className='label-text'>{option.label}</span>
                                        </div>
                                        {secondaryLabel && option.secondaryLabel && (
                                            <span className='secondary-label'>{option.secondaryLabel}</span>
                                        )}
                                        {iconEnd && (
                                            iconEnd
                                        )}
                                    </div>
                                ))
                                    : (
                                        <div
                                            className="custom-single-select-option"
                                        >
                                            <span>No data</span>
                                        </div>
                                    )
                                }
                            </div>
                        </>
                    ) : (
                        <div className="custom-single-select-option">
                            <span>No data</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
export default SingleSelect;