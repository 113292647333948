import React, { useEffect, useState } from "react";
import "./StackedBarchart.css";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
// import { StackedBarchartFilter } from "./StackedBarchartFilter";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Loader from '../Loader';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Button, Menu, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Tooltip as MaterialTooltip } from "@mui/material";
import { getEmployeeSubOrdinates } from "../../../actions/userActions/employeeAction";
import { getCallAveragPractice } from "../../../actions/userActions/callAveragePract";
import { getPracticeLocations } from "../../../actions/userActions/practiceLocationAction";
import { getCities } from "../../../actions/userActions/cityAction";
import ApplyButton from "../buttons/ApplyButton";
import ResetButton from "../buttons/ResetButton";



export function StackedBarchart({onChangePracticeLocation}) {
    const [appliedFilters, setAppliedFilters] = useState([]);
    function choosefilters(filters) {
        setAppliedFilters(filters);
    }
    const [locationCity, setLocationCity] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [customDate, setCustomDate] = useState(false);
    const [formError, setFormError] = useState({});
    const currentDate = moment();
    const [employeesName, setEmployeesName] = useState([]);
    // const employees = useSelector((state) => state.employees_reducer.employees);
    const [Filters, setFilters] = useState({
        practiceLocation: 0,
        employeeIds: [],
        fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
        toDate: moment(currentDate).format("YYYY-MM-DD"),
    });
    const [selected, setSelected] = useState({
        practiceLocation: "",
        employeeIds: [],
        fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
        toDate: moment(currentDate).format("YYYY-MM-DD"),
    });
    const setEmployeeNames = (array) => {
        setEmployeesName(array);
    };
    const user = JSON.parse(localStorage.getItem("user"));
    const employeeId =
        user.result[0] !== undefined ? user.result[0].user.id : false
    const practiceLocations = useSelector((state) => state.practiceLocations_reducer?.practiceLocations);
    const cities = useSelector((state) => state.cities_reducer.cities);
    const employees = useSelector((state) => state.employees_reducer.employeeSubordinates);
    useEffect(() => {

        let data = [];
        employees &&
            employees !== "isLoading" &&
            employees.map((row) => {
                appliedFilters.map((row2) => {
                    if (row.id == row2) {
                        data.push(row.firstName + " " + row.lastName);
                    }
                });
            });

        setEmployeeNames(data);
    }, [employees, appliedFilters]);
    const dispatch = useDispatch();
    const [uniqueEmployees, setUniqueEmployees] = useState([]);
    const callAvgPracticeLocation = useSelector(
        (state) => state.call_avg_pract_reducer.callAvgPract
    );
    const [employeeColors, setEmployeeColors] = useState({});

    // push data in array for rechart
    const [data, setData] = useState([]);

    useEffect(() => {
        if (
            callAvgPracticeLocation &&
            callAvgPracticeLocation !== "isLoading" &&
            callAvgPracticeLocation.byPracticeLocations &&
            callAvgPracticeLocation.byPracticeLocations.length > 0
        ) {
            const uniqueEmployees = [];
            const groupedData = callAvgPracticeLocation.byPracticeLocations.reduce((result, item) => {
                const date = moment(item.dateTime).format("D MMM, YY");
                const key = `${date}`;

                if (!result[key]) {
                    result[key] = { date };
                }

                if (!uniqueEmployees.includes(item.employeeName)) {
                    uniqueEmployees.push(item.employeeName);
                }

                if (!result[key][item.employeeName]) {
                    result[key][item.employeeName] = item.calls;
                } else {
                    result[key][item.employeeName] += item.calls;
                }

                return result;
            }, {});
            const employeeNames = new Set();
            Object.values(groupedData).forEach((item) => {
                Object.keys(item).forEach((key) => {
                    if (key !== "date") {
                        employeeNames.add(key);
                    }
                });
            });

            const outputData = Object.values(groupedData).map((item) => {
                const formattedItem = {
                    date: item.date,
                };
                employeeNames.forEach((employeeName) => {
                    formattedItem[employeeName] = item[employeeName] || 0;
                });

                return formattedItem;
            }).sort((a, b) => moment(a.date, "D MMM, YY").toDate() - moment(b.date, "D MMM, YY").toDate());
            setData(outputData);
            setUniqueEmployees(uniqueEmployees);
        }
    }, [callAvgPracticeLocation]);

    function getRandomColor() {
        const minChannelValue = 50;
        const maxChannelValue = 200;

        const randomRed = Math.floor(Math.random() * (maxChannelValue - minChannelValue + 1)) + minChannelValue;
        const randomGreen = Math.floor(Math.random() * (maxChannelValue - minChannelValue + 1)) + minChannelValue;
        const randomBlue = Math.floor(Math.random() * (maxChannelValue - minChannelValue + 1)) + minChannelValue;

        return `rgb(${randomRed}, ${randomGreen}, ${randomBlue})`;
    }
    useEffect(() => {
        if (uniqueEmployees.length > 0) {
            const newEmployeeColors = {};
            uniqueEmployees.forEach((employee) => {
                if (!employeeColors[employee]) {
                    newEmployeeColors[employee] = getRandomColor();
                }
            });
            setEmployeeColors((prevColors) => ({ ...prevColors, ...newEmployeeColors }));
        }
    }, [uniqueEmployees]);
    //

    useEffect(() => {
        const cityBody = {
            "filter": {
                "textSearch": "",
                "countryId": 1
            },
            "pageNumber": -1,
            "pageSize": 0
        }
        if (open) {
            dispatch(getCities(cityBody))
                .then(() => {
                    dispatch(getEmployeeSubOrdinates(employeeId));
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        }

    }, [open]);
    useEffect(() => {
        const practiceLocationBody = {
            filter: {
                textSearch: "",
                city: locationCity
            },
            pageNumber: -1,
            pageSize: 0,
        }
        if (locationCity) {
            dispatch(getPracticeLocations(practiceLocationBody));
        }
    }, [locationCity])
    const handleApply = () => {
        if (validateForm()) {
            const body = {
                practiceLocationFilters: {
                    practiceLocation: Filters.practiceLocation?Filters.practiceLocation:0,
                    employeeIds: Filters.employeeIds?Filters.employeeIds:[0],
                },
                startTime: Filters.fromDate?Filters.fromDate:new Date().toISOString(),
                endTime: Filters.toDate?Filters.toDate: new Date().toISOString(),
            };
            onChangePracticeLocation(body)
            dispatch(getCallAveragPractice(body));
            choosefilters(Filters.employeeIds);
            handleClose();
        }
    };

    const handleReset = () => {
        setSelected({
            practiceLocation: "",
            employeeIds: [],
            fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
            toDate: moment(currentDate).format("YYYY-MM-DD"),
        });
        setFilters({
            practiceLocation: 0,
            employeeIds: [],
            fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
            toDate: moment(currentDate).format("YYYY-MM-DD"),
        });
        setSelectedButton("last 7 days");
    }
    //
    const validateForm = () => {
        let errors = {};
        let isValid = true;
        if (!Filters.fromDate) {
            errors.fromDate = "From date is required.";
            isValid = false;
        }
        if (!Filters.toDate) {
            errors.toDate = "To date is required.";
            isValid = false;
        }

        setFormError(errors);
        return isValid;
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const [selectedButton, setSelectedButton] = useState("last 7 days");

    const handleToggleSelection = (event, newSelection) => {
        if (newSelection === "last 7 days") {
            setCustomDate(false);
            const fromDate = moment(currentDate).subtract(7, "days").format("YYYY-MM-DD");
            const toDate = moment(currentDate).format("YYYY-MM-DD");
            setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
        } else if (newSelection === "today") {
            setCustomDate(false);
            const currentDate = moment().format("YYYY-MM-DD");
            setSelected(prevValues => ({ ...prevValues, fromDate: currentDate, toDate: currentDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: currentDate, toDate: currentDate }));
        } else if (newSelection === "yesterday") {
            setCustomDate(false);
            const yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");
            setSelected(prevValues => ({ ...prevValues, fromDate: yesterdayDate, toDate: yesterdayDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: yesterdayDate, toDate: yesterdayDate }));
        } else if (newSelection === "last 30 days") {
            setCustomDate(false);
            const fromDate = moment().subtract(29, "days").toDate();
            const toDate = moment().toDate();
            setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
        } else if (newSelection === "this month") {
            setCustomDate(false);
            const fromDate = moment().startOf("month").toDate();
            const toDate = moment().endOf("month").toDate();
            setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
        } else if (newSelection === "last month") {
            setCustomDate(false);
            const fromDate = moment().subtract(1, "month").startOf("month").toDate();
            const toDate = moment().subtract(1, "month").endOf("month").toDate();
            setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
        } else if (newSelection === "last year") {
            setCustomDate(false);
            const fromDate = moment().subtract(1, "year").startOf("year").toDate();
            const toDate = moment().subtract(1, "year").endOf("year").toDate();
            setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
            setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
        } else if (newSelection === "custom") {
            setCustomDate(true);
            setSelected(prevValues => ({ ...prevValues, fromDate: "", toDate: "" }));
            setFilters(prevValues => ({ ...prevValues, fromDate: "", toDate: "" }));
        }

        setSelectedButton(newSelection);
    };
    const handleLocationChange = (event) => {
        setSelected(prevValues => ({
            ...prevValues,
            practiceLocation: event.target.value,
        }));
        setFilters(prevValues => ({
            ...prevValues,
            practiceLocation: event.target.value,
        }));
    };

    const handleEmployeesChange = (event, selectedEmployees) => {
        const selectedEmployeeIds = selectedEmployees.map(Employee => Employee.id);
        setSelected(prevValues => ({
            ...prevValues,
            employeeIds: selectedEmployees,
        }));
        setFilters(prevValues => ({
            ...prevValues,
            employeeIds: selectedEmployeeIds,
        }));
    };
    const handleFromDateChange = (date) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        setSelected(prevValues => ({
            ...prevValues,
            fromDate: formattedDate,
        }));
        setFilters(prevValues => ({
            ...prevValues,
            fromDate: formattedDate,
        }));
    };
    const handleToDateChange = (date) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        setSelected(prevValues => ({
            ...prevValues,
            toDate: formattedDate,
        }));
        setFilters(prevValues => ({
            ...prevValues,
            toDate: formattedDate,
        }));
    };

    const handleLocationCityChange = (event) => {
        setLocationCity(event.target.value);
    };
    return (
        <div className="chart-container" style={{ position: "relative", height: '400px', padding: '24px', boxShadow: "0px 2px 10px 0px #0000001A" }}>
            <div>
                <div className="row" style={{ height: '50px' }}>
                    <div className="col-10" style={{ height: "20px" }}><p style={{
                        fontFamily: "Roboto",
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "23px",
                        textAlign: "left",
                        color: "#000000"
                    }}>Call average by Practice Location</p>
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                        <MaterialTooltip title="Apply Filter" arrow>
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                startIcon={<FilterAltOutlinedIcon />}
                                sx={{
                                    color: "#505157",
                                    borderColor: "#505157",
                                    backgroundColor: "#FFFFFF",
                                    border: "1px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    '& .MuiButton-startIcon': {
                                        margin: "0",
                                    },
                                    '&:hover': {
                                        color: "#2F54EB",
                                        borderColor: "#2F54EB",
                                        '& .MuiButton-startIcon': {
                                            color: "#2F54EB",
                                        }
                                    }
                                }}
                            >
                            </Button>
                        </MaterialTooltip>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            PaperProps={{
                                style: {
                                    width: "989px",
                                    top: "-47614px",
                                    left: "-4486px",
                                    borderRadius: "4px",
                                    background: "#FFFFFF",
                                    boxShadow: "0px 11px 15px -7px #00000033",
                                },
                            }}
                        >
                            <div className="row" style={{
                                padding: "16px 24px 16px 24px",
                                justify: "space-between",
                                display: "flex",
                                gap: "24px"
                            }}>
                                <div className="col-12 d-flex ml-2">
                                    <h6 style={{
                                        fontFamily: "Roboto",
                                        fontSize: "20px",
                                        fontWeight: "500",
                                        lineHeight: "32px",
                                        textAlign: "left",
                                        color: "#000000DE"
                                    }}>
                                        Filter Results
                                    </h6>
                                    <MaterialTooltip title="Close Filter" arrow>
                                        <CloseIcon style={{ marginLeft: "auto", cursor: "pointer" }} onClick={handleClose} />
                                    </MaterialTooltip>
                                </div>
                            </div>
                            <div className="row" style={{
                                gap: "24px",
                                borderBottom: "1px solid #0000001F",
                                padding: "24px 16px 24px 16px",
                                alignItems: "center"
                            }}>
                                <div className="row" style={{ gap: "16px" }}>
                                    <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: "48px" }}>
                                        <ToggleButtonGroup
                                            exclusive
                                            value={selectedButton}
                                            onChange={handleToggleSelection}
                                            aria-label="button group"
                                        >
                                            <ToggleButton
                                                value="today"
                                                style={{
                                                    width: "114px",
                                                    height: "32px",
                                                    padding: "4px 8px 4px 8px",
                                                    gap: "8px",
                                                    borderRadius: "4px",
                                                    textTransform: "none",
                                                    backgroundColor: selectedButton === "today" ? "#2F54EB" : "inherit",
                                                    color: selectedButton === "today" ? "#fff" : "inherit"
                                                }}
                                            >
                                                Today
                                            </ToggleButton>
                                            <ToggleButton
                                                value="yesterday"
                                                style={{
                                                    width: "114px",
                                                    height: "32px",
                                                    padding: "4px 8px 4px 8px",
                                                    gap: "8px",
                                                    borderRadius: "4px",
                                                    textTransform: "none",
                                                    backgroundColor: selectedButton === "yesterday" ? "#2F54EB" : "inherit",
                                                    color: selectedButton === "yesterday" ? "#fff" : "inherit"
                                                }}
                                            >
                                                Yesterday
                                            </ToggleButton>
                                            <ToggleButton
                                                value="last 7 days"
                                                style={{
                                                    width: "114px",
                                                    height: "32px",
                                                    padding: "4px 8px 4px 8px",
                                                    gap: "8px",
                                                    borderRadius: "4px",
                                                    textTransform: "none",
                                                    backgroundColor: selectedButton === "last 7 days" ? "#2F54EB" : "inherit",
                                                    color: selectedButton === "last 7 days" ? "#fff" : "inherit"
                                                }}
                                            >
                                                Last 7 days
                                            </ToggleButton>
                                            <ToggleButton
                                                value="last 30 days"
                                                style={{
                                                    width: "114px",
                                                    height: "32px",
                                                    padding: "4px 8px 4px 8px",
                                                    gap: "8px",
                                                    borderRadius: "4px",
                                                    textTransform: "none",
                                                    backgroundColor: selectedButton === "last 30 days" ? "#2F54EB" : "inherit",
                                                    color: selectedButton === "last 30 days" ? "#fff" : "inherit"
                                                }}
                                            >
                                                Last 30 days
                                            </ToggleButton>
                                            <ToggleButton
                                                value="this month"
                                                style={{
                                                    width: "114px",
                                                    height: "32px",
                                                    padding: "4px 8px 4px 8px",
                                                    gap: "8px",
                                                    borderRadius: "4px",
                                                    textTransform: "none",
                                                    backgroundColor: selectedButton === "this month" ? "#2F54EB" : "inherit",
                                                    color: selectedButton === "this month" ? "#fff" : "inherit"
                                                }}
                                            >
                                                This Month
                                            </ToggleButton>
                                            <ToggleButton
                                                value="last month"
                                                style={{
                                                    width: "114px",
                                                    height: "32px",
                                                    padding: "4px 8px 4px 8px",
                                                    gap: "8px",
                                                    borderRadius: "4px",
                                                    textTransform: "none",
                                                    backgroundColor: selectedButton === "last month" ? "#2F54EB" : "inherit",
                                                    color: selectedButton === "last month" ? "#fff" : "inherit"
                                                }}
                                            >
                                                Last Month
                                            </ToggleButton>
                                            <ToggleButton
                                                value="last year"
                                                style={{
                                                    width: "114px",
                                                    height: "32px",
                                                    padding: "4px 8px 4px 8px",
                                                    gap: "8px",
                                                    borderRadius: "4px",
                                                    textTransform: "none",
                                                    backgroundColor: selectedButton === "last year" ? "#2F54EB" : "inherit",
                                                    color: selectedButton === "last year" ? "#fff" : "inherit"
                                                }}
                                            >
                                                Last Year
                                            </ToggleButton>
                                            <ToggleButton
                                                value="custom"
                                                style={{
                                                    width: "114px",
                                                    height: "32px",
                                                    padding: "4px 8px 4px 8px",
                                                    gap: "8px",
                                                    borderRadius: "4px",
                                                    textTransform: "none",
                                                    backgroundColor: selectedButton === "custom" ? "#2F54EB" : "inherit",
                                                    color: selectedButton === "custom" ? "#fff" : "inherit"
                                                }}
                                            >
                                                Custom
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                    <div className="col-12 d-flex" style={{
                                        height: "56px",
                                    }}>
                                        <div className="col-6">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="From"
                                                    valueType="date"
                                                    value={selected.fromDate && selected.fromDate.length != 0 ? dayjs(selected.fromDate) : null}
                                                    onChange={handleFromDateChange}
                                                    disabled={!customDate}
                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                    sx={{ width: "100%" }}
                                                />
                                            </LocalizationProvider>
                                            {formError.fromDate && (
                                                <span style={{ color: "red" }}>{formError.fromDate}</span>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="To"
                                                    valueType="date"
                                                    value={selected.toDate && selected.toDate.length != 0 ? dayjs(selected.toDate) : null}
                                                    onChange={handleToDateChange}
                                                    disabled={!customDate}
                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                    sx={{ width: "100%" }}
                                                />
                                            </LocalizationProvider>
                                            {formError.toDate && (
                                                <span style={{ color: "red" }}>{formError.toDate}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ gap: "16px" }}>
                                    <div className="col-12 d-flex" >
                                        <div className="col">
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">City</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={locationCity}
                                                    label="City"
                                                    onChange={handleLocationCityChange}
                                                    MenuProps={{ PaperProps: { style: { maxHeight: 160 } } }}
                                                >
                                                    {cities === "isLoading" && (
                                                        <MenuItem disabled>
                                                            Loading...
                                                        </MenuItem>
                                                    )}

                                                    {cities !== "isLoading" && cities.length === 0 && (
                                                        <MenuItem disabled>
                                                            No data
                                                        </MenuItem>
                                                    )}

                                                    {cities !== "isLoading" && cities.length !== 0 &&  cities.result?.[0]?.cities
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map(city => (
                                                            <MenuItem key={city.id} value={city.id}>
                                                                {city.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>

                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ gap: "16px" }}>
                                    <div className="col-12 d-flex" >
                                        <div className="col-6">
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selected.practiceLocation}
                                                    label="Location"
                                                    onChange={handleLocationChange}
                                                    MenuProps={{ PaperProps: { style: { maxHeight: 160 } } }}
                                                >
                                                    {practiceLocations && practiceLocations === "isLoading" && (
                                                        <MenuItem disabled>
                                                            Loading...
                                                        </MenuItem>
                                                    )}

                                                    {practiceLocations !== "isLoading" && practiceLocations.length === 0 && locationCity && (
                                                        <MenuItem disabled>
                                                            No data
                                                        </MenuItem>
                                                    )}
                                                    {practiceLocations && practiceLocations !== "isLoading" && practiceLocations.length === 0 && !locationCity && (
                                                        <MenuItem disabled>
                                                            Select city first
                                                        </MenuItem>
                                                    )}

                                                    {practiceLocations && practiceLocations !== "isLoading" && practiceLocations.length !== 0 &&  practiceLocations?.result?.[0]?.practiceLocations
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map(practiceLocation => (
                                                            <MenuItem key={practiceLocation.id} value={practiceLocation.id}>
                                                                {practiceLocation.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-6">
                                            <Autocomplete
                                                multiple
                                                limitTags={2}
                                                id="multiple-limit-tags-employee"
                                                onChange={handleEmployeesChange}
                                                value={selected.employeeIds}
                                                menuprops={{ PaperProps: { style: { maxHeight: 200, width: 250 } } }}
                                                options={employees === "isLoading"
                                                    ? [{ name: "Loading...", id: -1 }]
                                                    : employees.length === 0
                                                        ? [{ name: "No data", id: -1 }]
                                                        : employees
                                                            .sort((a, b) => {
                                                                const firstNameComparison = a.firstName.localeCompare(b.firstName);
                                                                if (firstNameComparison !== 0) {
                                                                    return firstNameComparison;
                                                                }
                                                                return a.lastName.localeCompare(b.lastName);
                                                            })
                                                }
                                                getOptionKey={(option)=>option.id}
                                                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Employee" />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mr-4" style={{
                                padding: "16px 24px 16px 24px",
                                gap: "8px",
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                                <ApplyButton onClick={handleApply} />
                                <ResetButton onClick={handleReset} />
                            </div>

                        </Menu>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ height: '30px' }}>
                            <p style={{ margin: "8px", maxHeight: '24px', fontFamily: "inter", fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>
                                {callAvgPracticeLocation.byPracticeLocations?.[0]?.practiceLocationName}
                            </p>
                        </div>
                    </div>

                    {/* <div className="row" style={{ marginTop: '8px' }}>
                        <div className="col-12 d-flex" style={{ width: '480px', height: '24px' }}>
                            {employeesName &&
                                employeesName.map((pn) => <span className="mr-2" style={{ paddingLeft: '4px', paddingBottom: '3px', paddingRight: '4px', borderRadius: '100px', border: '1px solid #00000014', height: '24px', width: "" }} key={pn}>{pn}</span>)}
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="graph-container" style={{ height: '320px' }}>
                {callAvgPracticeLocation === 'isLoading' && (<div className="col-12 d-flex justify-content-center"><Loader colored={true} /></div>)}
                {callAvgPracticeLocation !== 'isLoading' && callAvgPracticeLocation.byPracticeLocations && callAvgPracticeLocation.byPracticeLocations.length > 0 && (
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={data}
                            margin={{
                                top: 24,
                                right: 10,
                                left: 2,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tick={{ angle: -45, textAnchor: 'end' }} height={70} />
                            <YAxis>
                                <Label
                                    value="Number of Calls"
                                    angle={-90}
                                    position="insideLeft"
                                    style={{ textAnchor: 'middle' }}
                                />
                            </YAxis>
                            <Tooltip />
                            <Legend />
                            {uniqueEmployees.map((employee, index) => (
                                <Bar
                                    key={index}
                                    dataKey={employee}
                                    stackId="stacked"
                                    fill={employeeColors[employee]}
                                />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                )}
                {!callAvgPracticeLocation || (callAvgPracticeLocation === "isLoading") ||
                    (callAvgPracticeLocation.length === 0 ||
                        (callAvgPracticeLocation.byPracticeLocations && callAvgPracticeLocation.byPracticeLocations.length === 0)) && (
                        <div style={{ height: "240px", width: "480", border: "1px solid grey", display: "flex", alignItems: "center", justifyContent: "center", margin: "24px 10px 5px 20px" }}>
                            <div className="col-12 d-flex justify-content-center">
                                No Data to show "Apply or change Filters"
                            </div>
                        </div>
                    )}
            </div>

        </div>
    );
}