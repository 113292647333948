import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllPermissions,
  getAllPermissionsGroup,
} from "../../actions/userActions/permissionsAction";
import Loader from "../subComponents/Loader";
import { Link, useHistory, useLocation } from "react-router-dom";
import Pagination from "../paginatioin/pagination"
import "./Permissions.css";
import { IconButton, Tooltip } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { SIZE_OF_PAGE } from "../../actions/types";
import HeaderComponent from "../reuse_components/HeaderComponent";
import CustomButton from "../subComponents/buttons/CustomButton";
import DynamicTable from "../reuse_components/DynamicTable";
import SearchInput from "../subComponents/search/SearchInput";
import Heading6Regular from "../designSystem/Heading6Regular";
import BodySmallMedium from "../designSystem/BodySmallMedium";

import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import CustomPagination from "../subComponents/pagination/CustomPagination";
import AddPermissions from "./AddPermissions";

function Permissions() {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [permission, setPermission] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [permissiondata, setPermissionData] = useState([]);
  const itemsPerPage = 10;
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const setButtonPermission = () => {
    if(permissions.includes('CanAddPermission')){
      setPermission(true);
    }
  }
  useEffect(() => {
      dispatch(getAllPermissionsGroup(currentPage, itemsPerPage, searchQuery));
  }, [dispatch, currentPage, searchQuery]);

  const allPermissionsGroup = useSelector(
    (state) => state.permissions_reducer.allPermissionsGroup
  );
  const allPermissionsGroupData = allPermissionsGroup?.result || [];

  const totalPages = allPermissionsGroup?.totalPages || 0;
  const totalItems = allPermissionsGroup?.totalCount || 0;


  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}&search=${query}`);
  };
  useEffect(() => {
    const permissionGroupData = allPermissionsGroup && allPermissionsGroupData;
    const PermissionGroupData = permissionGroupData?.map((permissions) => ({
      ...permissions,
      status: (
        <span
          style={{
            background: "#4361FF1A",
            width: "56px",
            height: "26px",
            padding: "3px 8px",
            display: "inline-block",
            textAlign: "center",
            lineHeight: "20px",
            borderRadius: "4px 0px 0px 0px",
            opacity: 1 ,
          }}
        >
          {permissions.active ? "Active" : "Inactive"}
        </span>
      ),
    }));
    setPermissionData(PermissionGroupData);
  }, [permissions]);
  
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Description", accessor: "description" },
     { label: "Status", accessor: "status" },  
  ];
  
const handleEditPermission = (row) => {
 setEditData(row);
  setShowModal(true);
};
const handleAddPermissionClick = () => {
  setEditData(null);
  setShowModal(true);
};


const handleCloseModal = () => {
  setShowModal(false);
};
const handleSaveData = async () => {
  dispatch(getAllPermissions(currentPage, SIZE_OF_PAGE, query));
  setShowModal(false);
};

  const actions = [  
    {
        label: 'Edit Permission',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdatePermission',
        handler:handleEditPermission,
    },
];

const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
  setCurrentPage(1);
};
  return (
    <>
 <div>
      <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Permission Groups" color="#1E293B" />

                </div>
            </div>
            <Heading6Regular text="Permission Groups" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearchChange}
                        value ={searchQuery}
             />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {allPermissionsGroup !== 'isLoading' && allPermissionsGroup.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                   
                        {permissions && permissions.includes("CanAddDesignation") && (
                            <CustomButton text="Add Permission Group" width="180px" onClick={handleAddPermissionClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ permissiondata}
                    isLoading={allPermissionsGroup === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {allPermissionsGroup !== 'isLoading' && allPermissionsGroup.length > 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
            </div>

        </div>
        {showModal && (
        <AddPermissions
          onClose={handleCloseModal}
          editData={editData}
          onSave={handleSaveData}
        />
      )}
      </div>
    
    </>
  );
}

export default Permissions;
