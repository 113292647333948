import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getOfficeLocations } from '../../actions/userActions/officelocationAction'
import Loader from '../subComponents/Loader'
import ServerSideSearch from '../subComponents/search/ServerSideSearch'
import Pagination from '../paginatioin/pagination'
import './OfficeLocation.css'
import AddButton from '../subComponents/buttons/AddButton'
import { IconButton, Tooltip } from "@mui/material";
import EditButton from '../subComponents/buttons/EditButton'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CustomButton from '../subComponents/buttons/CustomButton'
import DynamicTable from '../reuse_components/DynamicTable'
import CustomPagination from '../subComponents/pagination/CustomPagination'
import SearchInput from '../subComponents/search/SearchInput'
import BodySmallMedium from '../designSystem/BodySmallMedium'
import Heading6Regular from '../designSystem/Heading6Regular'

import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import thIcon from "../../assets/images/thIcon.svg";
import AddOfficeLocations from './AddOfficeLocations'

function OfficeLocations() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [OfficeLocationsData, setOfficeLocationsData] = useState([]);
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const [selected, setSelected] = useState({
    textSearch: '',
  })
  const [filters, setFilters] = useState({
    textSearch: '',
  })
  useEffect(() => {
    dispatch(getOfficeLocations(body))
  }, [page, filters])

  const officeLocations = useSelector(
    (state) => state.officeLocations_reducer.officeLocations,
  )
  console.log(officeLocations,'s');
  
  const totalPages = officeLocations?.totalPages || 0;
  const totalItems = officeLocations?.totalCount || 0;
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const body = {
    filter: filters,
    pageNumber: page,
    pageSize: 10,
  }


  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 1) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }
  const handleAddOfficeLocationClick = () => {
    setEditData(null);
    setShowModal(true);
  };
  
  
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSaveData = async () => {
    dispatch(getOfficeLocations(body));
    setShowModal(false);
  }; 
  useEffect(() => {
    const officeLocationData = officeLocations && officeLocations.result?.[0]?.officeLocations  ;
    const OfficeLocationData = officeLocationData?.map((officeLocations) => ({
      ...officeLocations,
      isHeadOffice: officeLocations.isHeadOffice ? "Yes" : "No",
    }));
    
    setOfficeLocationsData(OfficeLocationData);
  }, [officeLocations]);
 console.log(totalItems,'items');

  const columns = [
    { label: "Address", accessor: "address" },
    { label: "City", accessor: "cityName" },
    { label: "Contact No", accessor: "contact1" },
    { label: "HeadOffice", accessor: "isHeadOffice" },  
  ];
  const handleEditOfficeLocation = (row) => {
    setEditData(row);
    setShowModal(true);
  };
   const actions = [  
    {
        label: 'Edit designation',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdateOfficeLocation',
        handler:handleEditOfficeLocation,
    },
];
  return (
    <>
 <div>
      <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Office Location" color="#1E293B" />
                </div>
            </div>
            <Heading6Regular text="Office Location" color="#1E293B" />

            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearch}
              value={selected.textSearch}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {officeLocations !== 'isLoading' && officeLocations.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                   
                        {permissions && permissions.includes("CanAddOfficeLocation") && (
                            <CustomButton text="Add Office Location" width="180px" onClick={handleAddOfficeLocationClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ OfficeLocationsData}
                    isLoading={officeLocations === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
                                       <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {officeLocations !== 'isLoading' && officeLocations.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
            </div>

        </div>
        {showModal && (
        <AddOfficeLocations
          onClose={handleCloseModal}
          editData={editData}
          onSave={handleSaveData}
        />
      )}
      </div>

    </>
  );
}

export default OfficeLocations
