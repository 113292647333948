import React, { useState, useEffect, useRef } from 'react';
import "./MultiSelect.css";
import BodySmallRegular from './BodySmallRegular';
import { Search, ChevronDown } from "lucide-react";
import { CircleAlert } from "lucide-react";

const MultiSelect = ({ options, value = [], onChange, disabled = false, placeholder = 'Select', label, isSearchable, secondaryLabel, icon, iconStart, iconEnd, error }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const dropdownRef = useRef(null);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const containerRef = useRef(null);
    const [visibleChips, setVisibleChips] = useState([]);
    const [overflowCount, setOverflowCount] = useState(0);
    const handleToggleDropdown = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleOptionClick = (option) => {
        // Ensure value is an array
        const selectedValues = Array.isArray(value) ? value : [];
        const selectedLabels = Array.isArray(selectedOptions) ? selectedOptions : [];

        let updatedValues, updatedLabels;

        if (selectedValues.includes(option.value)) {
            // Remove the option
            updatedValues = selectedValues.filter((val) => val !== option.value);
            updatedLabels = selectedLabels.filter((label) => label !== option.label);
        } else {
            // Add the option
            updatedValues = [...selectedValues, option.value];
            updatedLabels = [...selectedLabels, option.label];
        }

        setSelectedOptions(updatedLabels); // Store labels
        onChange(option.value); // Send values to parent
    };

    useEffect(() => {
        if (searchQuery.trim()) {
            const lowerCaseQuery = searchQuery.toLowerCase();
            setFilteredOptions(
                options.filter((option) =>
                    option.label.toLowerCase().includes(lowerCaseQuery)
                )
            );
        } else {
            setFilteredOptions(options);
        }
    }, [searchQuery, options]);
    useEffect(() => {
        if (Array.isArray(value) && options?.length > 0) {
            const matchedLabels = options
                .filter((option) => value.includes(option.value))
                .map((option) => option.label);

            setSelectedOptions(matchedLabels);
        } else {
            setSelectedOptions([]);
        }
    }, [value, options]);

// Handle selected options overflow logic
useEffect(() => {
    if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        let totalWidth = 0;
        let visible = [];
        let overflow = 0;

        const tempSpan = document.createElement('span');
        tempSpan.className = 'label-chip';
        tempSpan.style.position = 'absolute';
        tempSpan.style.visibility = 'hidden';
        document.body.appendChild(tempSpan);

        selectedOptions.forEach((option) => {
            tempSpan.innerText = option;
            const chipWidth = tempSpan.offsetWidth; // Add spacing for each chip

            if (totalWidth + chipWidth < containerWidth) { // Reserve space for "+X" text
                visible.push(option);
                totalWidth += chipWidth;
            } else {
                overflow++;
            }
        });

        document.body.removeChild(tempSpan);
        setVisibleChips(visible);
        setOverflowCount(overflow);
    }
}, [selectedOptions]);
    return (
        <div className={`custom-multi-select-container ${disabled ? 'custom-disabled' : ''}`}
            ref={dropdownRef}>
            <div className='multi-select-main'>
                {label && label.length !== 0 && (
                    <BodySmallRegular color='#1E293B' text={label} />
                )}
                <div className='custom-multi-select-div'>
                    <div className="custom-multi-select" onClick={handleToggleDropdown}>
                        <div className='selected-options-container' ref={containerRef}>
                            {visibleChips.length > 0
                                ? visibleChips.map((option, index) => (
                                    <span key={index} className='label-chip'>
                                        {option}
                                    </span>
                                ))
                                : <span className="placeholder-text">{placeholder}</span>}

                            {overflowCount > 0 && (
                                <span>+{overflowCount}</span>
                            )}
                        </div>
                        <span className="custom-multi-select-icon">
                            {icon ? (
                                icon
                            ) : (
                                <ChevronDown />
                            )}
                        </span>
                    </div>
                    {error && error.trim().length > 0 && (
                        <div className="error-multi-select-container">
                            <CircleAlert height={16} />
                            <span className="multi-select-error-text">
                                {error}
                            </span>
                        </div>
                    )}
                </div>
            </div>

            {isOpen && (
                <div className="custom-multi-select-dropmenu-container">

                    {options && options.length > 0 ? (
                        <>
                            {isSearchable && (
                                <div className='custom-multi-select-search-container'>
                                    <input
                                        className='search-input'
                                        placeholder='Search'
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <Search
                                        width={18}
                                    />
                                </div>
                            )}
                            <div className="custom-multi-select-options">
                                {filteredOptions && filteredOptions.length > 0 ? filteredOptions.map((option) => (
                                    <div
                                        key={option.value}
                                        className="custom-multi-select-option"
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        <div className='primary-label-div'>
                                            <input
                                                type="checkbox"
                                                className="custom-checkbox-input"
                                                checked={value?.includes(option.value)}
                                            />
                                            {iconStart && (
                                                iconStart
                                            )}
                                            <span className='label-text'>{option.label}</span>
                                        </div>
                                        {secondaryLabel && option.secondaryLabel && (
                                            <span className='secondary-label'>{option.secondaryLabel}</span>
                                        )}
                                        {iconEnd && (
                                            iconEnd
                                        )}
                                    </div>
                                ))
                                    : (
                                        <div
                                            className="custom-multi-select-option"
                                        >
                                            <span>No data</span>
                                        </div>
                                    )
                                }
                            </div>
                        </>
                    ) : (
                        <div className="custom-multi-select-option">
                            <span>No data</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
export default MultiSelect;