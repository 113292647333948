import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addSubTasks, updateSubTasks } from '../../actions/userActions/subTaskAction'
import Loader from '../subComponents/Loader'
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium';
import BodySmallRegular from '../designSystem/BodySmallRegular';
import subtaskIcon from "../../assets/images/subtaskIcon.svg";
import closeModal from "../../assets/images/closeModal.svg";
import BodyLargeMedium from '../designSystem/BodyLargeMedium';
import { IconButton } from '@mui/material';
function AddSubTasks({ onClose, editData, onSave} ) {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = { name: "", description: "", active: false };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

useEffect(() => {
    if (editData) {
        setFormValues(editData);
    }
}, [editData]);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleSave = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
  
    if (Object.keys(errors).length === 0) {
      if (editData) {
        // Ensure the correct status is maintained when saving
        const updatedSubTask = {
          ...formValues,
          status: formValues.active ? "Active" : "Inactive", // Make sure status is set on save
        };
        dispatch(updateSubTasks(updatedSubTask)); 
      } else {
        dispatch(addSubTasks(formValues));
      }
      onSave(formValues); // Save the task
    }
  };
  
   const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Name is required!"
        }
        if (!values.description) {
            errors.description = "Description is required!"
        }
        return errors;
    }

    return (
        <>
         <div className="dynamicmodal-overlay">
      <div className="dynamicmodal-container">
        <div className="dynamicmodal-header mb-3">
          <div className="dynamicmodal-header-left">
            <div className="icon">
              <img src={subtaskIcon} alt="subtasks" />
            </div>
            <div className="text">
              <BodyLargeMedium
                text={editData ? "Edit SubTasks" : "Add SubTasks"}
                color="#384153"
              />
              <p>Create a smaller, manageable task within a larger task.</p>
            </div>
          </div>
          <div className="dynamicmodal-header-right">
          <IconButton onClick={onClose}>
            <img src={closeModal} alt="" />
            </IconButton>
          </div>
        </div>
       

        <form onSubmit={handleSave}>
          <div className="dynamicmodal-body">
         
            <div className="dynamicmodal-form-group">
              <label>
                <BodySmallRegular text="Name" color="#1E293B" />
              </label>
              <input
                type="text"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                placeholder="Enter Name"
                style={{
                  width: "100%",
                  height: "30px",
                  padding: "4px 10px",
                  borderRadius: "4px 0px 0px 0px",
                  background: "#F9FAFB",
                }}
              />
              {formErrors.name && (
                <div style={{ color: "red" }}>{formErrors.name}</div>
              )}
            </div>
            <div className="dynamicmodal-form-group">
              <label>
                <BodySmallRegular text="Description" color="#1E293B" />
              </label>
              <textarea
                name="description"
                value={formValues.description}
                onChange={handleChange}
                placeholder="Enter Description"
                style={{
                  width: "100%",
                  height: "124px",
                  padding: "4px 10px",
                  borderRadius: "4px 0px 0px 0px",
                  background: " #F9FAFB",
                }}
              />
              {formErrors.description && (
                <div style={{ color: "red" }}>{formErrors.description}</div>
              )}
            </div>

            <div className=" mt-5 ml-0">
              <div
                className="form-group"
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <input
                  name="active"
                  style={{ cursor: "pointer", height: "14px", width: "14px" }}
                  type="checkbox"
                  checked={formValues.active}
                  className="ml-1 mt-1"
                  onChange={handleChange}
                />
                <label
                  style={{ paddingLeft: "10px" }}
                  className="form-control-label"
                >
                  <BodyExtraSmallMedium text="Active ?" color="#1E293B" />
                </label>
              </div>
            </div>
          </div>

          <div className="dynamicmodal-footer">
            <button
              className="cancel-button"
              type="button"
              onClick={onClose}
            >
              <BodyExtraSmallMedium color="#1E293B" text="Cancel" />
            </button>
            <button
              type="submit"
              className="primary-button"
              disabled={false}
            >
              <BodyExtraSmallMedium color="#FFFFFF" text="Save" />
            </button>
          </div>
        </form>
      </div>
    </div>
        </>
    )
}

export default AddSubTasks
