import {
  PRODUCT_SUCCESS,
  PRODUCT_FAIL,
  SET_MESSAGE,
  PRODUCT_PENDING,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT_PENDING,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_PENDING,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_SUCCESS,
  DELETE_FORM_PENDING,
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";

export const getProducts = (body) => async (dispatch) => {
  dispatch({
    type: PRODUCT_PENDING,
    payload: { products: "isLoading" },
  });
  return await UserService.getProducts(body)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: PRODUCT_SUCCESS,
              payload: { products: data.data },
            })
          : dispatch({
              type: PRODUCT_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: PRODUCT_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

//add product
export const addProducts = (body) => async (dispatch) => {
  dispatch({
    type: ADD_PRODUCT_PENDING,
    payload: { addProducts: "isLoading" },
  });
  return await UserService.addProduct(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Product added Successfully !", { autoClose: 2000 })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: ADD_PRODUCT_SUCCESS,
              payload: { addProducts: data.data.status },
            })
          : dispatch({
              type: ADD_PRODUCT_FAIL,
              payload: { addProducts: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_PRODUCT_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    }).finally(()=>{
      dispatch({
        type: ADD_PRODUCT_FAIL,
      });
    });;
};

//update product
export const updateProducts = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_PRODUCT_PENDING,
    payload: { updateProducts: "isLoading" },
  });
  return await UserService.updateProduct(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Product updated Successfully !", { autoClose: 2000 })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: UPDATE_PRODUCT_SUCCESS,
              payload: { updateProducts: data.data.status },
            })
          : dispatch({
              type: UPDATE_PRODUCT_FAIL,
              payload: { updateProducts: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_PRODUCT_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    }).finally(()=>{
      dispatch({
        type: UPDATE_PRODUCT_FAIL,
      });
    });
};

export const deleteProduct = (id, fileName) => async (dispatch) => {
  dispatch({
    type: DELETE_FORM_PENDING,
    payload: { deleteProduct: "isLoading" },
  });
  return await UserService.deleteProduct(id, fileName)
    .then(
    (data) => {
      if (data.data.status) {
        dispatch({
          type: DELETE_PRODUCT_SUCCESS,
          payload: { id, fileName },
        });
        toast.success(data.data.message, {autoClose: 2000 });
      } else {
        dispatch({
          type: DELETE_PRODUCT_FAIL,
        });
       console.error("Failed to delete form", {
         autoClose: 2000,
        });
      }
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: DELETE_PRODUCT_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};