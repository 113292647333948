import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getDoctors } from "../../actions/userActions/doctorAction";
import Loader from "../subComponents/Loader";
import { getCities } from "../../actions/userActions/cityAction";
import ServerSideSearch from '../subComponents/search/ServerSideSearch';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddButton from "../subComponents/buttons/AddButton";
import { IconButton,Tooltip } from "@mui/material";
import Pagination from '../paginatioin/pagination';
import EditButton from "../subComponents/buttons/EditButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DynamicTable from "../reuse_components/DynamicTable";
import CustomButton from "../subComponents/buttons/CustomButton";
import SearchInput from "../subComponents/search/SearchInput";
import BodySmallMedium from "../designSystem/BodySmallMedium";

import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import Heading6Regular from "../designSystem/Heading6Regular";
import CustomPagination from "../subComponents/pagination/CustomPagination";
function Doctors() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedCity, setSelectedCity] = useState(1);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [doctorsData, setdoctorData] = useState([]);
  const [cityFilters, setCityFilters] = useState({
    textSearch: "",
    countryId: "1",
  });
  const doctors = useSelector((state) => state.doctors_reducer.doctors);
  const allCities = useSelector((state) => state.cities_reducer.cities);
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [filters, setFilters] = useState({
    textSearch: '',
  })
  const itemsPerPage = 10;
  const totalPages = doctors?.totalPages || 0;
  const totalItems = doctors?.totalCount || 0;
  console.log(totalPages,"kkkkkkkkkkk");
  
  const [selected, setSelected] = useState({
    textSearch: '',
  })
  const cityBody = {
    filter: cityFilters,
    pageNumber: -1,
    pageSize: 0,
  };
  const doctorBody = {
    pageNumber: currentPage,
    pageSize: 10,
    textSearch: filters.textSearch,
    cityId: selectedCity,
    practiceLocationId: 0
  };

  useEffect(() => {
    dispatch(getCities(cityBody));
  }, []);

  useEffect(() => {
    selectedCity && dispatch(getDoctors(doctorBody));
  }, [selectedCity, currentPage, filters]);

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };
  const handleAddDoctorClick = () => {
    history.push('/admin/AddDoctors');
  };
  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 1) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }
  useEffect(() => {
    const doctorData = doctors && doctors.result?.[0]?.doctors;
  const DoctorData = doctorData?.map((doctors) => ({
    ...doctors,
    status: (
      <span
        style={{
          background: "#4361FF1A",
          width: "56px",
          height: "26px",
          padding: "3px 8px",
          display: "inline-block",
          textAlign: "center",
          lineHeight: "20px",
          borderRadius: "4px 0px 0px 0px",
          opacity: 1 ,
        }}
      >
        {doctors.active ? "Active" : "Inactive"}
      </span>
    ),
  }));
    setdoctorData(DoctorData);
  }, [doctors]);
 
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Specialization", accessor: "specializationName" },
    { label: "Designation", accessor: "rankName" },
     { label: "Status", accessor: "status" },  
  ];
  const handleEditDoctor = (row) => {
    history.push({
      pathname: '/admin/UpdateDoctors',
      doctors: { row },
      id: row.id,
    });
  }; 
  const actions = [  
    {
        label: 'Edit Doctor',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdateDoctor',
        handler:handleEditDoctor,
    },
];
  return (
    <>

<div>
      <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Doctors" color="#1E293B" />
                </div>
            </div>
            <Heading6Regular text="Doctors" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearch}
              value={selected.textSearch}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {doctors !== 'isLoading' && doctors.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                       <div style={{ display: "flex",marginRight:"10px" }}>
                       <FormControl>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCity}
                label="City"
                onChange={handleCityChange}
                MenuProps={{ PaperProps: { style: { maxHeight: 160 } } }}
                style={{ maxHeight: 40, borderRadius: "2px", width: "220px" }}
              >
                {allCities === "isLoading" && (
                  <MenuItem disabled>
                    Loading...
                  </MenuItem>
                )}

                {allCities !== "isLoading" && allCities?.length === 0 && (
                  <MenuItem disabled>
                    No data
                  </MenuItem>
                )}

                {allCities !== "isLoading" && allCities?.length !== 0 && allCities?.result?.[0].cities
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(city => (
                    <MenuItem key={city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
                       </div>
                  
                        {permissions && permissions.includes("CanAddDoctor") && (
                            <CustomButton text="Add Doctor" width="180px" onClick={handleAddDoctorClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ doctorsData}
                    isLoading={doctors === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
            </div>

        </div>
        
      </div>
  
    </>
  );
}

export default Doctors;
