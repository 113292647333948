import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getLocationType } from '../../actions/userActions/locationTypeAction';
import Loader from '../subComponents/Loader';
import Search from '../subComponents/search/Search';
import Pagination from '../paginatioin/pagination';
import AddButton from '../subComponents/buttons/AddButton';
import { IconButton, Tooltip } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SIZE_OF_PAGE } from '../../actions/types';
import DynamicTable from '../reuse_components/DynamicTable';

import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import BodySmallMedium from '../designSystem/BodySmallMedium';
import Heading6Regular from '../designSystem/Heading6Regular';
import SearchInput from '../subComponents/search/SearchInput';
import CustomPagination from '../subComponents/pagination/CustomPagination';
import CustomButton from '../subComponents/buttons/CustomButton';
import AddLocationTypes from './AddLocationTypes';
function LocationTypes() {
    const [isExpanded, setIsExpanded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState("");
    const [previousQuery, setPreviousQuery] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [locationTypeData, setlocationTypeData] = useState([]);
    const pageSize = SIZE_OF_PAGE;

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const pageNumber = currentPage;
        if (query !== previousQuery) {
            setCurrentPage(1);
        }
        dispatch(getLocationType(pageNumber, pageSize, query));
        setPreviousQuery(query);
    }, [currentPage, query, previousQuery, dispatch]);

    const locationTypesData = useSelector(
        (state) => state.location_type_reducer.locationTypes
    );
   const permissions = JSON.parse(localStorage.getItem('permissionsList')) || [];
  useEffect(() => {
    const practicelocationTypeData = locationTypesData?.result || [];
    setlocationTypeData(practicelocationTypeData);
  }, [locationTypesData]);
  
  const totalPages = locationTypesData?.totalPages || 0;
  const totalItems = locationTypesData?.totalCount || 0;
    const columns = [
      { label: "Name", accessor: "name" },
      { label: "Description", accessor: "description" },
     
    ];
     const handleEditLocationType = (row) => {
        setEditData(row);
        setShowModal(true);
      };
    const actions = [  
      {
          label: 'Edit Location Type',
          icon: <EditOutlinedIcon />,
          permission: 'CanUpdatePracticeLocation',
          handler:handleEditLocationType,
      },
  ];
  const itemsPerPage =10;
  const handleSearchChange = (e) => {
    setQuery(e.target.value);
    setCurrentPage(1);
  };
  
const handleAddPracticeLocationTypeClick = () => {
    setEditData(null);
    setShowModal(true);
  };
  
  
  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const handleSaveData = () => {
    dispatch(getLocationType(currentPage, itemsPerPage, query));
    setShowModal(false);
  };
  
    return (
        <>
        <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Location type" color="#1E293B" />
                </div>
            </div>
            <Heading6Regular text="Location type" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearchChange}
              value={query}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {locationTypesData !== 'isLoading' && locationTypesData.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                   
                        {permissions && permissions.includes("CanAddPracticeLocation") && (
                            <CustomButton text="Add PracticeLocation Type" width="180px" onClick={handleAddPracticeLocationTypeClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ locationTypeData}
                    isLoading={locationTypesData === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {locationTypesData !== 'isLoading' && locationTypesData.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
            </div>
 
            {showModal && (
        <AddLocationTypes
          onClose={handleCloseModal}
          editData={editData}
          onSave={handleSaveData}
        />
      )}
        </div>
  
        </>
    );
}

export default LocationTypes;
