import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDesignationLevel,
  updateDesignationLevel,
  getDesignationsLevel,
} from "../../actions/userActions/designationLevelAction";
import designationLevelIcon from "../../assets/images/designationLevelIcon.svg";
import closeModal from "../../assets/images/closeModal.svg";
import "./DynamicModal.css";
import BodyExtraSmallMedium from "../designSystem/BodyExtraSmallMedium";
import BodyLargeMedium from "../designSystem/BodyLargeMedium";
import BodySmallRegular from "../designSystem/BodySmallRegular";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import Textarea from "../designSystem/TextArea";
import TextField from "../designSystem/TextField";
import { IconButton } from "@mui/material";

export const AddDesignationLevel = ({ onClose, editData, onSave }) => {
  const dispatch = useDispatch();
 const initialValues = {
    name: "",
    description: "",
    active: false,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
 const designationLevels = useSelector(
    (state) => state.designationLevels_reducer.designationLevels
  );
  
// useEffect(() => {
//     if (!editData) {
//       const currentPage = 1; 
//       const itemsPerPage = 10;
//       const searchQuery = "";
//       dispatch(getDesignationsLevel(currentPage, itemsPerPage, searchQuery));
//     }
//   }, [dispatch, editData]);
  useEffect(() => {
    if (editData) {
      setFormValues(editData);
    }
  }, [editData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const validate = (values) => {
    const errors = {};
    const nameRegex = /^[a-zA-Z0-9-_]*$/;
    if (!values.name) {
      errors.name = "Name is required!";
    } else if (!nameRegex.test(values.name)) {
      errors.name = "Special Characters are not allowed.";
    } 
    if (!values.description) {
      errors.description = "Description is required!";
    }
    return errors;
  }; 
  const handletextChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSave = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (editData) {
        dispatch(updateDesignationLevel(formValues));
      } else {
        dispatch(addDesignationLevel(formValues));
      }
      onSave(formValues);
    }
  };

  return (
    <div className="dynamicmodal-overlay">
      <div className="dynamicmodal-container">
        <div className="dynamicmodal-header mb-3">
          <div className="dynamicmodal-header-left">
            <div className="icon">
              <img src={designationLevelIcon} alt="designation Level" />
            </div>
            <div className="text">
              <BodyLargeMedium
                text={editData ? "Edit Designation Level" : "Add Designation Level"}
                color="#384153"
              />
              <p>This will define the level of a designation in hierarchy.</p>
            </div>
          </div>
          <div className="dynamicmodal-header-right">
          <IconButton onClick={onClose}>
            <img src={closeModal} alt="" />
            </IconButton>
              </div>
         
        </div>
       

        <form onSubmit={handleSave}>
          <div className="dynamicmodal-body">
          <div className="mb-2">
          <BodySmallMedium
            text={`Level Order: ${editData ? editData.id : designationLevels !== "isLoading" && designationLevels.totalCount + 1}`}
            color="#1E293B"
          />
          <BodySmallRegular
            text={"Lower numbers indicate higher levels in the hierarchy."}
            color="#1E293B"
          />

        </div>
        <TextField
         name="name"
                    label="Name"
                      type='text'
                    value={formValues.name}
                    onChange={handletextChange}
                    placeholder='Enter name'
                    error={formErrors.name}
                />
         
              <Textarea
               name="description"
        label="Description"
        placeholder="Enter your description here"
        value={formValues.description}
        onChange={handletextChange}
        error={formErrors.description}
      />

            <div className=" mt-5 ml-0">
              <div
                className="form-group"
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <input
                  name="active"
                  style={{ cursor: "pointer", height: "14px", width: "14px" }}
                  type="checkbox"
                  checked={formValues.active}
                  className="ml-1 mt-1"
                  onChange={handleChange}
                />
                <label
                  style={{ paddingLeft: "10px" }}
                  className="form-control-label"
                >
                  <BodyExtraSmallMedium text="Active ?" color="#1E293B" />
                </label>
              </div>
            </div>
          </div>

          <div className="dynamicmodal-footer">
            <button
              className="cancel-button"
              type="button"
              onClick={onClose}
            >
              <BodyExtraSmallMedium color="#1E293B" text="Cancel" />
            </button>
            <button
              type="submit"
              className="primary-button"
              disabled={false}
            >
              <BodyExtraSmallMedium color="#FFFFFF" text="Save" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDesignationLevel;
