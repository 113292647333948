import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addTeams, updateTeams } from '../../actions/userActions/teamAction'
import Loader from '../subComponents/Loader'
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import HrComponent from '../reuse_components/HrComponent';
import BodyLargeMedium from '../designSystem/BodyLargeMedium';
import { IconButton } from '@mui/material';
import Textarea from '../designSystem/TextArea';
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium';
import TextField from '../designSystem/TextField';
import designationLevelIcon from "../../assets/images/designationLevelIcon.svg";
import closeModal from "../../assets/images/closeModal.svg";

import teamIcon from "../../assets/images/Icon=Team.svg";

function AddTeams({ onClose, editData, onSave }) {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const initialValues = { name: "", description: "", active: false };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  useEffect(() => {
    if (editData) {
      setFormValues(editData);
    }
  }, [editData]);
 const handleSave = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (editData) {
        dispatch(updateTeams(formValues));
      } else {
        dispatch(addTeams(formValues));
      }
      onSave(formValues);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (name === "active" && formValues.active === true) {
      setFormValues({ ...formValues, [name]: false });
    }
    else if (name === "active" && formValues.active === false) {
      setFormValues({ ...formValues, [name]: true });
    }
  }

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required!"
    }
    if (!values.description) {
      errors.description = "Description is required!"
    }
    return errors;
  }
  const addTeam = useSelector(state => state.teams_reducer.addTeams);
  const updateTeam = useSelector(state => state.teams_reducer.updateTeams);
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
         <div className="dynamicmodal-overlay">
      <div className="dynamicmodal-container">
        <div className="dynamicmodal-header mb-3">
          <div className="dynamicmodal-header-left">
            <div className="icon">
              <img src={teamIcon} alt="teamIcon " />
            </div>
            <div className="text">
              <BodyLargeMedium
                text={editData ? "Edit team" : "Add Teaam"}
                color="#384153"
              />
              <p>Create teams to organize users and streamline collaboration.</p>
            </div>
          </div>
          <div className="dynamicmodal-header-right">
          <IconButton onClick={onClose}>
            <img src={closeModal} alt="" />
            </IconButton>
              </div>
         
        </div>
       

        <form onSubmit={handleSave}>
          <div className="dynamicmodal-body">
       
        <TextField
         name="name"
                    label="Name"
                      type='text'
                    value={formValues.name}
                    onChange={handleChange}
                    placeholder='Enter name'
                    error={formErrors.name}
                />
         
              <Textarea
               name="description"
        label="Description"
        placeholder="Enter your description here"
        value={formValues.description}
        onChange={handleChange}
        error={formErrors.description}
      />

            <div className=" mt-5 ml-0">
              <div
                className="form-group"
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <input
                  name="active"
                  style={{ cursor: "pointer", height: "14px", width: "14px" }}
                  type="checkbox"
                  checked={formValues.active}
                  className="ml-1 mt-1"
                  onChange={handleChange}
                />
                <label
                  style={{ paddingLeft: "10px" }}
                  className="form-control-label"
                >
                  <BodyExtraSmallMedium text="Active ?" color="#1E293B" />
                </label>
              </div>
            </div>
          </div>

          <div className="dynamicmodal-footer">
            <button
              className="cancel-button"
              type="button"
              onClick={onClose}
            >
              <BodyExtraSmallMedium color="#1E293B" text="Cancel" />
            </button>
            <button
              type="submit"
              className="primary-button"
              disabled={false}
            >
              <BodyExtraSmallMedium  color="#FFFFFF" text="Save" />
            </button>
          </div>
        </form>
      </div>
    </div>

    </>
  );
}

export default AddTeams
