import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  addDoctors,
  updateDoctors,
} from '../../actions/userActions/doctorAction'
import Loader from '../subComponents/Loader'
import { getCities } from '../../actions/userActions/cityAction'
import { getDoctorRanks } from '../../actions/userActions/doctorRankAction'
import { getDoctorSpecializations } from '../../actions/userActions/doctorSpecializationAction'
import { Redirect } from 'react-router-dom'
import { useLocation, useHistory } from 'react-router-dom'
import { getCustomerRank } from '../../actions/userActions/customerRankAction'
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "../designSystem/TextField";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import Select from "@mui/material/Select";
import SaveButton from '../subComponents/buttons/SaveButton'
import { Autocomplete, CircularProgress, IconButton } from '@mui/material'
import HrComponent from '../reuse_components/HrComponent'
import { getPracticeLocations } from '../../actions/userActions/practiceLocationAction'
import SingleSelect from '../designSystem/SingleSelect'
import PrimaryButton from '../subComponents/buttons/PrimaryButton'
import BodySmallMedium from '../designSystem/BodySmallMedium'
import Heading6Regular from '../designSystem/Heading6Regular'
import deleteIcon from "../../assets/images/deleteIcon_drawer.svg";
import CancelButton from '../subComponents/buttons/CustomCancelButton'
function AddDoctors() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const initialValues = {
    name: '',
    pmdcnumber: '',
    specialization: '',
    rank: '',
    customerRankId: '',
    contact1: '',
    contact2: '',
    city: null,
    email: '',
    active: false,
    practiceLocationIds: [],
  }
  const [selectedPracticeLocations, setSelectedPracticeLocations] = useState([]); 
  const [formValues, setFormValues] = useState(initialValues)

  const [page, setPage] = useState(1)

  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })
  const cityBody = {
    filter: cityFilters,
    pageNumber: -1,
    pageSize: 0,
  };
  const allCities = useSelector((state) => state.cities_reducer.cities);
  const ranks = useSelector((state) => state.doctor_rank_reducer.ranks);
  const ranksData = ranks && ranks.result;

  const specializations = useSelector(
    (state) => state.doctor_specialization_reducer.specializations
  );
  const specializationsData = specializations && specializations.result;
  const addDoctor = useSelector((state) => state.doctors_reducer.addDoctors);
  const updateDoctor = useSelector((state) => state.doctors_reducer.updateDoctors);
  const permissions = JSON.parse(localStorage.getItem('permissionsList'));
  const customerRanks = useSelector((state) => state.customer_rank_reducer.customerRank);
  const practiceLocations = useSelector((state) => state.practiceLocations_reducer?.practiceLocations);
  
  const pageNumber = -1;
  const [loading, setLoading] = useState(true); // Loading state

  const [formErrors, setFormErrors] = useState({})
  const handleselectChange = (event, fieldName) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: event.target.value,
    }));
  };
  
  const getOptions = (type) => {
    switch (type) {
      case "cities":
        return allCities?.result?.[0]?.cities?.length
          ? allCities.result[0].cities
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((city) => ({
              value: city.id,
              label: city.name,
            }))
          : [];
      case "customerRank":
        return customerRanks !== "isLoading" && customerRanks?.result?.length
          ? customerRanks.result.map((customerRank) => ({
              value: customerRank.customerRankId,
              label: customerRank.rankDescription,
            }))
          : [];
      case "specialization":
        return specializationsData?.length
          ? specializationsData.map((specialization) => ({
              value: specialization.id,
              label: specialization.name,
            }))
          : [];
      case "designation":
        return ranksData?.length
          ? ranksData
              .filter((rank) => rank.active)
              .map((rank) => ({
                value: rank.id,
                label: rank.name,
              }))
          : [];
      default:
        return [];
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getCities(cityBody));
        dispatch(getDoctorRanks(pageNumber));
        dispatch(getDoctorSpecializations(pageNumber));
        dispatch(getCustomerRank(-1));

        if (location.doctors) {
          
          setFormValues({
            ...formValues,
            name: location.doctors.row.name,
            pmdcnumber: location.doctors.row.pmdcnumber,
            specialization: location.doctors.row.specialization,
            rank: location.doctors.row.rank,
            customerRankId: location.doctors.row.customerRankId,
            contact1: location.doctors.row.contact1,
            contact2: location.doctors.row.contact2,
            city: location.doctors.row.city,
            id: location.id,
            email: location.doctors.row.email?.trim(),
            active: location.doctors.row.active,
           
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
   fetchData();
  }, [location.id]);

useEffect(() => {
  if (
    location?.doctors?.row?.practiceLocationIds &&
    practiceLocations?.result?.[0]?.practiceLocations &&
    Array.isArray( practiceLocations?.result?.[0]?.practiceLocations)
  ) {
    const locationIds = location.doctors.row.practiceLocationIds;
    const matchedData =  practiceLocations?.result?.[0]?.practiceLocations.filter(item =>
      locationIds.includes(item.id)
    );
    if (matchedData.length > 0) {
      setSelectedPracticeLocations(matchedData);
    } 
  }
}, [location.id, practiceLocations]);
useEffect(() => {
    const practiceLocationBody = {
      filter: {
        textSearch: "",
        city: formValues.city
      },
      pageNumber: -1,
      pageSize: 0,
    };
    if (formValues.city) {
      dispatch(getPracticeLocations(practiceLocationBody));
    }
  }, [formValues.city])

  const handlePracticeLocationChange = (event, newValue) => {
   
    setSelectedPracticeLocations(newValue);
    setFormValues((prevValues) => ({
      ...prevValues,
      practiceLocationIds: newValue.map(location => location.id), 
    }));
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (location.doctors) {
        dispatch(updateDoctors(formValues));
      } else {
        dispatch(addDoctors(formValues));
      }
    }
  }

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "practiceLocationIds") {

  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       [name]: Array.isArray(value) ? value : [value],
  //     }));
  //   } else if (name === "active") {
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       [name]: !prevValues.active,
  //     }));
  //   } else if (name === "contact1" || name === "contact2") {
  //     const formattedValue = value.replace(/[^0-9]/g, "").slice(0, 13);
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       [name]: formattedValue,
  //     }));
  //   } else {
  //     const trimmedValue = name === 'email' ? value.trim() : value;
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       [name]: trimmedValue,
  //     }));
  //   }
  // };
  const validate = (values) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const errors = {}
    if (!values.name) {
      errors.name = 'Name is required!'
    }
    if (values.specialization === '') {
      errors.specialization = 'Specialization is required!'
    }
    if (values.customerRankId === '') {
      errors.customerRankId = 'Customer rank is required!'
    }
    if (values.rank === '') {
      errors.rank = 'Rank is required!'
    }
    if (values.email && values.email.length > 0 && !emailRegex.test(values.email.trim())) {
      errors.email = 'Invalid email!';
    }

    if (values.city === '') {
      errors.city = 'City is required!'
    }
    // if (values?.practiceLocationIds?.length == 0) {
    //   errors.practiceLocationIds = 'Practice Locations are required!';
    // }
    return errors
  }

  const handleGoBack = () => {
    history.goBack();
  };
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
        <CircularProgress size={60} style={{ color: '#002D72' }} />
      </div>
    );
  }
  const handlechange = (event) => {
    const { name, type, checked, value } = event.target;
  
    const updatedValue = type === "checkbox" ? checked : value;
  
    setFormValues({
      ...formValues,
      [name]: updatedValue,
    });
  };
  
  const handletextChange = (event) => {
    const { name, value } = event.target;
  
    let updatedValue = value;
  
    if (name === "contact1" || name === "contact2") {
      updatedValue = value.replace(/[^0-9]/g, "").slice(0, 13); // Allow only numeric values, max length 13
    } else if (name === "email") {
      updatedValue = value.trim(); // Trim spaces for email
    }
  
    setFormValues({
      ...formValues,
      [name]: updatedValue,
    });
  };
  
  return (
    <>
      {addDoctor && addDoctor !== "isLoading" && (
        <Redirect to="/admin/doctors" />
      )}
      {updateDoctor && updateDoctor != "isLoading" && <Redirect to="/admin/doctors" />}
      {!permissions.includes('CanAddDoctor') && <Redirect to="/admin" />}
      {!permissions.includes('CanUpdateDoctor') && <Redirect to="/admin" />}
      {!location.doctors && <Redirect to={'/admin/AddDoctors'} />}
      <div className="add-visit-profile-container">
        <div className="content-header">
          <div className="header-navigation">
            <BodySmallMedium text="Manage" color="#A1A2A4" />
            <img
              src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
            <BodySmallMedium text="Doctor" color="#1E293B" />
           
          </div>

        </div>
        <Heading6Regular text="Doctor" color="#1E293B" />
        <div className="add-content-wrapper">
          <div className="row">
            <div className="col-6">
              <TextField

                name="name"
                label="Doctor Name"
                type='text'
                value={formValues.name}
                onChange={handletextChange}
                placeholder='Enter Doctor Name'
                error={formErrors.name}
              />
            </div>

            <div className="col-6">
            <SingleSelect
                label="City"
                isSearchable
                iconEnd
                onChange={(event) => handleselectChange(event, 'cities')}
                value={formValues.city}
                placeholder='Select city'
                options={(allCities &&
                  allCities !== "isLoading" &&
                  allCities.length !== 0 && allCities.result?.[0]?.cities &&
                  allCities.result?.[0]?.cities.length !== 0)
                  && getOptions("cities")}
              />
              
            </div>
          </div>
          <div className="row">
            <div className="col-6">
            <SingleSelect
    label=" Designation *"
    value={formValues.rank}
    onChange={(option) => handleselectChange(option, "rank")}
    options={getOptions("designation")}
    placeholder="Select Designation"
    isSearchable
  />
            </div>

            <div className="col-6">
            <SingleSelect
    label="Specialization *"
    value={formValues.specialization}
    onChange={(option) => handleselectChange(option, "specialization")}
    options={getOptions("specialization")}
    placeholder="Select Specialization"
    isSearchable
  />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
            <SingleSelect
    label="Customer Rank *"
    value={ formValues.customerRankId}
    onChange={(option) => handleselectChange(option, "customerRank")}
    options={getOptions("customerRank")}
    placeholder="Select Customer Rank"
    isSearchable
  />
            </div>

            <div className="col-6">
              <TextField
                name="email"
                label="Email *"
                type='text'
                value={formValues.email}
                onChange={handletextChange}
                placeholder='Enter Email'
               
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TextField
                name="contact1"
                label="Contact 1"
                type='text'
                value={formValues.contact1}
                onChange={handletextChange}
                placeholder='Enter personal contact1 '
               
              />

            </div>

            <div className="col-6">
            <TextField
                name="contact2"
                label="contact 2"
                type='text'
                value={formValues.contact2}
                onChange={handletextChange}
                placeholder='Enter personal contact2 '
                
              />
            </div>
         
          </div>
          {/* <div  className="p-3 mb-3 maindiv">
                <div className="add-visit-profile-rounded-fields-container">
                    <div className="row align-items-center">
                        <div className="col-6">
                        <SingleSelect
                label="City"
                isSearchable
                iconEnd
                onChange={(event) => handleselectChange(event, 'cities')}
                value={formValues.city}
                placeholder='Select city'
                options={(allCities &&
                  allCities !== "isLoading" &&
                  allCities.length !== 0 && allCities.result?.[0]?.cities &&
                  allCities.result?.[0]?.cities.length !== 0)
                  && getOptions("cities")}
              />
                        </div>
                        <div className="col-6">
                      
        </div>
                    </div>
                </div>

                <div className="removeIcon">
                    
                        <IconButton>
                            <img src={deleteIcon} alt="Delete" style={{ width: '24px', height: '24px' }} />
                        </IconButton>
                    
                </div>
            </div> */}
        
          <div className="form-group mt-5" style={{ display: "flex", alignItems: "flex-start" }}>
            <input
              name="active"
              style={{ cursor: "pointer", height: "16px", width: "16px" }}
              type="checkbox"
              checked={formValues.active}
              className="ml-2 mt-1"
              onChange={handlechange}
            />
            <label
              style={{ paddingLeft: "10px", }}
              className="form-control-label"
            >
              Is this doctor active?
            </label>
          </div>
          <HrComponent />

       
          <div className="add-visit-profile-form-actions">
            <CancelButton onClick={handleGoBack} text="Cancel" />
            <PrimaryButton
              onClick={handleSubmit}
              isLoading={addDoctor === "isLoading" || updateDoctor === "isLoading"}
              text={location.doctors ? "Update" : "Save"}
              // icon={customIcon}
              width="100px"
              height="50px"
            />

          </div>
        </div>
      </div>

    </>
  )
}

export default AddDoctors
