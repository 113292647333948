import React, { useState } from 'react';
import "./TextField.css";
import BodySmallRegular from './BodySmallRegular';
import { Eye } from "lucide-react";
import { EyeOff } from "lucide-react";
import { CircleAlert } from "lucide-react";
const TextField = ({
    error,
    iconPasswordStart,
    value,
    onChange,
    disabled = false,
    placeholder = '',
    label,
    type = "text",
    iconStart,
    iconEnd,
    name,
    style = {},  // New prop for inline styles
    className = "" // New prop for class names
  }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  
    const handleTogglePassword = () => {
      setIsPasswordVisible((prev) => !prev);
    };
  
    const inputType = type === "password" && isPasswordVisible ? "text" : type;
  
    return (
      <div className={`text-field-container ${className}`} style={style}>
        {label && <BodySmallRegular color="#1E293B" text={label} />}
        <div className="text-field-main">
          <div className={`input-field-container ${disabled ? 'input-field-container-disabled' : ''}`}>
            {iconStart && iconStart}
            {iconPasswordStart && type === "password" && (
              <button
                type="button"
                className="toggle-password-button"
                onClick={handleTogglePassword}
                disabled={disabled}
              >
                {isPasswordVisible ? <Eye color="#4B5563" height={20} /> : <EyeOff color="#4B5563" height={20} />}
              </button>
            )}
            <input
              className="input-field"
              type={inputType}
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              name={name}
            />
            {!iconPasswordStart && type === "password" && (
              <button
                type="button"
                className="toggle-password-button"
                onClick={handleTogglePassword}
                disabled={disabled}
              >
                {isPasswordVisible ? <Eye color="#4B5563" height={20} /> : <EyeOff color="#4B5563" height={20} />}
              </button>
            )}
            {iconEnd && iconEnd}
          </div>
          {error && (
            <div className="error-text-container">
              <CircleAlert height={16} />
              <span className="error-text">{error}</span>
            </div>
          )}
        </div>
      </div>
    );
  };
  
  export default TextField;
  