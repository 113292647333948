import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getDesignationsLevel } from "../../actions/userActions/designationLevelAction";
import DynamicTable from "../reuse_components/DynamicTable";
import SearchInput from "../subComponents/search/SearchInput";
import CustomButton from "../subComponents/buttons/CustomButton";
import CustomPagination from "../subComponents/pagination/CustomPagination";
import AddDesignationLevel from "./AddDesignationLevel";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import Heading6Regular from "../designSystem/Heading6Regular";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import thIcon from "../../assets/images/thIcon.svg";
function DesignationLevel() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [DesignationLevelData, setDesignationLevelData] = useState([]);

  const itemsPerPage = 10;

  const designationLevels = useSelector(
    (state) => state.designationLevels_reducer.designationLevels
  );

  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  useEffect(() => {
    dispatch(getDesignationsLevel(currentPage, itemsPerPage, searchQuery));
  }, [dispatch, currentPage, searchQuery]);

  useEffect(() => {
    const designationLevelData =
      designationLevels?.result?.[0]?.designationLevels?.map((level) => ({
        ...level,
        status: level.active === true || level.active === 1 ? "Active" : "Inactive",
      })) || [];
    setDesignationLevelData(designationLevelData);
  }, [designationLevels]);

  const totalPages = designationLevels?.totalPages || 0;
  const totalItems = designationLevels?.totalCount || 0;

  const columns = [
    { label: "Level Order", accessor: "id" },
    { label: "Name", accessor: "name" },
    { label: "Description", accessor: "description" },
    { label: "Status", accessor: "status" },
  ];

  const handleAddClick = () => {
    setEditData(null);
    setShowModal(true);
  };

  const handleEditDesignationLevel = (row) => {
    setEditData(row);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSaveData = async (newData) => {
    dispatch(getDesignationsLevel(currentPage, itemsPerPage, searchQuery));
    setShowModal(false);
  };
 
 const actions = [
    {
      label: "Edit Designation",
      icon: <EditOutlinedIcon />,
      permission: "CanUpdateDesignationLevel",
      handler: handleEditDesignationLevel,
    },
  ];

  return (
    <div>
      <div className="main-container">
        <div className="content-header">
          <div className="header-navigation">
            <BodySmallMedium text="Manage" color="#A1A2A4" />
            <img
              src={chevron_rightIcon}
              alt="Chevron Right"
              className="chevron-right-icon"
            />
            <BodySmallMedium text="Designation Level" color="#1E293B" />
          </div>
        </div>
        <Heading6Regular text="Designation Level" color="#1E293B" />
        <div className="content-wrapper">
          <div className="pagination">
            <SearchInput
              placeholder="Search"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomPagination
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
              {permissions?.includes("CanAddDesignationLevel") && (
                <CustomButton
                  text="Add Designation Level"
                  width="180px"
                  onClick={handleAddClick}
                />
              )}
            </div>
          </div>
          <DynamicTable
            data={DesignationLevelData}
            isLoading={designationLevels === "isLoading"}
            columns={columns}
            permissions={permissions}
            actions={actions}
            thIcon={thIcon}
          />
               <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {designationLevels !== 'isLoading' && designationLevels.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
        </div>
      </div>

      {showModal && (
        <AddDesignationLevel
          onClose={handleCloseModal}
          editData={editData}
          onSave={handleSaveData}
        />
      )}
    </div>
  );
}

export default DesignationLevel;
