import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDoctorRanks } from "../../actions/userActions/doctorRankAction";
import { Link, useHistory } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SIZE_OF_PAGE } from "../../actions/types";
import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import Heading6Regular from "../designSystem/Heading6Regular";
import SearchInput from "../subComponents/search/SearchInput";
import DynamicTable from "../reuse_components/DynamicTable";
import CustomButton from "../subComponents/buttons/CustomButton";
import CustomPagination from "../subComponents/pagination/CustomPagination";
import AddDoctorRanks from "./AddDoctorRanks";

function DoctorRanks() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1)
  const [previousQuery, setPreviousQuery] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [doctorRankdata, setdoctorRankdata] = useState([]);
  useEffect(() => {
    dispatch(getDoctorRanks());
  }, []);
  const ranks = useSelector((state) => state.doctor_rank_reducer.ranks);

 const itemsPerPage = 10;
  const totalPages = ranks?.totalPages || 0;
  const totalItems = ranks?.totalCount || 0;
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  useEffect(() => {
    const pageNumber = currentPage;
  
      if (searchQuery !== previousQuery) {
        setCurrentPage(1);
      }
      dispatch(getDoctorRanks(pageNumber, SIZE_OF_PAGE, searchQuery));
      setPreviousQuery(searchQuery);
  
    }, [currentPage, searchQuery]);
 
  useEffect(() => {
    const RanksData = ranks && ranks.result?.map((ranks) => ({
      ...ranks,
      status: (
        <span
          style={{
            background: "#4361FF1A",
            width: "56px",
            height: "26px",
            padding: "3px 8px",
            display: "inline-block",
            textAlign: "center",
            lineHeight: "20px",
            borderRadius: "4px 0px 0px 0px",
            opacity: 1 ,
          }}
        >
          {ranks.active ? "Active" : "Inactive"}
        </span>
      ),
    }));
    setdoctorRankdata(RanksData);
  }, [ranks]);
  const handleAddDoctorDesignationClick = () => {
    setEditData(null);
    setShowModal(true);
  };

  const handleEditDoctorDesignation = (row) => {
    setEditData(row);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSaveData = async (newData) => {
    dispatch(getDoctorRanks(currentPage, itemsPerPage, searchQuery));
    setShowModal(false);
  };
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Description", accessor: "description" },
     { label: "Status", accessor: "status" },  
  ];
  const actions = [  
    {
        label: 'Edit Doctor Designation',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdateDoctorRank',
        handler:handleEditDoctorDesignation,
    },
];

const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
  setCurrentPage(1);
};
  return (
    <>
      <div>
      <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Doctor Designation" color="#1E293B" />
                </div>
            </div>
            <Heading6Regular text="Doctor Designation" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                <SearchInput
                        placeholder="Search"
                        onChange={handleSearchChange}
              value={searchQuery}
             />
                    <div style={{ display: "flex", alignItems: "center" }}>
                       {ranks !== 'isLoading' && ranks.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                         {permissions && permissions.includes("CanAddDoctorRank") && (
                            <CustomButton text="Add Doctor Designation" width="180px" onClick={handleAddDoctorDesignationClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ doctorRankdata}
                    isLoading={ranks === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {ranks !== 'isLoading' && ranks.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
            </div>

        </div>
        
        {showModal && (
        <AddDoctorRanks
          onClose={handleCloseModal}
          editData={editData}
          onSave={handleSaveData}
        />
      )}
      </div>
     
    </>
  );
}

export default DoctorRanks;
