import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import BodySmallRegular from "../designSystem/BodySmallRegular";
import Loader from "../subComponents/Loader";
const DynamicTable = ({
  data,
  isLoading,
  columns,
  permissions,
  actions,
  // thIcon
}) => {
  return (
    <div className="dynamic-table-container">
      <table>
        <thead>
          <tr>
            {columns?.map((col, index) => (
              <th key={index}>
                <div className="header-with-icon">
                  <BodySmallMedium text={col.label} color="#1E293B" />
                  {/* <img
                    src={thIcon}
                    alt={`${col.label} Icon`}
                    style={{ width: "16px", height: "16px", marginRight: "8px" }}
                  /> */}
                </div>
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={columns?.length + 1} style={{ textAlign: "center" }}>
                <Loader colored={true} />
              </td>
            </tr>
          ) : data?.length === 0 ? (
            <tr>
              <td colSpan={columns?.length + 1} style={{ textAlign: "center" }}>
                No data found
              </td>
            </tr>
          ) : (
            data?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns?.map((col, colIndex) => (
                  <td key={colIndex}>
                    <BodySmallRegular text={row[col.accessor]} color="#1E293B" />
                  </td>
                ))}
                  <td>
                  {actions?.map((action, actionIndex) => {
                    if (action.type === "handle") {
                      return (
                        <Tooltip key={actionIndex} title={action.label} arrow>
                          <IconButton onClick={() => action.linkTo(row)}>
                            {action.icon}
                          </IconButton>
                        </Tooltip>
                      );
                    }
                    if (permissions && permissions.includes(action.permission)) {
                      return (
                        <Tooltip key={actionIndex} title={action.label} arrow>
                          <IconButton onClick={() => action.handler(row)}>
                            {action.icon}
                          </IconButton>
                        </Tooltip>
                      );
                    } else {
                       return null;
                    }
                  })}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};


export default DynamicTable;
