import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getCountries } from '../../actions/userActions/countryAction'
import { addCities, updateCities } from '../../actions/userActions/cityAction'
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { CircularProgress, IconButton } from '@mui/material'
import HrComponent from '../reuse_components/HrComponent'
import SingleSelect from '../designSystem/SingleSelect'
import BodyLargeMedium from '../designSystem/BodyLargeMedium'
import BodySmallMedium from '../designSystem/BodySmallMedium'
import BodySmallRegular from '../designSystem/BodySmallRegular'
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium'
import cityicon from "../../assets/images/cityIcon (2).svg";
import closeModal from "../../assets/images/closeModal.svg";
import "../DesignationLevel/DynamicModal.css";
import TextField from '../designSystem/TextField'
function AddCities({ onClose, editData,onSave }) {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = { name: "", shortName: "", countryId: "" };
    const [loading, setLoading] = useState(true); 
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const countries = useSelector(state => state.countries_reducer.countries);
    useEffect(() => {
        const fetchData = async () => {
            try {
              await dispatch(getCountries());
              if (editData.city) {
                setFormValues({
                    ...formValues,
                    name: editData.name,
                    shortName: editData.shortName,
                    countryId: editData.countryId,
                    id: editData.id
                });
            }
            } catch (error) {
              console.error("Error fetching data:", error);
            } finally {
              setLoading(false); 
            }
          };
      
          fetchData();
    }, []);
    useEffect(() => {
        if (editData) {
          setFormValues(editData);
        }
      }, [editData]);
  const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Name is required!"
        }
        if (!values.shortName) {
            errors.shortName = "Short Name is required!"
        }
        if (values.countryId === '') {
            errors.countryId = "Country is required!"
        }
        return errors;
    }
    const handleSave = (e) => {
      e.preventDefault();
      const errors = validate(formValues);
      setFormErrors(errors);
  
      if (Object.keys(errors).length === 0) {
        if (editData) {
          dispatch(updateCities(formValues));
        } else {
          dispatch(addCities(formValues));
        }
        onSave(formValues);
      }
    };
    const handleCountryChange = (event) => {
        setFormValues({
            ...formValues,
            countryId: event.value
        })
    }
   
    console.log(countries,'countries');
    console.log(formValues,'formValues');
    const getOptions = () => {
        
        return (
            countries &&
            countries !== "isLoading" &&
            countries.length !== 0 &&
            countries.result && countries.result.length !== 0 && countries.result
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((countries) => ({
                value: countries.id,
                label: countries.name
      
              }))
          ) || [];
    };
   
    if (loading) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
            <CircularProgress size={60} style={{ color: '#002D72' }} />
          </div>
        );
      }
      const handleNameChange = (event) => {
        setFormValues({
            ...formValues,
            name: event.target.value 
        });
    };
    const handleShortnameChange = (event) => {
      setFormValues({
          ...formValues,
          shortName: event.target.value 
      });
    };
    return (
        <>
         
            <div className="dynamicmodal-overlay">
      <div className="dynamicmodal-container">
        <div className="dynamicmodal-header mb-3">
          <div className="dynamicmodal-header-left">
            <div className="icon">
              <img src={cityicon} alt="city" />
            </div>
            <div className="text">
              <BodyLargeMedium
                text={editData ? "Edit City" : "Add City"}
                color="#384153"
              />
              <p>Enter a City below.</p>
            </div>
          </div>
          <div className="dynamicmodal-header-right">
          <IconButton onClick={onClose}>
            <img src={closeModal} alt="" />
            </IconButton>
          </div>
        </div>
       

        <form onSubmit={handleSave}>
          <div className="dynamicmodal-body">
        
            <div className="dynamicmodal-form-group">
            <TextField
                    label="Name"
                      type='text'
                    value={formValues.name}
                    onChange={handleNameChange}
                    placeholder='Enter name'
                    error={formErrors.name}
                />
            </div>
            <div className="dynamicmodal-form-group">
             
            <TextField
                    label="Name"
                      type='text'
                    value={formValues.shortName}
                    onChange={handleShortnameChange}
                    placeholder='Enter name'
                    error={formErrors.name}
                />
            </div>
            <SingleSelect
                    label="Employee"
                    isSearchable
                    // type="radio"
                    secondaryLabel
                    // iconStart={<Search width={16} height={16} />}
                    iconEnd
                   onChange={handleCountryChange}
                    value={formValues.countryId}
                    placeholder='Select country'
                    options={(countries &&
                        countries !== "isLoading" &&
                        countries.length !== 0 && countries.result &&
                        countries.result.length !== 0)
                        && getOptions()}
                />
        
          </div>

          <div className="dynamicmodal-footer">
            <button
              className="cancel-button"
              type="button"
              onClick={onClose}
            >
              <BodyExtraSmallMedium color="#1E293B" text="Cancel" />
            </button>
            <button
              type="submit"
              className="primary-button"
              disabled={false}
            >
              <BodyExtraSmallMedium color="#FFFFFF" text="Save" />
            </button>
          </div>
        </form>
      </div>
    </div>
   
        </>
    )
}

export default AddCities