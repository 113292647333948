import axios from "axios";
import AuthService from "./auth/auth.service";
let isRefreshing = false;
let failedQueue = [];
const processQueue = (error, token = null) => {
  failedQueue.forEach((promise) => {
    if (token) {
      promise.resolve(token);
    } else {
      promise.reject(error);
    }
  });
  failedQueue = [];
};
const handleLogout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("path");
  localStorage.removeItem("fcmPosted");
  localStorage.removeItem("permissionsList");
  window.location.href = "/";
};
export const interceptor = () => {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        let url = error.response.request.responseURL
        if(url.includes('GetAccessToken')){
            handleLogout();
        }
        originalRequest._retry = true;
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axios(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }
        isRefreshing = true;
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user?.result?.[0]?.refreshToken) {
          handleLogout();
          return Promise.reject(error);
        }
        const body = {
          employeeId: user.result[0].user.id,
          refreshToken: user.result[0].refreshToken,
          deviceId: AuthService.getDeviceID(),
        };
        return AuthService.refreshToken(body)
          .then((response) => {
            if (response.data?.status == false) {
              handleLogout();
              return Promise.reject(new Error("Refresh token expired"));
            }
            if (response.data?.result?.[0]?.accessToken) {
              const newAccessToken = response.data.result[0].accessToken;
              if (newAccessToken === user.result[0].accessToken || response.data?.Status == false) {
                handleLogout();
                return Promise.reject(new Error("Refresh token expired"));
              }
              const updatedUser = {
                ...user,
                result: [
                  {
                    ...user.result[0],
                    accessToken: newAccessToken,
                  },
                ],
              };
              localStorage.setItem("user", JSON.stringify(updatedUser));
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${newAccessToken}`;
              processQueue(null, newAccessToken);
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${newAccessToken}`;
              return axios(originalRequest);
            }
          })
          .catch((err) => {
            processQueue(err, null);
            return Promise.reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      }
      return Promise.reject(error);
    }
  );
};






