import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteVisitProfile, getvisitProfile } from "../../actions/userActions/VisitProfileAction";
import Loader from "../subComponents/Loader";
import SearchInput from "../subComponents/search/SearchInput";
import CustomButton from "../subComponents/buttons/CustomButton";
import { IconButton, Tooltip } from "@mui/material";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CustomPagination from "../subComponents/pagination/CustomPagination";
import Heading6Regular from "../designSystem/Heading6Regular";
import './VisitProfile.css';
import '../../components/planner/Modal.css';
import deleteIcon from "../../assets/images/deleteIcon_drawer.svg"
import Heading6Semibold from "../designSystem/Heading6Semibold";
import BodyMediumRegular from "../designSystem/BodyMediumRegular";
import BodyExtraSmallMedium from "../designSystem/BodyExtraSmallMedium";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import BodySmallRegular from "../designSystem/BodySmallRegular";
import thIcon from "../../assets/images/thIcon.svg"
const VisitProfile = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [selectedVisitProfileId, setSelectedVisitProfileId] = useState(null);
    const itemsPerPage = 10;
    const visitProfile = useSelector((state) => state.visitProfile_reducer.visitProfile);
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));

    useEffect(() => {
        dispatch(getvisitProfile(currentPage, itemsPerPage, searchQuery));
    }, [dispatch, currentPage, searchQuery]);

    const handleCancelDelete = () => {
        setOpenModal(false);
        setSelectedVisitProfileId(null);
    };

    const handleConfirmDelete = () => {
        if (selectedVisitProfileId) {
            dispatch(deleteVisitProfile(selectedVisitProfileId)).then(() => {
                dispatch(getvisitProfile(currentPage, itemsPerPage, searchQuery));
            });
        }
        setOpenModal(false);
        setSelectedVisitProfileId(null);
    };

    const handleOpenModal = (id) => {
        setSelectedVisitProfileId(id);
        setOpenModal(true);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handleAddVisitProfileClick = () => {
        history.push("/admin/AddVisitProfile");
    };
       const visitProfileData = visitProfile?.result || [];
    const totalPages = visitProfile?.totalPages || 0;
    const totalItems = visitProfile?.totalCount || 0;

    return (
        <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Visit Profile" color="#1E293B" />
                </div>
            </div>
            <Heading6Regular text="Visit Profile" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                     <SearchInput
                        placeholder="Search"
                        onChange={handleSearchChange}
                        value={searchQuery}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <CustomPagination
                            currentPage={currentPage}
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            totalPages={totalPages}
                            onPageChange={setCurrentPage}
                        />
                        {permissions && permissions.includes("CanAddVisitProfile") && (
                            <CustomButton text="Add Visit Profile" width="180px" onClick={handleAddVisitProfileClick} />
                        )}
                    </div>
                </div>
                <div className="dynamic-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div className="header-with-icon">
                                        <BodySmallMedium text="Name" color="#1E293B" />
                                        <img
                                            src={thIcon}
                                            alt="Name Icon"
                                            style={{ width: '16px', height: '16px', marginRight: '8px' }}
                                        />
                                    </div>
                                </th>
                                <th>
                                    <div className="header-with-icon">
                                        <BodySmallMedium text="City" color="#1E293B" />
                                        <img
                                            src={thIcon}
                                            alt="City Icon"
                                            style={{ width: '16px', height: '16px', marginRight: '8px' }}
                                        />
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {visitProfile === "isLoading" ? (
                                <tr>
                                    <td colSpan="3" style={{ textAlign: "center" }}>
                                        <Loader colored={true} />
                                    </td>
                                </tr>
                            ) : visitProfileData.length === 0 ? (
                                <tr>
                                    <td colSpan="3" style={{ textAlign: "center" }}>
                                        No visit profiles found
                                    </td>
                                </tr>
                            ) : (
                                visitProfileData.map((row, i) => (
                                    <tr key={i}>
                                        <td><BodySmallRegular text={row.profileName} color='#1E293B' /></td>
                                        <td><BodySmallRegular text={row.cityName} color='#1E293B' /></td>
                                        <td>
                                            {permissions && permissions.includes("CanDeleteVisitProfile") && (
                                                <Tooltip title="Delete Visit Profile" arrow>
                                                    <IconButton onClick={() => handleOpenModal(row.visitProfileId)}>
                                                        <DeleteOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {permissions && permissions.includes("CanUpdateVisitProfile") && (
                                                <Tooltip title="Edit Visit Profile" arrow>
                                                    <IconButton
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: "/admin/UpdateVisitProfile",
                                                                visitProfile: { row },
                                                                id: row.visitProfileId,
                                                            })
                                                        }
                                                    >
                                                        <EditOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {visitProfile !== 'isLoading' && visitProfile.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
            </div>

            {openModal && (
                <div className="modal-backdrop">
                    <div className="modal-container" onClick={(e) => e.stopPropagation()}>
                        <div className='content-container'>
                            <div className='icon-container'>
                                <img src={deleteIcon} />
                            </div>
                            <div className='text-container'>
                                <Heading6Semibold color='#1E293B' text='Delete Visit Profile' />
                                <BodyMediumRegular
                                    color='#384153'
                                    text='Are you sure you want to delete this visit profile?'
                                    textAlign='center'
                                />
                            </div>
                        </div>
                        <div className='button-contiainer'>
                            <button className='cancel-button'
                                onClick={handleCancelDelete}
                            >
                                <BodyExtraSmallMedium color='#1E293B' text='Cancel' />
                            </button>
                            <button className='danger-button' onClick={handleConfirmDelete} >
                                <BodyExtraSmallMedium color='#FFFFFF' text='Delete' />
                            </button>

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VisitProfile;
