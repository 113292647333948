import React, { useEffect, useState } from 'react';
import './AddEventCard.css';
import BodySmallRegular from '../designSystem/BodySmallRegular';
import clockIcon from "../../assets/images/clockIcon.svg";
import practiceLocationIcon from "../../assets/images/Icon=Practice Locations.svg";
import cityIcon from "../../assets/images/Icon=City.svg";
import doctorIcon from "../../assets/images/Icon=Doctors.svg";
import CustomTimeSelect from './CustomTimeSelect';
import minusIcon from "../../assets/images/minusIcon.svg";
import CustomSelect from './CustomSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorsByPracticeLocation } from '../../actions/userActions/doctorAction';
import BodyExtraSmallRegular from '../designSystem/BodyExtraSmallRegular';
import { getTaskPracticeLocations } from '../../actions/userActions/tasks/taskAction';

const AddEventCard = ({ isDeleteActive, planDetail, onUpdatePlanDetail, practiceLocations, index, isVisitProfileId, errors, formValues, isChecked, onToggleCheckbox }) => {
    const permissions = JSON.parse(localStorage.getItem('permissionsList'));
    const [doctorsList, setDoctorsList] = useState([]);
    const [locationsList, setLocationsList] = useState([]);
    const [visitProfileData, setVisitProfileData] = useState(null);
    const [cityDisabled, setCityDisabled] = useState(false);
    const dispatch = useDispatch();
    const visitProfileDetail = useSelector(state => state.visitProfile_reducer.visitProfileDetail);
    const fetchedDoctors = useSelector((state) => state.doctors_reducer.getDoctors);
    const cities = useSelector((state) => state.cities_reducer.cities);

    useEffect(() => {
        if (!isVisitProfileId && planDetail.practiceLocationId && permissions && permissions.includes('CanAddPlannerDoctor')) {
            const doctorBody = {
                practiceLocationId: planDetail.practiceLocationId,
                pageNumber: -1,
                pageSize: 0,
                textSearch: "",
            };
            dispatch(getDoctorsByPracticeLocation(doctorBody));
        }
    }, [planDetail.practiceLocationId]);

    const handleLocationChange = (e) => {
        const newPracticeLocationId = parseInt(e.target.value);

        onUpdatePlanDetail(index, { practiceLocationId: newPracticeLocationId, doctorId: null });
    };
    const handleDoctorChange = (e) => {
        const newDoctorId = parseInt(e.target.value);
        onUpdatePlanDetail(index, { doctorId: newDoctorId });
    };
    // const handleCityChange = (e) => {
    //     const newCityId = parseInt(e.target.value);
    //     onUpdatePlanDetail(index, { cityId: newCityId, practiceLocationId: null });
    // };
    const handleCitySelect = (event) => {
        const selectedCityId = event.target.value;
        if (selectedCityId === "clear") {
            onUpdatePlanDetail(index, { cityId: selectedCityId, practiceLocationId: null });
        } else {
            const selectedCityId = parseInt(event.target.value);
            onUpdatePlanDetail(index, { cityId: selectedCityId, practiceLocationId: null });
        }
    };

    useEffect(() => {
        if (fetchedDoctors && Array.isArray(fetchedDoctors) && fetchedDoctors.length > 0) {
            setDoctorsList((prevDoctorsList) => {
                const updatedList = [...prevDoctorsList];

                fetchedDoctors.forEach((newLocation) => {
                    const existingLocation = updatedList.find(
                        (loc) => loc.practiceLocationId === newLocation.practiceLocationId
                    );

                    if (existingLocation) {
                        existingLocation.doctors = [
                            ...new Map(
                                [...existingLocation.doctors, ...newLocation.doctors].map((doctor) => [
                                    doctor.id,
                                    doctor,
                                ])
                            ).values(),
                        ];
                    } else {
                        updatedList.push(newLocation);
                    }
                });

                return updatedList;
            });
        }
    }, [fetchedDoctors]);
    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const rawValue = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`;
                const ampm = hour >= 12 ? 'PM' : 'AM';
                const displayHour = hour % 12 || 12;
                const label = `${displayHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${ampm}`;
                times.push({ value: rawValue, label });
            }
        }
        // Add the final minute of the day: 11:59 PM
        times.push({ value: "23:59:00", label: "11:59 PM" });
        return times;
    };



    const timeOptions = generateTimeOptions();
    const filteredStartTimeOptions = () => {
        return timeOptions.filter((time) => {
            const [hour, minute] = time.value.split(':').map(Number);
            return hour < 23 || (hour === 23 && minute <= 30); // Exclude times after 11:30 PM
        });
    };
    const filteredEndTimeOptions = () => {
        if (!planDetail.startTime) return timeOptions;

        const startTimeParts = planDetail?.startTime?.split(':');
        const startHour = parseInt(startTimeParts[0], 10);
        const startMinute = parseInt(startTimeParts[1], 10);
        const startTimeInMinutes = startHour * 60 + startMinute;

        // Filter to include only times after the start time, ensuring no rounding issues
        return timeOptions.filter((time) => {
            const timeParts = time.value.split(':');
            const timeHour = parseInt(timeParts[0], 10);
            const timeMinute = parseInt(timeParts[1], 10);
            const timeInMinutes = timeHour * 60 + timeMinute;

            // Ensure no times after 11:59 PM are included
            return (timeInMinutes > startTimeInMinutes && timeInMinutes <= 1439) ||
                (timeHour === 23 && timeMinute === 59);
        });
    };


    const handleFromTimeSelect = (e) => {
        const fromTime = e.target.value;
        onUpdatePlanDetail(index, { startTime: fromTime, endTime: null });
    };
    const handleToTimeSelect = (e) => {
        const toTime = e.target.value;
        onUpdatePlanDetail(index, { endTime: toTime });
    };
    const handleHeadOfficeChange = (e) => {
        const isHeadOfficeChecked = e.target.checked;
        onUpdatePlanDetail(index, { headOffice: isHeadOfficeChecked });
        if (isHeadOfficeChecked) {
            onUpdatePlanDetail(index, { cityId: null, practiceLocationId: null });
            setCityDisabled(true);
        } else {
            setCityDisabled(false);
        }
    };
    const handleCheckbox = (e) => {
        onToggleCheckbox(index, e.target.checked);
    };

    useEffect(() => {
        if (isVisitProfileId && visitProfileDetail && visitProfileDetail !== "isLoading" && visitProfileDetail.length !== 0 && visitProfileDetail.result && visitProfileDetail.result.length !== 0) {
            setVisitProfileData(visitProfileDetail.result[0]);
        }
    }, [visitProfileDetail, isVisitProfileId]);

    useEffect(() => {
        if (permissions && permissions.includes('CanAddPlannerCity') && permissions.includes('CanAddPlannerLocation') && planDetail.cityId) {
            dispatch(getTaskPracticeLocations(planDetail.cityId));
        }
    }, [planDetail.cityId]);

    useEffect(() => {
        if (
            practiceLocations &&
            practiceLocations !== "isLoading" &&
            practiceLocations.length > 0 &&
            practiceLocations?.result?.[0]?.practiceLocations &&
            practiceLocations?.result?.[0]?.practiceLocations.length > 0
        ) {
            const firstPracticeLocation =  practiceLocations?.result?.[0]?.practiceLocations[0];
            const cityId = firstPracticeLocation.city; // Assuming `city` is the `cityId`

            setLocationsList((prevLocationsList) => {
                const updatedList = [...prevLocationsList];
                const existingEntryIndex = updatedList.findIndex(
                    (location) => location.cityId === cityId
                );

                if (existingEntryIndex !== -1) {
                    // Update existing entry
                    const existingEntry = updatedList[existingEntryIndex];
                    existingEntry.practiceLocations = [
                        ...new Map(
                            [
                                ...existingEntry.practiceLocations,
                                ... practiceLocations?.result?.[0]?.practiceLocations,
                            ].map((loc) => [loc.id, loc])
                        ).values(),
                    ];
                    updatedList[existingEntryIndex] = { ...existingEntry }; // Ensure immutability
                } else {
                    // Add a new entry
                    updatedList.push({
                        cityId,
                        practiceLocations:  practiceLocations?.result?.[0]?.practiceLocations,
                    });
                }

                return updatedList;
            });
        }
    }, [practiceLocations]);

    return (
        <div className="add-eventcard">
            {isDeleteActive && (
                <input type="checkbox" className="event-checkbox" checked={isChecked} onChange={handleCheckbox} />
            )}
            {permissions && permissions.includes('CanAddPlannerTime') && (
                <div className='add-eventcard-row'>
                    <div className='icon-label'>
                        <img src={clockIcon} />
                        <BodySmallRegular text='Visit Time' color='#1E293B' />
                    </div>
                    <div className='visit-dropdown-container'>
                        <div className='select-container'>
                            <CustomTimeSelect
                                value={planDetail.startTime || ""}
                                onChange={handleFromTimeSelect}
                                placeholder="Start"
                            >
                                <option value="" disabled>
                                    Start
                                </option>
                                {filteredStartTimeOptions().map((time) => (
                                    <option key={time.value} value={time.value}>
                                        {time.label}
                                    </option>
                                ))}
                            </CustomTimeSelect>
                            {errors?.startTime && (<p>{errors.startTime}</p>)}
                        </div>
                        <img src={minusIcon} />
                        <div className='select-container'>
                            <CustomTimeSelect
                                value={planDetail.endTime || ""}
                                onChange={handleToTimeSelect}
                                placeholder="End"
                            >
                                <option value="" disabled>
                                    End
                                </option>
                                {filteredEndTimeOptions().map((time) => (
                                    <option key={time.value} value={time.value}>
                                        {time.label}
                                    </option>
                                ))}
                            </CustomTimeSelect>
                            {errors?.endTime && (<p>{errors.endTime}</p>)}
                        </div>
                    </div>
                </div>
            )}
            {permissions && permissions.includes('CanAddPlannerCity') && (
                <div className='add-eventcard-row'>
                    <div className='icon-label'>
                        <img src={cityIcon} />
                        <BodySmallRegular text='City' color='#1E293B' />
                    </div>
                    <div className='select-container'>
                        <CustomSelect
                            value={planDetail.cityId || ""}
                            onChange={handleCitySelect}
                            errors={errors}
                            disabled={planDetail.headOffice}
                        >

                            <option value="" disabled>Select city</option>
                            {formValues.employeePlanId && formValues.employeePlanId !== 0 && permissions && permissions.includes('CanAddPlannerCity') && !permissions.includes('CanAddPlannerTime') && (
                                <>
                                    <option value="clear">None</option>
                                    <option disabled className="divider-option">────────────────────────────────</option>
                                </>
                            )}
                            {cities && cities !== "isLoading" && cities.length !== 0 &&  cities.result?.[0]?.cities && cities.result?.[0]?.cities.length !== 0 && cities.result?.[0]?.cities.map((city) => (
                                <option key={city.id} value={city.id}>
                                    {city.name}
                                </option>
                            ))}
                        </CustomSelect>
                        {errors?.cityId && (<p>{errors.cityId}</p>)}
                    </div>
                </div>
            )}
            {permissions && permissions.includes('CanAddPlannerCity') && !permissions.includes('CanAddPlannerTime') && (
                <div className='head-office-row'>
                    <input
                        type="checkbox"
                        className='headoffice-checkbox'
                        checked={planDetail.headOffice}
                        onChange={handleHeadOfficeChange}
                    />
                    <BodyExtraSmallRegular text="at head office" color="#6B7280" />
                </div>
            )}
            {permissions && permissions.includes('CanAddPlannerLocation') && (
                <div className='add-eventcard-row'>
                    <div className='icon-label'>
                        <img src={practiceLocationIcon} />
                        <BodySmallRegular text='Practice Location' color='#1E293B' />
                    </div>
                    <div className='select-container'>
                        <CustomSelect
                            value={planDetail.practiceLocationId || ""}
                            onChange={handleLocationChange}
                            errors={errors}
                        >

                            <option value="" disabled>Select practice location</option>
                            {permissions && !permissions.includes('CanAddPlannerCity') && permissions.includes('CanAddPlannerLocation') && !isVisitProfileId && practiceLocations && practiceLocations !== "isLoading" && practiceLocations.length !== 0 &&  practiceLocations?.result?.[0]?.practiceLocations &&  practiceLocations?.result?.[0]?.practiceLocations.map((location) => (
                                <option key={location.id} value={location.id}>
                                    {location.name}
                                </option>
                            ))}
                            {isVisitProfileId && visitProfileData && visitProfileData.visitLocations?.length > 0 &&
                                visitProfileData.visitLocations.map((location) => (
                                    <option key={location.practiceLocation.id} value={location.practiceLocation.id}>
                                        {location.practiceLocation.name}
                                    </option>
                                ))
                            }

                            {permissions &&
                                permissions.includes("CanAddPlannerCity") &&
                                permissions.includes("CanAddPlannerLocation") &&
                                !isVisitProfileId &&
                                locationsList &&
                                locationsList.length !== 0 &&
                                locationsList.filter((locationGroup) => locationGroup.cityId === planDetail.cityId).length > 0 ? (
                                locationsList
                                    .filter((locationGroup) => locationGroup.cityId === planDetail.cityId)
                                    .map((locationGroup) => {
                                        if (locationGroup.practiceLocations.length === 0) {
                                            return <option key="no-data">No data</option>;
                                        }

                                        const availableLocations = locationGroup.practiceLocations
                                            .filter((location) => {
                                                const isLocationSelected = formValues.employeePlanDate.planDetails.some((planDetail, idx) =>
                                                    idx !== index &&
                                                    planDetail.practiceLocationId === location.id
                                                );
                                                return !isLocationSelected;
                                            });
                                        if (availableLocations.length === 0) {
                                            return <option key="no-data">No data</option>;
                                        }
                                        return availableLocations.map((location) => (
                                            <option key={location.id} value={location.id}>
                                                {location.name}
                                            </option>
                                        ));
                                    })
                            )
                                : (
                                    // <option key="no-data">No data</option>
                                    ""
                                )
                            }
                        </CustomSelect>
                        {errors?.practiceLocationId && (<p>{errors.practiceLocationId}</p>)}
                    </div>
                </div>
            )}
            {permissions && permissions.includes('CanAddPlannerDoctor') && (
                <div className='add-eventcard-row'>
                    <div className='icon-label'>
                        <img src={doctorIcon} />
                        <BodySmallRegular text='Doctor' color='#1E293B' />
                    </div>
                    <div className='select-container'>
                        <CustomSelect
                            value={planDetail.doctorId || ""}
                            onChange={handleDoctorChange}
                            placeholder="Select Doctor"
                        >
                            {!isVisitProfileId && doctorsList.loadingDoctors && (
                                <option disabled>Loading...</option>
                            )}
                            {/* Show no data state */}
                            {!doctorsList.loadingDoctors &&
                                ((!isVisitProfileId && doctorsList.length === 0) ||
                                    (isVisitProfileId && (!visitProfileData || visitProfileData.visitLocations.length === 0))) && (
                                    <option disabled>No data</option>
                                )}
                            <option value="" disabled>Select Doctor</option>
                            {!isVisitProfileId && !doctorsList.loadingDoctors && doctorsList.length > 0 &&
                                doctorsList
                                    .find((location) => location.practiceLocationId === planDetail.practiceLocationId)
                                    ?.doctors
                                    .filter((doctor) => {
                                        const selectedDoctors = formValues.employeePlanDate.planDetails
                                            .filter((detail, idx) => idx !== index && detail.practiceLocationId === planDetail.practiceLocationId)
                                            .map((detail) => detail.doctorId);
                                        return !selectedDoctors.includes(doctor.id);
                                    })
                                    .map((doctor) => (
                                        <option key={doctor.id} value={doctor.id}>
                                            {doctor.name}
                                        </option>
                                    ))
                            }

                            {/* Render doctors from visitProfileData if isVisitProfileId is true */}
                            {isVisitProfileId &&
                                visitProfileData?.visitLocations
                                    ?.find((location) => location.practiceLocation.id == planDetail.practiceLocationId)
                                    ?.doctor
                                    ?.filter((doctor) => {
                                        const selectedDoctors = formValues.employeePlanDate.planDetails
                                            .filter(
                                                (detail, idx) =>
                                                    idx !== index && detail.practiceLocationId === planDetail.practiceLocationId
                                            )
                                            .map((detail) => detail.doctorId);
                                        return !selectedDoctors.includes(doctor.id);
                                    })
                                    ?.map((doctor) => (
                                        <option key={doctor.id} value={doctor.id}>
                                            {doctor.name}
                                        </option>
                                    ))}
                        </CustomSelect>
                        {errors?.doctorId && (<p>{errors.doctorId}</p>)}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddEventCard;