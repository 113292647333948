import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getProducts } from '../../actions/userActions/productAction'
import Loader from '../subComponents/Loader'
import Pagination from '../paginatioin/pagination'
import AddButton from '../subComponents/buttons/AddButton'
import { Tooltip } from "@mui/material";
import ServerSideSearch from '../subComponents/search/ServerSideSearch'
import EditButton from '../subComponents/buttons/EditButton'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IconButton from '@mui/material/IconButton';
import BodySmallMedium from '../designSystem/BodySmallMedium'
import Heading6Regular from '../designSystem/Heading6Regular'
import SearchInput from '../subComponents/search/SearchInput'
import CustomButton from '../subComponents/buttons/CustomButton'
import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import DynamicTable from '../reuse_components/DynamicTable'
import AddProducts from './AddProducts'
import CustomPagination from '../subComponents/pagination/CustomPagination'

function Products() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [productdata, setproductdata] = useState([]);
console.log(editData,"edit ");
const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const [selected, setSelected] = useState({
    textSearch: '',
  })
  const [filters, setFilters] = useState({
    textSearch: '',
  })
  const body = {
    filter: filters,
    pageNumber: currentPage,
    pageSize: 10,
  }
  const products = useSelector((state) => state.products_reducer.products) || [];
  console.log(products.result,'data');
  
 const totalPages =products.totalPages;
 const totalItems = products.totalCount;
  const permissions = JSON.parse(localStorage.getItem('permissionsList'));
  useEffect(() => {
    dispatch(getProducts(body))
  }, [currentPage, filters])
 
  useEffect(() => {
   const productsData = products && products.result?.[0]?.products;
  const ProductsData = productsData?.map((products) => ({
    ...products,
    status: (
      <span
        style={{
          background: "#4361FF1A",
          width: "56px",
          height: "26px",
          padding: "3px 8px",
          display: "inline-block",
          textAlign: "center",
          lineHeight: "20px",
          borderRadius: "4px 0px 0px 0px",
          opacity: 1 ,
        }}
      >
        {products.active ? "Active" : "Inactive"}
      </span>
    ),
  }));
  setproductdata(ProductsData);
  }, [products]);
 
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Description", accessor: "description" },
     { label: "Status", accessor: "status" },  
  ];
 
  const handleAddProductClick = () => {
    setEditData(null);
    setShowModal(true);
  };

  const handleEditProducts = (row) => {
    setEditData(row);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSaveData = async () => {
    dispatch(getProducts(body));
    setShowModal(false);
  };
  const actions = [  
    {
        label: 'Edit Products',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdateProduct',
        handler:handleEditProducts,
    },
];
  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 1) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }
  
  return (
    <>
      <div>
      <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Products" color="#1E293B" />
                </div>
            </div>
            <Heading6Regular text="Products" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearch}
              value={selected.textSearch}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {products !== 'isLoading' && products.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                   
                        {permissions && permissions.includes("CanAddDesignation") && (
                            <CustomButton text="Add Products" width="180px" onClick={handleAddProductClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ productdata}
                    isLoading={products === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
                     <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {products !== 'isLoading' && products.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
            </div>

        </div>
        {showModal && (
        <AddProducts
          onClose={handleCloseModal}
          editData={editData}
          onSave={handleSaveData}
        />
      )}
      </div>
    
    </>
  )
}

export default Products
