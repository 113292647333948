import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getDesignations } from '../../actions/userActions/designationAction'
import Loader from '../subComponents/Loader'
import Pagination from '../paginatioin/pagination'
import AddButton from '../subComponents/buttons/AddButton'
import { IconButton,Tooltip } from "@mui/material";
import ServerSideSearch from '../subComponents/search/ServerSideSearch'
import EditButton from '../subComponents/buttons/EditButton'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BodyExtraSmallMedium from '../designSystem/BodyExtraSmallMedium'
import DynamicTable from '../reuse_components/DynamicTable'
import BodySmallMedium from '../designSystem/BodySmallMedium'
import Heading6Regular from '../designSystem/Heading6Regular'
import SearchInput from '../subComponents/search/SearchInput'

import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import CustomButton from '../subComponents/buttons/CustomButton'
import CustomPagination from '../subComponents/pagination/CustomPagination'
import AddDesignations from './AddDesignations'
function Designations() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [designationdata, setdesignationdata] = useState([]);
  const designations = useSelector(
    (state) => state.designations_reducer.designations
  )
  const totalPages = designations?.totalPages || 0;
  const totalItems = designations?.totalCount || 0;

  const itemsPerPage = 10;
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const [selected, setSelected] = useState({
    textSearch: '',
  })
  const [filters, setFilters] = useState({
    textSearch: '',
  })
 

  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const [query, setQuery] = useState('')
  useEffect(() => {
    const body = {
      filter: filters,
      pageNumber: currentPage,
      pageSize: itemsPerPage,
    };
    dispatch(getDesignations(body));
  }, [currentPage, filters, dispatch]);
  
  const designationData = designations && designations?.result?.[0]?.designations;

  const getPaginationNo = (pageNum) => {
    const object = []
    if (designations !== 'isLoading' && designations.length !== 0) {
      for (let i = 0; i < designations[0].page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }

  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 1) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }
 
  useEffect(() => {
    const DesignationData = designationData?.map((designation) => ({
      ...designation,
      status: (
        <span
          style={{
            background: "#4361FF1A",
            width: "56px",
            height: "26px",
            padding: "3px 8px",
            display: "inline-block",
            textAlign: "center",
            lineHeight: "20px",
            borderRadius: "4px 0px 0px 0px",
            opacity: 1 ,
          }}
        >
          {designation.active ? "Active" : "Inactive"}
        </span>
      ),
    }));
    setdesignationdata(DesignationData);
  }, [designations]);
 
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Description", accessor: "description" },
    { label: "Designation Level", accessor: "levelName" },
    { label: "Status", accessor: "status" },  
  ];
 
  const handleAddDesignationClick = () => {
    setEditData(null);
    setShowModal(true);
  };

  const handleEditDesignation = (row) => {
    setEditData(row);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSaveData = async () => {
    const body = {
      filter: filters,
      pageNumber: currentPage,
      pageSize: itemsPerPage,
    };
    dispatch(getDesignations(body));
    setShowModal(false);
  };
   const actions = [  
    {
        label: 'Edit designation',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdateDesignation',
        handler:handleEditDesignation,
    },
];

  return (
    <>
      <div>
      <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Designation" color="#1E293B" />
                </div>
            </div>
            <Heading6Regular text="Designation" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearch}
              value={selected.textSearch}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {designations !== 'isLoading' && designations.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                   
                        {permissions && permissions.includes("CanAddDesignation") && (
                            <CustomButton text="Add Designation" width="180px" onClick={handleAddDesignationClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ designationdata}
                    isLoading={designations === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
                     <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {designations !== 'isLoading' && designations.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
            </div>

        </div>
        {showModal && (
        <AddDesignations
          onClose={handleCloseModal}
          editData={editData}
          onSave={handleSaveData}
        />
      )}
      </div>
    </>
  )
}

export default Designations
