import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getEmployees } from '../../actions/userActions/employeeAction'
import './Employee.css'
import Loader from '../subComponents/Loader'
import { Link, useHistory } from 'react-router-dom'
import Pagination from '../paginatioin/pagination'
import { Tooltip } from "@mui/material";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SIZE_OF_PAGE } from '../../actions/types'
import HeaderComponent from '../reuse_components/HeaderComponent'
import { downloadEmployee } from '../../actions/userActions/downloadEmployeeAction'
import DynamicTable from '../reuse_components/DynamicTable'
import BodySmallMedium from '../designSystem/BodySmallMedium'
import SearchInput from '../subComponents/search/SearchInput'
import Heading6Regular from '../designSystem/Heading6Regular'
import CustomPagination from '../subComponents/pagination/CustomPagination'
import CustomButton from '../subComponents/buttons/CustomButton'

import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import { Download } from 'lucide-react'
function Employees() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [query, setQuery] = useState('')
  const [previousQuery, setPreviousQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [perPage] = useState(10)
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    const pageNumber = currentPage;

    if (query !== previousQuery) {
      setCurrentPage(1);
    }
    dispatch(getEmployees(pageNumber, SIZE_OF_PAGE, query))
    setPreviousQuery(query);
    setButtonPermission()
  }, [currentPage, query])

  const downloadEmployeeStatus = useSelector((state) => state.download_employee_reducer.downloadEmployee);
  const employees = useSelector((state) => state.employees_reducer.employees);
  const totalPages = employees.totalPages;
 const itemsPerPage=10;
  const totalItems = employees.totalCount;
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  console.log(downloadEmployeeStatus, "download employee reducer");


  // const handlePageChange = (event, value) => {
  //   setCurrentPage(value);
  // };
  const handleSearchChange = (query) => {
    setQuery(query);
    setCurrentPage(1);
   // history.push(`?page=1&search=${query}`);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}&search=${query}`);
  };
  const handleAddEmployeeClick = () => {
    history.push('/admin/Addemployees');
  };
  const setButtonPermission = () => {
    if (permissions.includes('CanAddEmployee')) {
      setPermission(true);
    }
  }
  const handleEmployeeDownload = () => {
    dispatch(downloadEmployee());
  };
 
  const EmployeeData =employees &&  employees.result?.map((employees) => ({
    ...employees,
    empId: employees.empId ? employees.empId : "N/A",
  fullName: (
    <div>
      {/* <p>
        {employees.firstName.charAt(0)}
        {employees.lastName.charAt(0)}
      </p> */}
      {employees.firstName} {employees.lastName}
    </div>
  ),
    status: employees.status ? "Active" : "In-active",
  }));

  const columns = [
    { label: "EmpID", accessor: "empId" },
    { label: "Name", accessor: "fullName" },
    { label: "Designation ", accessor: "designationName" },
    { label: "Reports To", accessor: "reportToName" },
    { label: "Status", accessor: "status" },  
  ];
  const handleEditEmployee = (row) => {
    history.push({
      pathname: '/admin/UpdateEmployees',
      employee: { row },
      id: row.id,
    });
  }; const actions = [  
    {
        label: 'Edit employee',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdateEmployee',
        handler:handleEditEmployee,
    },
];

  return (
    <>
     <div>
      <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Employee" color="#1E293B" />
                </div>
            </div>
            <Heading6Regular text="Employee" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={(e) => handleSearchChange(e.target.value)}

              value={query}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {employees !== 'isLoading' && employees.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                    <IconButton>
                          <Download onClick={handleEmployeeDownload}/>
                        </IconButton>
                        {permissions && permissions.includes("CanAddEmployee") && (
                            <CustomButton text="Add Employee" width="180px" onClick={handleAddEmployeeClick} />
                        )}
                       
                                                 
                    </div>
                </div>
                <DynamicTable
                    data={ EmployeeData}
                    isLoading={employees === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
                                     <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {employees !== 'isLoading' && employees.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
            </div>

        </div>
       
      </div>
     
    </>
  )
}

export default Employees
