import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getDepartments } from "../../actions/userActions/departmentAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import { IconButton, Tooltip } from "@mui/material";
import Pagination from "../paginatioin/pagination";
import EditButton from "../subComponents/buttons/EditButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SIZE_OF_PAGE } from "../../actions/types";
import HeaderComponent from "../reuse_components/HeaderComponent";

import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import DynamicTable from "../reuse_components/DynamicTable";
import CustomButton from "../subComponents/buttons/CustomButton";
import SearchInput from "../subComponents/search/SearchInput";
import Heading6Regular from "../designSystem/Heading6Regular";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import CustomPagination from "../subComponents/pagination/CustomPagination";
import AddDepartments from "./AddDepartments";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Departments() {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const initialPageNumber = parseInt(query.get("page")) || 1;
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageNumber, setCurrentPageNumber] = useState(initialPageNumber);
  const [searchQuery, setSearchQuery] = useState(query.get("search") || "");
  const [previousQuery, setPreviousQuery] = useState("");
  const [permission, setPermission] = useState(false)
  const pageSize = SIZE_OF_PAGE;
  const itemsPerPage = 10;
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [departmentData, setdepartmentData] = useState([]);
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const pageNumber = currentPageNumber;

    if (searchQuery !== previousQuery) {
      setCurrentPageNumber(1);
    }

    dispatch(getDepartments(currentPage, itemsPerPage, searchQuery));
    setPreviousQuery(searchQuery);
    setButtonPermission()
  }, [currentPage, searchQuery]);

  const departments = useSelector((state) => state.departments_reducer.departments);
 
  const departmentsData = departments?.result || [];
  const totalPages = departments.totalPages;
  const totalItems = departments?.totalCount;
 console.log(departments.totalCount,'>>>>>>>.....items');
 
  
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    history.push(`?page=${page}&search=${searchQuery}`);
  };
  useEffect(() => {
    const departmentData = departments && departmentsData;
    const DepartmentsData = departmentData?.map((departments) => ({
      ...departments,
      status: (
        <span
          style={{
            background: "#4361FF1A",
            width: "56px",
            height: "26px",
            padding: "3px 8px",
            display: "inline-block",
            textAlign: "center",
            lineHeight: "20px",
            borderRadius: "4px 0px 0px 0px",
            opacity: 1 ,
          }}
        >
          {departments.active ? "Active" : "Inactive"}
        </span>
      ),
    }));
    setdepartmentData(DepartmentsData);
  }, [departments]);

  
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Description", accessor: "description" },
     { label: "Status", accessor: "status" },  
  ];
 
  const handleAddDepartmentClick = () => {
    setEditData(null);
    setShowModal(true);
  };

  const handleEditDepartment = (row) => {
    setEditData(row);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSaveData = async (newData) => {
    dispatch(getDepartments(currentPage, itemsPerPage, searchQuery));
    setShowModal(false);
  };
  const actions = [  
    {
        label: 'Edit Department',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdateDepartment',
        handler:handleEditDepartment,
    },
];

const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
  setCurrentPage(1);
};
  const setButtonPermission = () => {
    if(permissions.includes('CanAddEmployee')){
      setPermission(true);
    }
  }

  return (
    <>
     <div>
      <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Departments" color="#1E293B" />
                </div>
            </div>
            <Heading6Regular text="Departments" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearchChange}
              value={searchQuery}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {departments !== 'isLoading' && departments.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                   
                        {permissions && permissions.includes("CanAddDepartment") && (
                            <CustomButton text="Add Department" width="180px" onClick={handleAddDepartmentClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ departmentData}
                    isLoading={departments === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
                     <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {departments !== 'isLoading' && departments.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
    </div>
            </div>

        </div>
          
        {showModal && (
        <AddDepartments
          onClose={handleCloseModal}
          editData={editData}
          onSave={handleSaveData}
        />
      )}
      </div>
   
    </>
  );
}

export default Departments;
