import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getTaskPriorities } from "../../actions/userActions/taskPriorityAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import { IconButton,Tooltip } from "@mui/material";
import Pagination from '../paginatioin/pagination';
import EditButton from "../subComponents/buttons/EditButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SIZE_OF_PAGE } from "../../actions/types";
import DynamicTable from "../reuse_components/DynamicTable";
import CustomButton from "../subComponents/buttons/CustomButton";
import CustomPagination from "../subComponents/pagination/CustomPagination";
import SearchInput from "../subComponents/search/SearchInput";
import Heading6Regular from "../designSystem/Heading6Regular";
import BodySmallMedium from "../designSystem/BodySmallMedium";
import thIcon from "../../assets/images/thIcon.svg";
import chevron_rightIcon from "../../assets/images/chevron_rightIcon.svg";
import AddTaskPriorities from "./AddTaskPriorities";
function TaskPriorities() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [previousQuery, setPreviousQuery] = useState("");
  const [query, setQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [taskprioritydata, settaskprioritydata] = useState([]);

  
  const dispatch = useDispatch();
  const history = useHistory();


  const taskPriorities = useSelector(
    (state) => state.task_priorities_reducer.taskPriorities
  );
  
  const taskPrioritiesData = taskPriorities && taskPriorities.result;
 const itemsPerPage =10; 
  const totalItems = taskPriorities?.totalCount || 0;
  const totalPages = taskPriorities?.totalPages || 0;
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  useEffect(() => {
    const pageNumber = currentPage;
  
      if (query !== previousQuery) {
        setCurrentPage(1);
      }
      dispatch(getTaskPriorities(pageNumber, SIZE_OF_PAGE, query));
      setPreviousQuery(query);
  
    }, [currentPage, query]);
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}&search=${query}`);
  };
  
  useEffect(() => {
    const TaskPrioritiesData = taskPrioritiesData?.map((taskPriorities) => ({
      ...taskPriorities,
      status: (
        <span
          style={{
            background: "#4361FF1A",
            width: "56px",
            height: "26px",
            padding: "3px 8px",
            display: "inline-block",
            textAlign: "center",
            lineHeight: "20px",
            borderRadius: "4px 0px 0px 0px",
            opacity: 1 ,
          }}
        >
          {taskPriorities.active ? "Active" : "Inactive"}
        </span>
      ),
      color:( <span
         style={{
        height: "20px",
        width: "20px",
        backgroundColor: taskPriorities.color,
        display: "inline-block",
      }}>
         </span>)
    }));
    settaskprioritydata(TaskPrioritiesData);
  }, [taskPriorities]);
 
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Description", accessor: "description" },
    { label: "Color", accessor: "color" },
     { label: "Status", accessor: "status" },  
  ];

  const handleAddTaskPriorityClick = () => {
    setEditData(null);
    setShowModal(true);
  };

  const handleEditTaskPriority = (row) => {
    setEditData(row);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSaveData = async () => {
    dispatch(getTaskPriorities(currentPage, SIZE_OF_PAGE, query));
    setShowModal(false);
  };
   const actions = [  
    {
        label: 'Edit Task Priority',
        icon: <EditOutlinedIcon />,
        permission: 'CanUpdateTaskPriority',
        handler:handleEditTaskPriority,
    },
];

const handleSearchChange = (e) => {
  setQuery(e.target.value);
  setCurrentPage(1);
};
  return (
    <>
     <div className="main-container">
            <div className="content-header">
                <div className="header-navigation">
                    <BodySmallMedium text="Manage" color="#A1A2A4" />
                    <img
                        src={chevron_rightIcon} alt="Chevron Right" className="chevron-right-icon" />
                    <BodySmallMedium text="Task Priorities" color="#1E293B" />

                </div>
            </div>
            <Heading6Regular text="Task Priorities" color="#1E293B" />
            <div className="content-wrapper">
                <div className="pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearchChange}
                        value ={query}
             />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {taskPriorities !== 'isLoading' && taskPriorities.length !== 0 &&(
                        <CustomPagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)} 
                      />
                      )
                      }
                   
                        {permissions && permissions.includes("CanAddTaskPriority") && (
                            <CustomButton text="Add Task Priority" width="180px" onClick={handleAddTaskPriorityClick} />
                        )}
                    </div>
                </div>
                <DynamicTable
                    data={ taskprioritydata}
                    isLoading={taskPriorities === "isLoading"}
                    columns={columns}
                    permissions={permissions}
                    actions={actions}
                    thIcon={thIcon}
                />
<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
    {taskPriorities !== 'isLoading' && taskPriorities.length !== 0 && totalItems > 0 && (
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    )}
  </div>

            </div>
            {showModal && (
        <AddTaskPriorities
          onClose={handleCloseModal}
          editData={editData}
          onSave={handleSaveData}
        />
      )}
        </div>
     
    </>
  );
}

export default TaskPriorities;
